<template>
  <div class="pauseModal" ref="root">
    <div class="appIntroContent">
      <div class="pure-g">
        <div class="appIntroHeaderTitle pure-u-1-1"></div>
        <div class="appIntroHeaderFixedButton" @click="close()">
          <button class="skipButton clickable" id="btnSkipAppIntro" :gamepadClickable="true">
            {{ $t("appintro.done") }}
          </button>
        </div>
        <div class="pure-u-1-1">
          <p>
            {{ $t("hello.loginNeeded") }}
          </p>
          <button class="clickable openidbutton" @click="onOpenIdLogin" :gamepadClickable="true">
          <img
            src="/app/assets/img/sits_large_border.png"
            @click="onOpenIdLogin"
            class="logo"
            alt="Login Button"
            ref="loginButton"
          />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  components: {},
  data: function() {
    return {};
  },
  mounted: function() {
    this.$setControllerViewConfig({ controls: this.$tabbable(this.$refs.root) });
  },
  methods: {
    close: function() {
      this.$root.$emit('LoginPopupClose');
    },

    onOpenIdLogin: function() {
      var sSuffix1 = window.location.href.substr(
        0,
        window.location.href.indexOf("/app/")
      );

      var sSuffix2 = window.location.href.substr(
        0,
        window.location.href.indexOf("/app/") + 5
      );

      window.location.href =
        "https://steamcommunity.com/openid/login?openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.mode=checkid_setup&openid.ns=http://specs.openid.net/auth/2.0&openid.realm=" +
        sSuffix1 +
        "&openid.return_to=" +
        sSuffix2;
    },
  },
};
</script>
<style>
.appIntroContent {
  margin: auto;
  padding: 1em 1em 0.5em 1em;
  width: 40vh;
  margin-top: 5vh;
  border-radius: 0.5em;
  text-align: center;
  background-color: whitesmoke;
  position: relative;
}

.appIntroSeperator {
  border-color: #ddd;
  background-color: #fff;
  background-image: linear-gradient(0deg, #ddd, #ddd 50%, transparent 50%);
  color: #444;
  margin: 0.25em -20px 0em -20px;
  opacity: 50%;
}

.skipButton {
  border-color: transparent;
  background: none;
  box-shadow: none;
  color: #387ef5;
  float: right;
}

.appIntroHeaderButton {
  /* width: 10%; */
  display: inline-block;
  vertical-align: top;
}

.appIntroHeaderTitle {
  /* width: 90%; */
  display: inline-block;
  vertical-align: top;
}

.appIntroHeaderTitle h3 {
  margin: initial;
  padding: initial;
}

.appIntroImg {
  width: 100%;
  margin: auto;
}

.appIntroHeaderFixedButton{
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
