import PromiseWorker from '../libs/promise-worker'
import Worker from 'worker-loader!./worker'

const worker = new Worker()
const promiseWorker = new PromiseWorker(worker)

const send = function( message, transferList ) {
  return promiseWorker.postMessage(message,transferList);
}

export default {
  send
}
