var SettingsManager = {
  settings: {
    displaymode: "f",
    resolution: {
      width: null,
      height: null,
      resolutionString: null
    },
    resolutionEnabled: false,
    uiLangu: "en",
    volume: { master: 100, music: 50, sfx: 50, narration: 80 },
    noSleepEnabled: false,
    streamerMode: false,
    lowPerformanceMode: false,
    noai: false
  },

  resolutionOptions: [
    { value: "1280x720", text: "1280 × 720" },
    { value: "1920x1080", text: "1920 × 1080" },
    { value: "2560x1440", text: "2560 × 1440" },
    { value: "3840x2160", text: "3840 × 2160" }
  ],
  noSleep: null,
  cookiePromises: {},

  pollForSettings: async function (fn, ms) {
    while (!(document.settings && document.settings.volume)) {
      await this.wait(ms);
    }
    fn();
  },

  getBrowserSettings: function (vue) {
    var oCookie = vue.cookies;
    var isTauri = vue.$isTauri;
    if (!isTauri) {
      try {
        var settings =
          oCookie.get("settings") !== undefined
            ? JSON.parse(oCookie.get("settings"))
            : {};
        if (settings.resolutionEnabled !== undefined) {
          settings.displaymode = settings.resolutionEnabled ? "w" : "f";
        }
        if (settings.resolution.width && settings.resolution.height) {
          settings.resolution.resolutionString =
            settings.resolution.width + "x" + settings.resolution.height;
        }

        this.settings = settings;
        this.applySettings(vue);
      } catch {
        console.warn(
          "Couldn't load settings saved in browser, try to set new settings in the settings menu to update incompatible old settings."
        );
      }
    } else {
      parent.postMessage({ type: "getSettings" }, "*");
    }
  },

  applySettings(vue) {
    vue.SoundManager.setVolume(this.settings.volume);
    this.onLanguChange(vue);
    this.onNoSleepEnabledChange();
    this.streamerModeChange(vue);
    this.onLowPerformanceModeChange(vue);
  },

  onLanguChange: function (vue) {
    vue.$root.$i18n.locale = this.settings.uiLangu;
    vue.$root.$emit("languChange", vue.$root.$i18n.locale);
  },

  streamerModeChange: function (vue) {
    if (this.settings.streamerMode) {
      vue.$store.commit("PartyCode", { show: false });
    } else {
      vue.$store.commit("PartyCode", { show: true });
    }

    vue.$root.$emit("streamerModeChange", this.settings.streamerMode);
  },

  onLowPerformanceModeChange: function (vue) {
    vue.$root.$emit(
      "lowPerformanceModeChange",
      this.settings.lowPerformanceMode
    );
  },

  onNoaiChange: function (vue) {
    vue.$root.$emit("noaiChange", this.settings.noai);
  },

  wait: function (ms = 1000) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  },

  onNoSleepEnabledChange: function () {
    if (this.settings.noSleepEnabled) {
      document.addEventListener(
        "click",
        function enableNoSleep() {
          document.removeEventListener("click", enableNoSleep, false);

          this.noSleep.enable();
        }.bind(this),
        false
      );
    } else {
      this.noSleep.disable();
    }
  },

  saveCookie: function (name, value, expire, isTauri) {
    if (!isTauri) {
      this.cookies.set(name, value, {
        expires: expire
      });
    } else {
      parent.postMessage(
        {
          type: "setCookie",
          name: name,
          value: value,
          expire: expire
        },
        "*"
      );
    }
  },

  removeCookie(name, isTauri) {
    if (!isTauri) {
      this.cookies.remove(name);
    } else {
      parent.postMessage(
        {
          type: "removeCookie",
          name: name
        },
        "*"
      );
    }
  },

  getCookie(name, isTauri) {
    if (!isTauri) {
      return this.cookies.get(name);
    } else {
      let checkPromise = new Promise(
        function (resolve) {
          this.cookiePromises[name] = resolve;
        }.bind(this)
      );

      parent.postMessage(
        {
          type: "getCookie",
          name: name
        },
        "*"
      );

      return checkPromise;
    }
  },

  resolveCookie(name, payload) {
    if (this.cookiePromises[name]) {
      this.cookiePromises[name](payload);
      delete this.cookiePromises[name];
    }
  }
};

export default SettingsManager;
