import Vue from "vue";
import App from "./App";
import router from "./router";
import Notifications from "vue-notification";
import VueTimers from "vue-timers";
import VueProgressBar from "vue-progressbar";
import i18n from "./i18n";
import FastClick from "fastclick";
import SoundManager from "./libs/soundmanager";
import ControllerManager from "./libs/controllermanager";
import SettingsManager from "./libs/settingsmanager";
import SmartTable from "vuejs-smart-table";
import VueAgile from "vue-agile";
import NoSleep from "./libs/nosleep";
import Cookies from "js-cookie";
import FlagWrapper from "./libs/flagwrapper";
import ElementQueries from "css-element-queries";
import ObjectValues from "object.values";
import store from "./store";
import VueMeta from "vue-meta";
import { tabbable } from "tabbable";
import PortalVue from 'portal-vue'

window.addEventListener(
  "load",
  () => {
    FastClick.attach(document.body);
  },
  false
);
const progressOptions = {
  color: "#647687",
  failedColor: "#874b4b",
  thickness: "7px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: true,
  autoFinish: true,
};

Vue.use(Notifications);
Vue.use(VueTimers);
Vue.use(VueProgressBar, progressOptions);
Vue.use(SmartTable);
Vue.use(VueAgile);
Vue.use(VueMeta);
Vue.use(PortalVue)

Vue.config.devtools = true;
Vue.config.performance = true;
Vue.config.productionTip = false;

Vue.prototype.spaceCounter = 0;
Vue.prototype.getSpaceCounter = function() {
  return this.spaceCounter++;
};

Vue.prototype.$tabbable = tabbable;

import worker from "@/worker";
Vue.prototype.drawingWorker = worker;

Vue.use(ControllerManager);
Vue.use(FlagWrapper);

Vue.prototype.ElementQueries = ElementQueries;
Vue.prototype.cookies = Cookies;
Vue.prototype.SoundManager = SoundManager;

Vue.prototype.SettingsManager = SettingsManager;
Vue.prototype.SettingsManager.noSleep = new NoSleep();
Vue.prototype.SettingsManager.cookies = Cookies;
Vue.prototype.$checkTauri();

window.vm = new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");

SoundManager.onInit();

(function() {
  "use strict";
  var apngTest = new Image(),
    ctx = document.createElement("canvas").getContext("2d");
  apngTest.onload = function() {
    ctx.drawImage(apngTest, 0, 0);
    self.window.APNGSupport = ctx.getImageData(0, 0, 1, 1).data[3] === 0;
  };
  apngTest.src =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACGFjVEwAAAABAAAAAcMq2TYAAAANSURBVAiZY2BgYPgPAAEEAQB9ssjfAAAAGmZjVEwAAAAAAAAAAQAAAAEAAAAAAAAAAAD6A+gBAbNU+2sAAAARZmRBVAAAAAEImWNgYGBgAAAABQAB6MzFdgAAAABJRU5ErkJggg==";
  // frame 1 (skipped on apng-supporting browsers): [0, 0, 0, 255]
  // frame 2: [0, 0, 0, 0]
})();

ObjectValues.shim();