<template>
  <div style="display:flex">
    <div class="pure-sm-1 pure-u-1-3">
      <div v-for="guess in turnAnswerChooseListSorted" :key="guess.key">
        <div
          v-if="guess.index % 2 == 0"
          class="guessContainer"
          :style="'text-align: ' + getAlign(guess.index) + ';'"
        >
          <p  class="ink shape04"
            :style="
              'transform: rotate(' +
                getRotation(turnAnswerChooseListSorted, guess.index, true) +
                'deg); padding-right: ' +
                getPadding(turnAnswerChooseListSorted, guess.index) +
                'em; margin-right: ' +
                getPadding(turnAnswerChooseListSorted, guess.index)*2 +
                'em'
            "
          >
            <strong class="guessValue">{{ guess.value }}</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="pure-sm-1 pure-u-1-3 middleAlign">
      <div class="ink shape03">
        <DrawView v-bind:drawingBlob="turnDrawingBlob"></DrawView>
      </div>
    </div>
    <div class="pure-sm-1 pure-u-1-3">
      <div v-for="guess in turnAnswerChooseListSorted" :key="guess.key">
        <div
          v-if="guess.index % 2 == 1"
          class="guessContainer"
          :style="'text-align: ' + getAlign(guess.index) + ';'"
        >
          <p class=" ink shape04"
            :style="
              'transform: rotate(' +
                getRotation(turnAnswerChooseListSorted, guess.index, false) +
                'deg); padding-left: ' +
                getPadding(turnAnswerChooseListSorted, guess.index) +
                'em; margin-left: ' +
                getPadding(turnAnswerChooseListSorted, guess.index)*2 +
                'em'
            "
          >
            <strong class="guessValue">{{ guess.value }}</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DrawView from "./DrawView";

export default {
  props: ["turnDrawingBlob", "turnAnswerChooseList"],
  name: "TurnAnswerChooseDisplay",
  data: function() {
    return {
      turnAnswerChooseListSorted : [],
    };
  },
  components: {
    DrawView,
  },
  mounted() {
    this.turnAnswerChooseListSorted = this.turnAnswerChooseList
      .map((a) => ({
        sort: this.$xmur3(a.value)(),
        value: a,
      }))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value);

    let i = 1;
    this.turnAnswerChooseListSorted.forEach(function(guess) {
      guess.index = i;
      i++;
    });
  },
  beforeDestroy() {
    this.$root.$emit("WatchTimerHide");
  },
  methods: {
    getRotation: function(oTab, guessIndex, positiveRotation) {
      var maxGuessIndex = Math.max.apply(
        Math,
        oTab.map(function(guess) {
          return guess.index;
        })
      );
      var minGuessIndex = Math.min.apply(
        Math,
        oTab.map(function(guess) {
          return guess.index;
        })
      );
      var normalizedIndex = this.normalize(
        guessIndex,
        minGuessIndex,
        maxGuessIndex
      );

      if (positiveRotation) {
        let rotDegree = 5 * Math.floor(oTab.length / 2);
        if (normalizedIndex < 0.5) {
          return rotDegree * Math.abs(normalizedIndex - 0.5);
        } else {
          return -rotDegree * Math.abs(normalizedIndex - 0.5);
        }
      } else {
        let rotDegree = 5 * Math.ceil(oTab.length / 2);
        if (normalizedIndex > 0.5) {
          return rotDegree * Math.abs(normalizedIndex - 0.5);
        } else {
          return -rotDegree * Math.abs(normalizedIndex - 0.5);
        }
      }
    },

    getPadding(oTab, guessIndex) {
      var maxGuessIndex = Math.max.apply(
        Math,
        oTab.map(function(guess) {
          return guess.index;
        })
      );
      var minGuessIndex = Math.min.apply(
        Math,
        oTab.map(function(guess) {
          return guess.index;
        })
      );
      var normalizedIndex = this.normalize(
        guessIndex,
        minGuessIndex,
        maxGuessIndex
      );
      return 2 * (1 - Math.abs(normalizedIndex - 0.5));
    },

    getAlign: function(guessIndex) {
      if (guessIndex % 2 == 0) {
        return "right";
      } else {
        return "left";
      }
    },

    normalize: function(val, min, max) {
      if (max - min === 0) return 1;
      return (val - min) / (max - min);
    },
  },
};
</script>
<style scoped>
.guessValue {
  font-size: xx-large;
  font-weight: 500;
  max-width: 35rem;
  word-break: break-word;
}

.guessContainer {
  margin-bottom: 4em;
}

.middleAlign {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  flex-grow: 1;
}
</style>
