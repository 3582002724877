<template>
  <div class="partyWrapper" ref="root">
    <div v-bind:class="[$store.state.playType == 'watch' ? 'spectatorPartyWrapper' : 'playerPartyWrapper']">
      <br v-if="$store.state.playType == 'watch'" />
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('LobbyWait')" class="pure-g" :key="NaN">
          <PartyLobby
            :users="users"
            :partyHost="partyHost"
            :numberWatchModes="numberWatchModes"
            :numberAIWatchModes="numberAIWatchModes"
            @onKickPlayer="onKickPlayer"
            @onLeave="onLeave"
            @onStartGame="onStartGame"
          ></PartyLobby>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('RoundAnnounce') && !showingCardView" class="centerContentIngameText ink shape02" :key="NaN">
          <p>{{ $t("party.startingRound") }} #{{ $store.state.round }}</p>
          <button class="pure-button" v-if="$store.state.playType != 'watch'" :disabled="$store.state.handcards.length === 0" @click="showCardView">
            {{ $t("party.useCard") }} ({{ $store.state.handcards.length }})
          </button>
        </div>
      </transition>
      <ActivatedCardView
        v-if="fsm.is('RoundAnnounce') && $store.state.playType === 'watch' && activatedCards.length > 0"
        :activatedCards="activatedCards"
      ></ActivatedCardView>
      <portal to="overlay-outlet">
        <CardView v-if="showingCardView" :users="users"></CardView>
      </portal>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('QuestionAnnounce')">
          <div
            v-if="fsm.is('QuestionAnnounce') && question != ''"
            class="centerContentIngameTextDrawing"
            v-bind:class="[$store.state.playType == 'watch' ? '' : 'playerDrawingWrapper']"
            :key="NaN"
          >
            <DrawArea
              :type="'drawing'"
              ref="DrawAreaParty"
              :renderAdditionalButtons="true"
              :question="question"
              @onRedrawQuestion="onRedrawQuestion"
              @onSubmitDrawing="onSubmitDrawing"
              @onSendDoneDrawing="onSendDoneDrawing"
              v-if="$store.state.playType == 'play'"
              :noEraser="activeCards.indexOf('noeraser') > -1"
              :onlyBW="activeCards.indexOf('onlyblackandwhite') > -1"
              :lightsOut="activeCards.indexOf('lightsout') > -1"
            ></DrawArea>
          </div>
          <div v-if="fsm.is('QuestionAnnounce') && $store.state.playType == 'watch'" class="centerContentIngameText ink shape03">
            <p>{{ $t("party.watchWaitingDrawing") }}</p>
            <div class="stillDrawingContainer">
              <span v-if="usersStillDrawingList.length > 0">{{ $t("party.stillDrawing") }}</span>
              <div v-for="user in usersStillDrawingList" :key="user.name" class="stillDrawingViewWrapper">
                <DrawView class="stillDrawingView" v-bind:drawingBlob="user.avatarBlob" :styleClass="'DrawViewAvatarSmall'"></DrawView>
              </div>
            </div>
          </div>
          <br />
          <Tip v-if="$store.state.playType == 'watch'"></Tip>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('QuestionWait')" :key="NaN">
          <div class="centerContentIngameText ink shape02" :key="NaN">
            <div class="stillDrawingContainer">
              <span v-if="usersStillDrawingList.length > 0">{{ $t("party.stillDrawing") }}</span>
              <div v-for="user in usersStillDrawingList" :key="user.name" class="stillDrawingViewWrapper">
                <DrawView class="stillDrawingView" v-bind:drawingBlob="user.avatarBlob" :styleClass="'DrawViewAvatarSmall'"></DrawView>
              </div>
            </div>
            <p>{{ $t("party.playWaitingDrawing") }}</p>
            <div v-if="partyHost === $store.state.username">
              <hr />
              <IngameKick :username="$store.state.username" :users="users" :partyHost="partyHost"> </IngameKick>
            </div>
          </div>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('SubmitAnswer') && $store.state.username != turnUser" class="centerContentIngameText">
          <div class="pure-u-1-24"></div>
          <div class="pure-u-23-24">
            <p v-if="$store.state.playType == 'watch'" class="ink shape01">
              {{ $t("party.instructionTurnAnswerWatch") }}
            </p>
            <DrawView
              v-bind:drawingBlob="turnDrawingBlob"
              v-if="$store.state.playType == 'watch'"
              class="ingameDrawView ink shape06"
              :onlyShownBriefly="activeCards.indexOf('blind') > -1"
              :upsideDown="activeCards.indexOf('upsidedown') > -1"
            ></DrawView>
            <div v-if="$store.state.playType == 'play'" class="pure-g ink shape05">
              <div class="pure-u-1-1 pure-form pure-form-stacked">
                <fieldset>
                  <input
                    id="inpTurnAnswer"
                    ref="inpTurnAnswer"
                    class="pure-form guessMargin"
                    v-on:keyup.enter="onSubmitTurnAnswer"
                    v-model="turnAnswer"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    maxlength="65"
                  />
                  <button id="btnSubmitTurnAnswer" class="pure-button guessMargin" @click="onSubmitTurnAnswer">
                    {{ $t("party.submitGuess") }}
                  </button>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="pure-u-1-24"></div>
          <div class="pure-form ink shape02" v-if="turnUser != '' && $store.state.playType == 'play'">
            <div>
              <p>{{ $t("party.instructionSubmitGuess") }}</p>
            </div>
          </div>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('SubmitAnswer') && $store.state.username == turnUser" class="centerContentIngameText ink shape03" :key="NaN">
          <p>{{ $t("party.drawnByYou") }}</p>
          <p v-if="$store.state.playType == 'watch'">
            {{ $t("party.instructionTurnAnswerWatch") }}
          </p>
          <DrawView v-bind:drawingBlob="turnDrawingBlob" v-if="$store.state.playType == 'watch'"></DrawView>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('TurnAnswerWait')" :key="NaN">
          <div class="centerContentIngameText ink shape01">
            <p>{{ $t("party.turnAnswerWait") }}</p>
          </div>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div
          v-if="fsm.is('TurnAnswerChoose') && $store.state.username != turnUser && $store.state.playType == 'play'"
          class="centerContentIngameText ink shape03"
          :key="NaN"
        >
          <div class="turnAnswerChooseListClipper">
            <p class="paragraphWrapper" v-html="$t('party.instructionTurnAnswerChoose')"></p>
            <div class="pure-sm-1" v-for="answer in turnAnswerChooseList" :key="answer.key">
              <button
                class="pure-button pure-button-primary btnPickGuess"
                :id="answer.key"
                @click="onSubmitPickGuess"
                :disabled="!pickButtonsEnabled"
              >
                {{ answer.value }}
              </button>
              <br />
              <br />
            </div>
          </div>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: 300, leave: 300 }">
        <div v-if="playerGetsCard" class="cardBadge">{{ $t("party.gotCard") }}</div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div
          v-if="(fsm.is('TurnAnswerChoose') && $store.state.username == turnUser) || (fsm.is('EndTurn') && $store.state.playType == 'play')"
          class="centerContentIngameText ink shape02"
          :key="NaN"
        >
          <p class="paragraphWrapper">{{ $t("party.playTurnAnswerChoose") }}</p>
          <div class="pure-sm-1 ink shape06" v-if="turnAnswerChooseList.length > 0">
            <div class="turnAnswerChooseListClipper">
              <div v-for="answer in turnAnswerChooseList" :key="answer.key" class="">
                <p>{{ answer.value }}</p>
                <span
                  v-if="
                    answer.value !== question &&
                      answer.value &&
                      ((turnAnswer && answer.value.toLowerCase() !== turnAnswer.toLowerCase()) || turnAnswer === '')
                  "
                >
                  <button
                    class="pure-button pure-button-primary"
                    v-for="reaction in reactionList"
                    :key="reaction.key"
                    :data-reaction-group="answer.key"
                    @click="onReact(answer.key, reaction.key)"
                    :disabled="guessesReactedToList.includes(Number(answer.key))"
                  >
                    <IconDisplay :icon="reaction"></IconDisplay>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('TurnAnswerChoose') && $store.state.playType == 'watch'" class="centerContentIngameText">
          <span class="ink shape02" v-if="$store.state.playType == 'watch'" style="display:inline-block; margin-bottom: 2em;">
            {{ $t("party.instructionPickGuessWaitWatch") }}
          </span>
          <TurnAnswerChooseDisplay :turnAnswerChooseList="turnAnswerChooseList" :turnDrawingBlob="turnDrawingBlob"> </TurnAnswerChooseDisplay>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('PickGuessWait')" :key="NaN">
          <div class="centerContentIngameText ink shape01">
            <DrawView v-bind:drawingBlob="turnDrawingBlob" v-if="$store.state.playType == 'watch'"></DrawView>
            <p class="paragraphWrapper">{{ $t("party.instructionPickGuessWait") }}</p>
            <div class="pure-sm-1 turnAnswerChooseListClipper" v-if="$store.state.playType == 'play'">
              <div v-for="answer in turnAnswerChooseList" :key="answer.key" class="ink shape04">
                <p>{{ answer.value }}</p>
                <span v-if="answer.value !== question && answer.value.toLowerCase() !== turnAnswer.toLowerCase()">
                  <button
                    class="pure-button pure-button-primary"
                    v-for="reaction in reactionList"
                    :key="reaction.key"
                    :data-reaction-group="answer.key"
                    @click="onReact(answer.key, reaction.key)"
                    :disabled="guessesReactedToList.includes(Number(answer.key))"
                  >
                    <IconDisplay :icon="reaction"></IconDisplay>
                  </button>
                </span>
                <br />
              </div>
            </div>
          </div>
          <br />
          <Tip></Tip>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('EndTurn') && $store.state.playType == 'watch'" class="centerContentIngameText" :key="NaN">
          <TurnResult
            @onTurnAnnounceEnd="onTurnAnnounceEnd"
            v-bind:oTurnResult="oTurnResult"
            v-bind:oTurnSummary="oTurnSummary"
            :key="NaN"
          ></TurnResult>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('EndGame')" :key="NaN">
          <EndGameResult
            id="chtEndGameResult"
            :content="endgameContent"
            :isPartyHost="isPartyHost"
            :users="users"
            :partyHost="partyHost"
          ></EndGameResult>
        </div>
      </transition>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: aniEnter, leave: aniLeave }">
        <div v-if="fsm.is('ReconnectWait')" class="centerContentIngameText ink shape01">
          <p v-if="reconnectNextRound > 0">
            {{
              $t("party.reconnectWaitForNextRound", {
                reconnectNextRound: reconnectNextRound,
              })
            }}
          </p>
          <p v-if="reconnectNextRound === 0">
            {{ $t("party.reconnectWaitForNextGame") }}
          </p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import DrawArea from "./DrawArea";
import DrawView from "./DrawView";
import TurnResult from "./TurnResult";
import EndGameResult from "./EndGameResult";
import StateMachine from "javascript-state-machine";
import reactionListJson from "../data/reactionList.json";
import PartyLobby from "./PartyLobby";
import Tip from "./Tip";
import TurnAnswerChooseDisplay from "./TurnAnswerChooseDisplay";
import IngameKick from "./IngameKick";
import IconDisplay from "./IconDisplay";
import CardView from "./CardView";
import ActivatedCardView from "./ActivatedCardView";

export default {
  name: "Party",
  components: {
    DrawArea,
    DrawView,
    TurnResult,
    EndGameResult,
    PartyLobby,
    Tip,
    TurnAnswerChooseDisplay,
    IngameKick,
    IconDisplay,
    CardView,
    ActivatedCardView,
  },
  data() {
    return {
      users: [],
      question: "",

      turnUser: null,
      turnDrawingBlob: null,
      timeToGuess: null,
      timeToGuessDuration: null,

      turnAnswer: "",
      turnAnswerChooseList: [],
      timeToPick: null,
      timeToPickDuration: null,
      lastStillDrawingRound: null,

      questionDrawing: "",
      questionDrawingBlob: null,
      timeToDrawPicture: null,
      timeToDrawPictureDuration: null,

      activatedCards: [], //ad-hoc activated cards
      activeCards: [], //cards that are active during drawing
      lastCardPickedInRound: null,

      timeToDrawStart: null,
      timeToDrawStartDate: null,
      timeToDrawStartDuration: null,

      oTurnResult: {},
      guessesReactedToList: [],
      oTurnSummary: {},

      numberWatchModes: 0,
      numberAIWatchModes: 0,

      pickButtonsEnabled: true,

      usersStillDrawingList: [],
      hasSomebodyCards: false,
      showingCardView: false,

      partyHost: null,
      isPartyHost: false,
      wasInUserlist: false,
      savedPartySettingsInitially: false,

      reactionList: reactionListJson,

      endgameContent: null,

      aniEnter: 300,
      aniLeave: 0,

      currentRetry: 0,
      maxRetries: 5,
      retryDelay: 3000,

      subscription: null,
      sessionSpecificSubscription: null,
      reconnectNextRound: 0,

      playerGetsCard: false,

      fsm: new StateMachine({
        //LobbyWait -> RoundAnnounce -> QuestionAnnounce -> QuestionWait -> SubmitAnswer -> TurnAnswerWait -> TurnAnswerChoose -> PickGuessWait -> EndTurn -> Endgame | (Reconnect)
        init: "LobbyWait",
        transitions: [
          {
            name: "toRoundAnnounce",
            from: ["LobbyWait", "EndTurn", "QuestionWait", "QuestionAnnounce", "ReconnectWait"], //2: From the end of a Round to the next; 3: Players: Nobody drew a drawing; 4: Watchers: Nobody drew a drawing; 5: from Reconnect
            to: "RoundAnnounce",
          },
          {
            name: "toQuestionAnnounce",
            from: ["RoundAnnounce", "ReconnectWait", "QuestionWait"], //3: Empty Drawing -> go back
            to: "QuestionAnnounce",
          },
          {
            name: "toQuestionWait",
            from: ["QuestionAnnounce", "ReconnectWait"],
            to: "QuestionWait",
          },
          {
            name: "toSubmitAnswer",
            from: ["QuestionWait", "EndTurn", "QuestionAnnounce", "ReconnectWait"], //2: From end of a turn to answering the next, 3: Watch Mode
            to: "SubmitAnswer",
          },
          {
            name: "toTurnAnswerWait",
            from: ["SubmitAnswer", "ReconnectWait"],
            to: "TurnAnswerWait",
          },
          {
            name: "toTurnAnswerChoose",
            from: ["TurnAnswerWait", "SubmitAnswer", "ReconnectWait"], //2: If there is not Wait time
            to: "TurnAnswerChoose",
          },
          {
            name: "toPickGuessWait",
            from: ["TurnAnswerChoose", "ReconnectWait"],
            to: "PickGuessWait",
          },
          {
            name: "toEndTurn",
            from: ["PickGuessWait", "TurnAnswerChoose", "ReconnectWait"],
            to: "EndTurn",
          },
          {
            name: "toEndGame",
            from: ["EndTurn", "QuestionWait", "QuestionAnnounce", "ReconnectWait"], //2: Players, when nobody drew a drawing; 3: Watchers, when nobody drew a drawing
            to: "EndGame",
          },
          {
            name: "toLobbyWait",
            from: ["EndGame", "ReconnectWait"], //1: Normal flow; 2: From Reconnect
            to: "LobbyWait",
          },
          {
            name: "toReconnectWait",
            from: [
              "LobbyWait",
              "RoundAnnounce",
              "QuestionAnnounce",
              "QuestionWait",
              "SubmitAnswer",
              "TurnAnswerWait",
              "TurnAnswerChoose",
              "PickGuessWait",
              "EndTurn",
              "Endgame",
            ],
            to: "ReconnectWait",
          },
        ],
        methods: {
          // onTransition: function(lifecycle) {
          //   console.log(lifecycle.transition + ": " + lifecycle.from + " -> " + lifecycle.to);
          // },
          onToRoundAnnounce: function() {
            if (!document.body.classList.contains("noScrollWrapper")) {
              document.body.classList.add("noScrollWrapper");
            }
            this.vue.$store.commit("PartyCode", {
              show: !this.vue.SettingsManager.settings.streamerMode,
              code: this.partyCode,
            });
            this.vue.$root.$emit("WatchTimerHide");

            if (this.vue.$store.state.round === 1) {
              this.vue.SoundManager.stopNarration();
              this.vue.SoundManager.playNarration("first-round");
            } else {
              this.vue.SoundManager.stopNarration();
              this.vue.SoundManager.playNarration("next-round", this.vue.$store.state.round);
            }
          },
          onToQuestionWait: function() {
            window.scrollTo(0, 1);
            this.vue.timeToDrawPicture = this.vue.timeToDrawPictureDuration = null;
            this.vue.$Progress.finish();
          },
          onToQuestionAnnounce: async function() {
            window.scrollTo(0, 1);
            this.vue.activatedCards = [];
            if (this.vue.$store.state.playType === "watch") {
              var activeCards = await this.vue.SettingsManager.getCookie("activeCards", this.vue.$isTauri);
              if (activeCards) {
                activeCards = JSON.parse(activeCards);
                if (Number(activeCards.round) === Number(this.vue.$store.state.round) && activeCards.partyCode === this.vue.$store.state.partyCode) {
                  this.vue.activeCards = activeCards.cards;
                }
              }

              if (this.vue.activeCards.indexOf("notime") === -1) {
                this.vue.SoundManager.startDrawingMusic();
                this.vue.$root.$emit("WatchTimerShow", this.vue.$store.state.partySettings.timeToDrawPicture);
              } else {
                this.vue.$root.$emit("WatchTimerShow", "?");
              }
              if (this.vue.$store.state.round === 1) {
                this.vue.SoundManager.stopNarration();
                this.vue.SoundManager.playNarration("drawing-first-round");
              } else {
                this.vue.SoundManager.stopNarration();
                this.vue.SoundManager.playNarration("next-round-drawing");
              }

              var timeToTimeisRunningOutNarration = this.vue.$store.state.partySettings.timeToDrawPicture - 10;
              if (timeToTimeisRunningOutNarration > 0) {
                setTimeout(() => {
                  if (this.vue.usersStillDrawingList.length > 0) {
                    this.vue.SoundManager.playNarration("time-is-up");
                  }
                }, timeToTimeisRunningOutNarration * 1000);
              }
            }
            this.vue.usersStillDrawingList = [].concat(this.vue.users);
          },
          onToSubmitAnswer: function() {
            if (this.vue.$store.state.playType === "watch") {
              this.vue.SoundManager.startBackgroundMusic();

              this.vue.SoundManager.stopNarration();
              this.vue.SoundManager.playNarration("guessing", this.vue.turnUser);
            }
            this.vue.$root.$emit("backgroundChange");
          },
          onToTurnAnswerWait: function() {
            window.scrollTo(0, 1);
            this.vue.timeToGuess = this.vue.timeToGuessDuration = null;
            this.vue.$Progress.finish();
            this.vue.$root.$emit("WatchTimerHide");
          },
          onToTurnAnswerChoose: function() {
            window.scrollTo(0, 1);
            if (this.vue.$store.state.playType != "watch") {
              document.body.classList.remove("noScrollWrapper");
            } else {
              this.vue.SoundManager.stopNarration();
              this.vue.SoundManager.playNarration("picking", this.vue.turnUser);
            }
            this.vue.timeToGuess = this.vue.timeToGuessDuration = null;
            this.vue.$Progress.finish();
            this.vue.$root.$emit("WatchTimerHide");
          },
          onToPickGuessWait: function() {
            window.scrollTo(0, 1);
            this.vue.timeToPick = this.vue.timeToPickDuration = null;
            this.vue.$Progress.finish();
          },
          onToEndTurn: function() {
            window.scrollTo(0, 1);
            this.vue.timeToPick = this.vue.timeToPickDuration = null;
            this.vue.$Progress.finish();
            this.guessesReactedToList = [];
            this.vue.$root.$emit("backgroundChange");
            this.vue.getHandcards();
            this.vue.clearActiveCards();

            this.vue.SoundManager.stopNarration();
            this.vue.SoundManager.playNarration("drawing-comment");
          },
          onToEndGame: function() {
            this.vue.clearActiveCards();
            this.vue.$root.$emit("WatchTimerHide");
            this.vue.hasSomebodyCards = this.vue.showingCardView = false;
          },
          onToLobbyWait: function() {
            //Reset for next Round
            this.vue.$store.commit("NumberQuestionRedraws", {
              value: this.vue.$store.state.partySettings.numberQuestionRedraws,
            });
            if (!this.vue.SettingsManager.settings.streamerMode) {
              this.vue.$store.commit("PartyCode", {
                show: true,
                code: this.partyCode,
              });
            }
          },
        },
      }),
    };
  },
  timers: {
    onRoundAnnounceEnd: { time: 6000 }, //redundant, is set in Backend with timeToDrawStart
  },
  watch: {
    usersStillDrawingList: function(aNewList, aOldList) {
      if (this.$store.state.playType === "watch") {
        if (aOldList.length > aNewList.length) {
          this.SoundManager.playSfx("submit-drawing");
          if (this.lastStillDrawingRound !== this.$store.state.round) {
            this.SoundManager.playNarration("people-still-drawing");
            this.lastStillDrawingRound = this.$store.state.round;
          }
          setTimeout(
            function() {
              aOldList.forEach((oOldUser) => {
                if (!aNewList.some((oUser) => oUser.name === oOldUser.name)) {
                  this.SoundManager.playPlayersound(oOldUser.playersoundRef);
                }
              });
            }.bind(this),
            1000
          );
        }
      }
    },
    activeCards: function(aNewActiveCards) {
      //save activeCards as cookie
      if (aNewActiveCards.length === 0) {
        this.SettingsManager.removeCookie("activeCards", this.$isTauri);
      } else {
        this.SettingsManager.saveCookie(
          "activeCards",
          JSON.stringify({ partyCode: this.$store.state.partyCode, round: this.$store.state.round, cards: aNewActiveCards }),
          1,
          this.$isTauri
        );
      }
    },
  },
  metaInfo() {
    return {
      title: `Super Sketchy Party (${this.$store.state.partyCode})`,
    };
  },
  mounted: async function() {
    this.$nextTick(function() {
      this.timer = window.setInterval(() => {
        this.update();
      }, 1000);
    });
    this.fsm.vue = this;

    this.$attachZoomHandler();
    window.scrollTo(0, 1);

    this.$root.$emit("backgroundChange");

    this.$store.commit("Secret", {
      value: await this.SettingsManager.getCookie("supersketchysecret-" + this.$store.state.username, this.$isTauri),
      name: this.$store.state.username,
      vue: this,
    });

    this.$store.commit("WebsocketClientId", this.$uuidv4());

    document.onkeyup = function(e) {
      if (e.key === "Escape") {
        this.$root.$emit("PauseMenuToggle");
      }
    }.bind(this);

    this.SettingsManager.getBrowserSettings(this);

    this.$store.commit("PartyCode", {
      show: !this.SettingsManager.settings.streamerMode,
    });

    if (this.$store.state.playType === "watch") {
      document.body.classList.add("noScrollWrapper");
    }

    this.$root.$on(
      "Leave",
      function(bKickYourself) {
        this.onLeave(bKickYourself);
      }.bind(this)
    );

    this.$setControllerViewConfig({ controls: this.$tabbable(this.$refs.root) });
  },
  beforeDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.timeToDrawPicture = this.timeToDrawPictureDuration = null;
      this.timeToGuess = this.timeToGuessDuration = null;
      this.timeToPick = this.timeToPickDuration = null;
    }
    if (this.socket) {
      this.socket.close();
    }
    window.clearInterval(this.timer);
  },
  created() {
    if (this.$route.params.partyCode.toUpperCase() !== this.$store.state.partyCode) {
      this.$store.commit("PartyCode", {
        code: this.$route.params.partyCode.toUpperCase(),
      });
    }

    this.$store.commit("Username", this.$route.params.name);

    if (this.$store.state.username && this.$store.state.partyCode) {
      this.$store.commit("PlayType", "play");
    } else {
      this.$store.commit("PlayType", "watch");
    }

    this.socket = new SockJS(`//${window.location.host}/supersketchyparty-websocket`);
    this.stompClient = Stomp.over(this.socket);
    this.stompClient.reconnect_delay = 0;
    this.stompClient.debug = function() {};
    this.stompClient.connect({}, this.websocketHandle, this.websocketHandleError.bind(this));

    axios.interceptors.response.use(undefined, this.$axiosErrorInterceptor.bind(this));
  },
  methods: {
    websocketHandleError: function(event) {
      //Error
      if (this.socket) {
        this.socket.close(); //otherwise it stays open anyway
      }
      this.connected = false;
      if (
        !event.headers ||
        //if connection failed because of these reasons, backend will deny connection again on retry with same reason
        (!event.headers.message.includes("SSP_PARTY_DOES_NOT_EXIST") &&
          !event.headers.message.includes("SSP_VALIDATION_FAILED") &&
          !event.headers.message.includes("SSP_UNAUTHORIZED"))
      ) {
        if (event.code !== 1000) {
          // i.e. Abnormal Closure (iOS Smartphone Locked) 1006
          //wait a few seconds before trying to reconnect
          if (this.currentRetry <= this.maxRetries) {
            this.currentRetry++;
            setTimeout(
              function() {
                this.$notify({
                  group: "notifications",
                  type: "warn",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: `${this.$getMessage("error.reconnecting")} ${this.currentRetry}/${this.maxRetries}`,
                });

                this.socket = new SockJS(`//${window.location.host}/supersketchyparty-websocket`);
                this.stompClient = Stomp.over(this.socket);
                this.stompClient.reconnect_delay = 0;
                this.stompClient.debug = function() {};
                this.stompClient.connect({}, this.websocketHandle, this.websocketHandleError.bind(this));
              }.bind(this),
              this.retryDelay
            );
          } else {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: this.$getMessage("error.reconnectFailed"),
            });
          }
        }
      } else {
        this.$notify({
          group: "notifications",
          type: "error",
          position: "top center",
          title: this.$getMessage("error.genericError"),
          text: this.$getMessage("error.reconnectHeaderFailed"),
        });
      }
    },

    websocketHandle: function() {
      this.connected = true;
      while (this.stompClient.subscriptions.length > 0) {
        this.stompClient.unsubscribe();
      }
      this.subscription = this.stompClient.subscribe(
        "/topic/party/" + this.$store.state.partyCode.toUpperCase(),
        function(message) {
          var msg = JSON.parse(message.body);
          var content = msg.content;
          if (msg.type === "reconnect") {
            if (content.gameInProgress === true) {
              this.reconnectNextRound = content.nextRound;
              this.$store.commit("NumberQuestionRedraws", {
                value: content.redrawsLeft,
              });

              this.turnAnswer = content.turnAnswer;
              this.question = content.question;
              this.turnDrawingBlob = content.turnDrawingBlob;
              this.userContentId = content.userContentId;

              if (content.langu != this.$root.$i18n.locale) {
                this.SettingsManager.settings.uiLangu = content.langu;
                this.$root.$i18n.locale = content.langu;
                this.$root.$emit("languChange", this.$root.$i18n.locale);
              }

              if (content.nextRound > 0) {
                this.$store.commit("Round", {
                  value: content.nextRound - 1,
                });
              } else {
                this.$store.commit("Round", {
                  value: this.$store.state.partySettings.numberRounds,
                });
              }

              //Overwrite message with last WebSocket Message
              msg = JSON.parse(atob(content.lastWSMessage));
              content = msg.content;

              this.fsm.toReconnectWait();
            }
          }
          switch (msg.type) {
            case "lobby":
              var aUsers = [];
              var isInUserlist = false;

              content.userlist.forEach(
                function(entry) {
                  if (entry.name === this.$store.state.username) {
                    isInUserlist = true;
                    this.wasInUserlist = true;
                  }
                  aUsers.push(entry);
                }.bind(this)
              );

              this.numberWatchModes = content.numberWatchModes;
              this.numberAIWatchModes = content.numberAIWatchModes;

              if (!isInUserlist && this.wasInUserlist && this.$store.state.playType === "play") {
                this.onLeave(false);
              }

              if (this.$store.state.numberQuestionRedraws === -1) {
                this.$store.commit("NumberQuestionRedraws", {
                  value: content.partySettings.numberQuestionRedraws,
                });
              }

              this.$store.commit("PartySetting", {
                setting: "numberQuestionRedraws",
                value: content.partySettings.numberQuestionRedraws,
                vue: this,
              });

              if (this.fsm.is("LobbyWait")) {
                this.$store.commit("NumberQuestionRedraws", {
                  value: this.$store.state.partySettings.numberQuestionRedraws,
                });
              }

              this.$store.commit("PartySetting", {
                setting: "numberRounds",
                value: content.partySettings.numberRounds,
                vue: this,
              });
              this.partyHost = content.partySettings.partyHost;

              this.$store.commit("PartySetting", {
                setting: "timeToDrawPicture",
                value: content.partySettings.timeToDrawPicture,
                vue: this,
              });
              this.$store.commit("PartySetting", {
                setting: "timeToGuess",
                value: content.partySettings.timeToGuess,
                vue: this,
              });
              this.$store.commit("PartySetting", {
                setting: "timeToPick",
                value: content.partySettings.timeToPick,
                vue: this,
              });
              this.$store.commit("PartySetting", {
                setting: "timeToGuess",
                value: content.partySettings.timeToGuess,
                vue: this,
              });
              this.$store.commit("PartySetting", {
                setting: "awardCardsAtGameStart",
                value: content.partySettings.awardCardsAtGameStart,
                vue: this,
              });
              this.$store.commit("PartySetting", {
                setting: "carryOverCardsToNextGame",
                value: content.partySettings.carryOverCardsToNextGame,
                vue: this,
              });

              if (content.partySettings.langu != this.$root.$i18n.locale) {
                this.SettingsManager.settings.uiLangu = content.partySettings.langu;
                this.$root.$i18n.locale = content.partySettings.langu;
                this.$root.$emit("languChange", this.$root.$i18n.locale);
              }

              this.isPartyHost = this.partyHost === this.$store.state.username;
              if (!this.savedPartySettingsInitially && this.isPartyHost) {
                this.savedPartySettingsInitially = true;
                this.onSavePartySettings(null, true);
              }

              this.users = aUsers;
              if (this.fsm.is("EndGame") && content.transitionToLobby === true) {
                this.question = "";
                this.fsm.toLobbyWait();
              }

              if (msg.type === "inactiveusers") {
                if (this.$store.state.username === this.partyHost) {
                  this.$notify({
                    group: "notifications",
                    type: "error",
                    position: "top center",
                    title: this.$getMessage("error.genericError"),
                    text: `${this.$getMessage("error.inactiveUsers")} ${content}`,
                  });
                }
              }
              break;

            case "roundstart":
              this.$store.commit("Round", {
                value: content.round,
              });

              if (!this.fsm.is("RoundAnnounce")) {
                this.question = "";
                this.fsm.toRoundAnnounce();
              }
              // Caution: I am telling you this as a friend. It exists. It is a thing, but it is a hack. Please don't use it. — Paul
              window.scrollTo(0, 1);

              //statistics
              if (Number(content.round) === 1 && this.reconnectNextRound === 0) {
                axios
                  .post(
                    "/api/statistics/party/" + this.$store.state.partyCode.toUpperCase() + "/uimode",
                    { playType: this.$store.state.playType, playMode: "normal" },
                    {
                      headers: {
                        "x-supersketchysecret": this.$store.state.superSketchySecret,
                      },
                    }
                  )
                  .catch((err) => {
                    if (err.response) {
                      this.$notify({
                        group: "notifications",
                        type: "error",
                        position: "top center",
                        title: this.$getMessage("error.genericError"),
                        text: err.response.data.message,
                      });
                    }
                  });
              }

              this.turn = 0;

              this.timers.onRoundAnnounceEnd.time = content.timeToDrawStart * 1000;
              this.$timer.start("onRoundAnnounceEnd");
              this.timeToDrawStartDate = new Date(new Date().getTime() + content.timeToDrawStart * 1000);
              this.timeToDrawStart = content.timeToDrawStart;
              this.getHandcards();

              this.enablePickButtons();
              break;

            case "timeToPickCards":
              this.timers.onRoundAnnounceEnd.time = content * 1000;
              this.$timer.restart("onRoundAnnounceEnd");
              this.timeToDrawStartDate = new Date(new Date().getTime() + content * 1000);
              this.timeToDrawStart = content;

              if (this.lastCardPickedInRound === this.$store.state.round) {
                this.SoundManager.playNarration("card-picking-another");
              } else {
                this.SoundManager.stopNarration();
                this.SoundManager.playNarration("card-picking");
              }

              this.lastCardPickedInRound = this.$store.state.round;
              break;

            case "timeToDraw":
              this.fsm.toQuestionAnnounce();
              this.$timer.stop("onRoundAnnounceEnd");
              this.onRoundAnnounceEnd();

              if (this.$store.state.playType === "play") {
                axios
                  .get("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/username/" + this.$store.state.username + "/question", {
                    headers: {
                      "x-supersketchysecret": this.$store.state.superSketchySecret,
                    },
                  })
                  .then(
                    function(resp) {
                      this.question = resp.data.question;
                      if (resp.data.activeCards) {
                        this.activeCards = this.activeCards.concat(resp.data.activeCards);
                      }
                      if (resp.data.latestSubmit < 0) {
                        resp.data.latestSubmit = 10000;
                      }
                      this.timeToDrawPicture = new Date(new Date().getTime() + resp.data.latestSubmit);
                    }.bind(this)
                  )
                  .catch((err) => {
                    if (err.response) {
                      this.$notify({
                        group: "notifications",
                        type: "error",
                        position: "top center",
                        title: this.$getMessage("error.genericError"),
                        text: err.response.data.message,
                      });
                    }
                  });
              }
              break;

            case "startturn":
              this.turnAnswer = "";
              this.turnUser = content.username;
              this.turnDrawingBlob = content.drawingBlob;

              this.timeToGuess = new Date(new Date().getTime() + content.latestSubmit);
              if (!this.fsm.is("SubmitAnswer")) {
                this.fsm.toSubmitAnswer();
                if (this.$store.state.playType == "watch") {
                  this.$root.$emit("WatchTimerShow", this.$store.state.partySettings.timeToGuess);
                }
              }
              this.enablePickButtons();
              this.questionDrawingBlob = null;
              this.clearActiveCards();
              this.activeCards = this.activeCards.concat(content.activeCards);
              break;

            case "startpick":
              this.userContentId = content.userContentId;
              this.turnAnswerChooseList = content.guesses;

              var i = 1;
              this.turnAnswerChooseList.forEach((answer) => (answer.index = i++));

              this.timeToPick = new Date(new Date().getTime() + content.latestSubmit);
              if (!this.fsm.is("TurnAnswerChoose")) {
                this.fsm.toTurnAnswerChoose();
                if (this.$store.state.playType == "watch") {
                  this.$root.$emit("WatchTimerShow", this.$store.state.partySettings.timeToPick);
                }
              }
              break;

            case "endturn":
              this.oTurnResult.contentCreatorUser = content.contentCreatorUser;
              this.oTurnResult.guesses = content.guesses;
              this.oTurnSummary = content.turnSummary;
              this.hasSomebodyCards = this.hasSomebodyCards || content.turnSummary.some((turnSummary) => turnSummary.newCards.length > 0);
              this.oTurnResult.users = this.users;
              this.oTurnResult.turnDrawingBlob = this.turnDrawingBlob;
              this.oTurnResult.playMode = "normal";
              this.oTurnResult.username = this.$store.state.username;
              if (this.turnAnswer) {
                this.oTurnResult.turnAnswer = this.turnAnswer.toLowerCase();
              }

              if (content.turnSummary.some((turnSummary) => turnSummary.username === this.$store.state.username && turnSummary.newCards.length > 0)) {
                this.$root.$emit("Confetti");
                this.playerGetsCard = true;
                setTimeout(() => {
                  this.playerGetsCard = false;
                }, 2500);
              }

              this.transformTurnResult();

              if (!this.fsm.is("EndTurn")) {
                this.fsm.toEndTurn();
              }
              break;

            case "newreaction":
              this.transformReactions(content);
              break;

            case "showreaction":
              this.onReactionShow(content.reaction);
              break;

            case "newdrawinguserlist":
              this.usersStillDrawingList = [];
              content.forEach((username) => {
                this.usersStillDrawingList.push(this.users.find((user) => user.name === username));
              });
              break;

            case "newguessinguserlist":
              if (this.$store.state.playType === "watch") {
                this.SoundManager.playSfx("guess-sent");
                var stillGuessingUsers = content.usersStillGuessing.join();
                if (stillGuessingUsers.length > 0) {
                  this.SoundManager.playNarration("comments-guesses", stillGuessingUsers);
                }
                this.SoundManager.playPlayersound(this.users.find((u) => u.name === content.userThatFinishedGuessing).playersoundRef);
              }
              break;

            case "endgame":
              this.endgameContent = content;
              if (!this.fsm.is("EndGame")) {
                this.SoundManager.stopNarration();
                this.SoundManager.playNarration("end-of-game");
                this.fsm.toEndGame();
              }
              break;

            case "partydestroyed":
              if (this.$store.state.username !== this.partyHost) {
                this.onLeave(true);
              }
              break;

            case "cardActivation":
              this.activatedCards.splice(0, 0, content);
              this.SoundManager.playNarration("card-activated", content.targetPlayer + content.cardName + content.sourcePlayer);
              if (
                (this.$store.state.playType === "watch" && content.cardName === "notime") ||
                (this.$store.state.playType === "play" && content.targetPlayer === this.$store.state.username)
              ) {
                this.activeCards.push("notime");
              }
              break;

            case "activeCards":
              if (this.$store.state.playType === "watch") {
                this.activeCards = content;
              }
              break;
          }
          // message.ack();
        }.bind(this),
        this.$store.state.username
          ? {
              username: this.$store.state.username,
              "x-supersketchysecret": this.$store.state.superSketchySecret,
              websocketClientId: this.$store.state.websocketClientId,
            }
          : {
              websocketClientId: this.$store.state.websocketClientId,
            }
      );

      if (this.$isTauri) {
        this.clientSpecificSubscription = this.stompClient.subscribe(
          "/topic/session/" + this.$store.state.websocketClientId + "/party/" + this.$store.state.partyCode.toUpperCase(),
          function(message) {
            var msg = JSON.parse(message.body);
            // console.log("Received session specific message: ", msg);
            var content = msg.content;
            if (msg.type === "airecognition") {
              //content example:
              // {
              //       "username": "Bob",
              //       "round": 1,
              //       "questionId": 2183,
              //       "drawnPictureBlob": "some base64 encoded picture"
              // }
              parent.postMessage({ type: "aiRecognition", payload: content }, "*");
            }
          }.bind(this),
          this.$store.state.username
            ? {
                username: this.$store.state.username,
                "x-supersketchysecret": this.$store.state.superSketchySecret,
                websocketClientId: this.$store.state.websocketClientId,
              }
            : {
                websocketClientId: this.$store.state.websocketClientId,
              }
        );

        this.$root.$on(
          "retrieve-ai-performance-result",
          function(performance) {
            const performancePayload = JSON.stringify(performance);
            this.stompClient.send("/topic/party/" + this.$store.state.partyCode.toUpperCase() + "/aiperformance", performancePayload, {
              priority: 9,
            });
          }.bind(this)
        );

        this.$root.$on(
          "ai-recognition-result",
          function(frontendResponse) {
            //content example:
            // {
            //       "username": "Bob",
            //       "round": 1,
            //       "questionId": 2183,
            //       "drawnPictureBlob": "some base64 encoded picture"
            //       "result": "some result"
            // }

            if(frontendResponse.result === undefined){
              return;
            }

            let performanceProxyPayload = {};
            performanceProxyPayload.username = frontendResponse.username;
            performanceProxyPayload.round = frontendResponse.round;
            performanceProxyPayload.questionId = frontendResponse.questionId;
            performanceProxyPayload.result = frontendResponse.result;
            const performancePayload = JSON.stringify(performanceProxyPayload);

            this.stompClient.send(
              "/topic/party/" + this.$store.state.partyCode.toUpperCase() + "/airecognitionresult",
              performancePayload,
              { priority: 9 },
              ""
            );
          }.bind(this)
        );

        parent.postMessage({ type: "retrieveAiPerformance" }, "*");
      }
    },

    onStartGame: function() {
      this.stompClient.send("/topic/party/" + this.$store.state.partyCode.toUpperCase() + "/startgame", { priority: 9 }, "");
    },

    onRoundAnnounceEnd: function() {
      this.showingCardView = false;
    },

    onTurnAnnounceEnd: function() {
      axios
        .post("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/startnext", this.userContentId, {
          headers: {
            "Content-Type": "text/plain",
            "x-supersketchysecret": this.$store.state.superSketchySecret,
          },
        })
        .catch((err) => {
          if (err.response) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: err.response.data.message,
            });
          }
        });
    },

    onSubmitDrawing: function(isPressed) {
      if ((this.$refs.DrawAreaParty && this.$refs.DrawAreaParty.apngFrames.length > 0) || !isPressed) {
        this.$Progress.finish();
        this.timeToDrawPicture = this.timeToDrawPictureDuration = null;
        this.fsm.toQuestionWait();
      } else {
        this.$notify({
          group: "notifications",
          type: "error",
          position: "top center",
          title: this.$getMessage("error.genericError"),
          text: this.$getMessage("error.emptyDrawing"),
        });
      }

      this.$refs.DrawAreaParty.exportAPNG()
        .then((oResolveObject) => {
          this.questionDrawingBlob = oResolveObject.oBlob;
          if (this.questionDrawingBlob.size > 0) {
            var formData = new FormData();
            formData.append("performanceData", JSON.stringify(oResolveObject.oPerformanceInfo));
            formData.append("round", this.$store.state.round);
            formData.append("drawnPictureBlob", this.questionDrawingBlob);

            axios
              .put("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/username/" + this.$store.state.username + "/drawing", formData, {
                "Content-Type": undefined,
                headers: {
                  "x-supersketchysecret": this.$store.state.superSketchySecret,
                },
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status === 400) {
                    this.$notify({
                      group: "notifications",
                      type: "error",
                      position: "top center",
                      title: this.$getMessage("error.genericError"),
                      text: this.$getMessage("error.tooktoolong"),
                    });
                  } else {
                    this.$notify({
                      group: "notifications",
                      type: "error",
                      position: "top center",
                      title: this.$getMessage("error.genericError"),
                      text: err.response.data.message,
                    });
                  }
                }
              });
          } else {
            if (isPressed) {
              this.$notify({
                group: "notifications",
                type: "error",
                position: "top center",
                title: this.$getMessage("error.genericError"),
                text: this.$getMessage("error.emptyDrawing"),
              });
              this.fsm.toQuestionAnnounce();
            }
          }
        })
        .catch(() => {
          if (isPressed) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: this.$getMessage("error.emptyDrawing"),
            });
            this.fsm.toQuestionAnnounce();
          }
        });
    },

    onSubmitTurnAnswer: function() {
      if (this.turnAnswer !== "") {
        axios
          .put("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/username/" + this.$store.state.username + "/guess", this.turnAnswer, {
            headers: {
              "Content-Type": "text/plain",
              "x-supersketchysecret": this.$store.state.superSketchySecret,
            },
          })
          .then(
            function() {
              this.$Progress.finish();
              this.timeToGuess = this.timeToGuessDuration = null;
              this.fsm.toTurnAnswerWait();
            }.bind(this)
          )
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 409) {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: this.$getMessage("error.guessTooClose"),
                });
              } else {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: err.response.data.message,
                });
              }
            }
          });
      } else {
        if (this.$refs.inpTurnAnswer) {
          this.$refs.inpTurnAnswer.focus();
        }
      }
    },

    onSubmitPickGuess: function(oControl) {
      var sGuess = oControl.target.id;
      this.fsm.toPickGuessWait();
      axios
        .put("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/username/" + this.$store.state.username + "/pickguess", sGuess, {
          headers: {
            "Content-Type": "text/plain",
            "x-supersketchysecret": this.$store.state.superSketchySecret,
          },
        })
        .catch((err) => {
          if (err.response) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: err.response.data.message,
            });
          }
        });
    },

    onRedrawQuestion: function() {
      axios
        .get("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/username/" + this.$store.state.username + "/redrawQuestion", {
          headers: {
            "x-supersketchysecret": this.$store.state.superSketchySecret,
          },
        })
        .then(
          function(resp) {
            this.$refs.DrawAreaParty.clearCanvas();
            this.question = resp.data.question;
            this.$store.commit("NumberQuestionRedraws", {
              value: this.$store.state.numberQuestionRedraws - 1,
            });
            this.$calcZoom();
          }.bind(this)
        )
        .catch(
          function(err) {
            if (err.response.status === 412) {
              this.$notify({
                group: "notifications",
                type: "error",
                position: "top center",
                title: this.$getMessage("error.genericError"),
                text: this.$getMessage("error.noMoreRedraws"),
              });
            } else {
              if (err.response) {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: err.response.data.message,
                });
              }
            }
          }.bind(this)
        );
    },

    onKickPlayer: function(username) {
      axios
        .put("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/kick/" + username, null, {
          headers: {
            "Content-Type": "text/plain",
            "x-supersketchysecret": this.$store.state.superSketchySecret,
          },
        })
        .catch((err) => {
          if (err.response) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: err.response.data.message,
            });
          }
        });
    },

    onReact: function(guessKey, reactionKey) {
      document.querySelectorAll('*[data-reaction-group="' + guessKey + '"]').forEach(function(button) {
        button.disabled = true;
      });
      this.guessesReactedToList.push(Number(guessKey));
      axios
        .put(
          "/api/party/" +
            this.$store.state.partyCode.toUpperCase() +
            "/username/" +
            this.$store.state.username +
            "/guess/" +
            guessKey +
            "/addreaction",
          reactionKey,
          {
            headers: {
              "Content-Type": "text/plain",
              "x-supersketchysecret": this.$store.state.superSketchySecret,
            },
          }
        )
        .catch((err) => {
          if (err.response) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: err.response.data.message,
            });
          }
        });
    },

    update: function() {
      if (this.timeToDrawPicture) {
        this.timeToDrawPictureDuration = new Date(this.timeToDrawPicture - new Date()).getSeconds();
        if (this.activeCards.indexOf("notime") === -1) {
          this.$Progress.set((this.timeToDrawPictureDuration / this.$store.state.partySettings.timeToDrawPicture) * 100);
        }
      }

      if (this.timeToGuess) {
        this.timeToGuessDuration = new Date(this.timeToGuess - new Date()).getSeconds();
        this.$Progress.set((this.timeToGuessDuration / this.$store.state.partySettings.timeToGuess) * 100);
      }

      if (this.timeToPick) {
        this.timeToPickDuration = new Date(this.timeToPick - new Date()).getSeconds();
        this.$Progress.set((this.timeToPickDuration / this.$store.state.partySettings.timeToPick) * 100);
      }

      if (this.timeToDrawStart) {
        this.timeToDrawStartDuration = new Date(this.timeToDrawStartDate - new Date()).getSeconds();
        this.$Progress.set((this.timeToDrawStartDuration / this.timeToDrawStart) * 100);
      }

      if (this.timeToDrawPictureDuration <= 0 && this.timeToDrawPictureDuration != null) {
        this.onSubmitDrawing(false);
        this.timeToDrawPicture = this.timeToDrawPictureDuration = null;
        this.$Progress.finish();
      }

      if (this.timeToGuessDuration <= 0 && this.timeToGuessDuration != null) {
        this.onSubmitTurnAnswer();
        this.timeToGuess = this.timeToGuessDuration = null;
        this.$Progress.finish();
      }

      if (this.timeToPickDuration <= 0 && this.timeToPickDuration != null) {
        this.disablePickButtons();
        this.timeToPick = this.timeToPickDuration = null;
        this.$Progress.finish();
      }

      if (this.timeToDrawStartDuration <= 0 && this.timeToDrawStartDuration != null) {
        this.timeToDrawStart = this.timeToDrawStartDuration = this.timeToDrawStartDate = null;
        this.$Progress.finish();
      }
    },

    transformReactions: function(reactions) {
      if (this.oTurnSummary && this.oTurnSummary.length !== undefined) {
        reactions.forEach(
          function(reaction) {
            var i = this.oTurnSummary.findIndex((x) => x.username === reaction.username);
            if (i > -1) {
              this.oTurnSummary[i].reactions = reaction.reactions;
              this.$set(this.oTurnSummary[i], "reactions", reaction.reactions);
            }
          }.bind(this)
        );
      }
    },

    disablePickButtons: function() {
      this.pickButtonsEnabled = false;
    },

    enablePickButtons: function() {
      this.pickButtonsEnabled = true;
    },

    isEmptyObject: function(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }
      return true;
    },

    onLeave: function(bKickYourself) {
      this.$router.go(-1);

      this.$root.$emit("PauseMenuClose");
      this.$store.commit("PartyCode", { show: false, code: this.partyCode });
      this.SoundManager.stopNarration();

      if (!bKickYourself) {
        this.$notify({
          group: "notifications",
          type: "error",
          position: "top center",
          text: this.$getMessage("party.kicked"),
        });
      }

      this.socket.close();

      if (bKickYourself && this.$store.state.username) {
        //setTimeout, so it is called after the player websocket is disconnected.
        //otherwise the disconnect playerlobby message arrives later & player is shown as disconnected and is not removed completely
        setTimeout(() => this.onKickPlayer(this.$store.state.username), 1000);
      }
    },

    getRotation: function(guessKey, positiveRotation) {
      if (this.oTurnResult.guesses) {
        var maxGuessKey = Math.max.apply(
          Math,
          this.oTurnResult.guesses.map(function(guess) {
            return guess.key;
          })
        );
        var minGuessKey = Math.min.apply(
          Math,
          this.oTurnResult.guesses.map(function(guess) {
            return guess.key;
          })
        );
        var normalizedKey = this.normalize(guessKey, minGuessKey, maxGuessKey);
        var rotDegree = 3;
        if (positiveRotation) {
          return Math.floor(0 + normalizedKey * (rotDegree + 1 - 0));
        } else {
          return Math.floor(-rotDegree + normalizedKey * (0 + 1 - -rotDegree));
        }
      } else {
        return 0;
      }
    },

    normalize: function(val, min, max) {
      if (max - min === 0) return 1;
      return (val - min) / (max - min);
    },

    onReactionShow: function(sEmoji) {
      var dir = ["Left", "Right"][Math.floor(Math.random() * 2)];
      var dirSign = "+";
      if (dir === "Right") {
        dirSign = "-";
      }

      var icon = this.reactionList.find((reaction) => reaction.key === sEmoji);

      for (let i = 0; i < 10; i++) {
        this.spawnFloat({
          id: this.$uuidv4(),
          i: i,
          icon: icon,
          dir: dir,
          dirSign: dirSign,
          size: `emojiSize${Math.floor(Math.random() * 5) + 1}`,
          visible: true,
        });
      }

      if (this.$store.state.playType == "watch") {
        this.SoundManager.playSfx(sEmoji);
      }
    },

    spawnFloat: function(float) {
      var emojiSpan = document.createElement("span");

      emojiSpan.classList.add(`emojiFloat${float.dir}`);
      emojiSpan.classList.add(float.size);

      if (float.icon.type === "emoji") {
        emojiSpan.innerHTML = float.icon.emoji;
      } else if (float.icon.type === "svg") {
        emojiSpan.innerHTML = `<img src="${float.icon.emoji}" alt="icon" class="iconSvg"/>`;
      }

      var emojiAni = emojiSpan.animate(
        [
          { transform: "translate(0px, 0px)", opacity: "0%", offset: 0 },
          {
            transform: `translate(${float.dirSign}${Math.floor(Math.random() * (window.innerWidth / 2 - 0 + 1)) + 0}px, -${window.innerHeight *
              1.1}px)`,
            opacity: "50%",
            offset: 0.99,
          },
          {
            transform: `translate(0px, -${window.innerHeight * 1.1}px)`,
            opacity: "0%",
            offset: 1,
          },
        ],
        {
          duration: 3000 + float.i * 150,
          iterations: 1,
          easing: "ease-in",
        }
      );

      emojiAni.child = emojiSpan;
      emojiAni.onfinish = function() {
        this.child.remove();
      };

      document.body.appendChild(emojiSpan);
    },

    transformTurnResult: function() {
      this.oTurnResult.guesses.sort(function(guess1, guess2) {
        if (guess1.correct) {
          return 1;
        }
        if (guess2.correct) {
          return -1;
        }
      });

      let i = 1;
      this.oTurnResult.guesses.forEach(
        function(guess) {
          if (this.oTurnResult.users) {
            var aUser = [];
            guess.users.forEach(
              function(username) {
                var oUser = {};
                oUser.name = username;
                for (let index = 0; index < this.oTurnResult.users.length; index++) {
                  var user2 = this.oTurnResult.users[index];
                  if (user2.name === oUser.name) {
                    oUser.userAvatarBlob = user2.avatarBlob;
                    oUser.playersoundRef = user2.playersoundRef;
                  }
                }
                aUser.push(oUser);
              }.bind(this)
            );
          }
          guess.internalUsers = aUser;
          guess.showAvatar = false;

          if (guess.internalUsers.length > 0) {
            guess.displaydelay = 2250 * i;
            i++;
          } else {
            guess.displaydelay = 0;
          }
        }.bind(this)
      );

      this.oTurnResult.guesses = this.oTurnResult.guesses
        .map((a) => ({
          sort: this.$xmur3(a.text)(),
          value: a,
        }))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value);

      i = 1;
      this.oTurnResult.guesses.forEach(function(guess) {
        guess.index = i;
        i++;
      });

      i = 1;
      this.oTurnSummary.forEach(
        function(summary) {
          if (!summary.reactions) {
            summary.reactions = [];
          }
          summary.index = i;
          if (this.oTurnResult.users) {
            for (let index = 0; index < this.oTurnResult.users.length; index++) {
              var user2 = this.oTurnResult.users[index];
              if (user2.name === summary.username) {
                summary.userAvatarBlob = user2.avatarBlob;
              }
            }
          }
          i++;
          summary.pointsGained = summary.pointsGained > 0 ? "+" + summary.pointsGained :  summary.pointsGained;
        }.bind(this)
      );

      this.oTurnResult.correctAnswer = this.oTurnResult.guesses.find((guess) => {
        return guess.correct === true;
      }).text;
    },

    onSendDoneDrawing: function() {
      axios.put(
        "/api/party/" +
          this.$store.state.partyCode.toUpperCase() +
          "/username/" +
          this.$store.state.username +
          "/donedrawing/round/" +
          this.$store.state.round,
        null,
        {
          headers: {
            "x-supersketchysecret": this.$store.state.superSketchySecret,
          },
        }
      );
    },

    onSavePartySettings: function(oEvent, bSilent) {
      if (oEvent) {
        this.$store.commit("PartySetting", {
          setting: oEvent.target.id,
          value: oEvent.target.type === "checkbox" ? oEvent.target.checked : oEvent.target.value,
          vue: this,
        });
      }

      axios
        .put("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/settings", this.$store.state.partySettings, {
          headers: {
            "x-supersketchysecret": this.$store.state.superSketchySecret,
          },
        })
        .then(
          function() {
            if (!bSilent) {
              this.$notify({
                group: "notifications",
                type: "success",
                position: "top center",
                title: "Success",
                text: this.$getMessage("party.settingsChanged"),
              });
            }
          }.bind(this)
        )
        .catch((err) => {
          if (err.response) {
            if (!bSilent) {
              this.$notify({
                group: "notifications",
                type: "error",
                position: "top center",
                title: this.$getMessage("error.genericError"),
                text: err.response.data.message,
              });
            }
          }
        });
    },

    getHandcards: function() {
      if (this.$store.state.playType == "watch") {
        return;
      }

      axios
        .get("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/username/" + this.$store.state.username + "/cards/hand", {
          headers: {
            "x-supersketchysecret": this.$store.state.superSketchySecret,
          },
        })
        .then(
          function(response) {
            this.$store.commit("Handcards", response.data);
          }.bind(this)
        )
        .catch((err) => {
          if (err.response) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: err.response.data.message,
            });
          }
        });
    },

    showCardView: function() {
      this.showingCardView = true;
    },

    clearActiveCards: function() {
      this.activeCards = [];
      this.SettingsManager.removeCookie("activeCards", this.$isTauri);
    },
  },
};
</script>
