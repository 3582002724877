<template>
  <div class="monitor">
    <span>{{ $t("clipinstalldialog.onlinecheck_short") }}</span>
    <span v-if="!isAiOnline">❌</span>
    <span v-if="isAiOnline">✔️</span>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      isAiOnline: false,
      aiChecker: null,
    };
  },
  methods: {},
  mounted: function() {
    this.aiChecker = setInterval(
      function() {
        parent.postMessage({ type: "checkAiOnline" }, "*");
      }.bind(this),
      5000
    );

    this.$root.$on(
      "check-ai-online-result",
      function(payload) {
        if(this.isAiOnline != payload) {
          parent.postMessage({ type: "retrieveAiPerformance" }, "*");
        }
        this.isAiOnline = payload;
      }.bind(this)
    );
  },
  beforeDestroy: function() {
    clearInterval(this.aiChecker);
  },
};
</script>
<style>
.monitor {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 10px;
  padding: 10px;
  background-color: #263238;
  color: #cbd3d9;
  border-radius: 10px;
}
</style>
