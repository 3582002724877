<template>
  <div class="menuPadding ink shape04">
    <button class="pure-button" @click="$router.back()">
      {{ $t("hello.back") }}
    </button>
    <div class="eulaWrapper">
      <h2>Super Sketchy Party EULA</h2>
      <br />
      <br />End-User License Agreement <br />Last updated: 03.06.2021
      <br />
      <br />Please read this End-User License Agreement ("Agreement") carefully
      before clicking the "I Agree" button, downloading or using Super Sketchy
      Party ("Application").
      <br />
      <br />By clicking the "I Agree" button, downloading or using the
      Application, you are agreeing to be bound by the terms and conditions of
      this Agreement.
      <br />
      <br />If you do not agree to the terms of this Agreement, do not click on
      the "I Agree" button and do not download or use the Application.
      <br />
      <br />License, Intellectual Property and Privacy Policy
      <br />
      <br />Thiele / Werder GbR grants you a revocable, non-exclusive,
      non-transferable, limited license to download, install and use the
      Application solely for your personal, non-commercial purposes strictly in
      accordance with the terms of this Agreement.
      <br />
      <br />In the Application Users contribute to the Applications content by,
      including but not limited to, a) drawing pictures during games, b)
      providing phrases or words to describe pictures during games, c)
      submitting phrases or words for usage in the game. ("User Content")
      <br />
      <br />In order to further develop the Application Thiele / Werder GbR
      needs the right to make use of the User Content in accordance with and
      subject to this Agreement. Therefore, by using the Application you
      contribute to its content and you automatically grant the Thiele / Werder
      GbR an irrevocable and perpetual (except as set forth in this Agreement),
      non-exclusive, transferable, fully-paid, royalty-free (except as set forth
      in this Agreement), worldwide license, by Thiele / Werder GbR or with
      others, to use, copy, distribute, publicly perform, publicly display,
      print, publish, republish, excerpt (in whole or in part), reformat,
      translate, modify, revise and incorporate into other works, that User
      Content and any works derived from that User Content, in any form of media
      or expression, in the manner in which the Service from time to time
      permits User Content to be used, and to license or permit others to do so.
      When contributing implicitly by using the Application, no connection to
      any personal data of the User will be stored or used. This does not apply
      to explicit contributions, where the user is actively aware of
      contributing or submitting User Content. In this case the user's Steam ID
      is stored. This connection of User Content to Steam ID is not shared with
      other users.
      <br />
      <br />This license also grants Thiele / Werder GbR the right to sublicense
      that User Content to other users to permit their use of that User Content
      in the manner in which the Service from time to time permits User Content
      to be used.
      <br />
      <br />Restrictions
      <br />
      <br />You agree not to, and you will not permit others to:
      <br />
      <br />a) license, sell, rent, lease, assign, distribute, transmit, host,
      outsource, disclose or otherwise commercially exploit the Application or
      make the Application available to any third party.
      <br />
      <br />b) give, purchase, sell, bargain, barter, market, trade, offer for
      sale, sell, license, assign or otherwise divest your rights,
      responsibilities or obligations under the Agreement, either in whole or in
      part. Any attempt to do so shall be void and of no effect.
      <br />
      <br />Modifications to Application
      <br />
      <br />Thiele / Werder GbR reserves the right to modify, suspend or
      discontinue, temporarily or permanently, the Application or any service to
      which it connects, with or without notice and without liability to you.
      <br />
      <br />Term and Termination
      <br />
      <br />This Agreement shall remain in effect until terminated by you or
      Thiele / Werder GbR.
      <br />
      <br />Thiele / Werder GbR may, in its sole discretion, at any time and for
      any or no reason, suspend or terminate this Agreement with or without
      prior notice.
      <br />
      <br />This Agreement will terminate immediately, without prior notice from
      Thiele / Werder GbR, in the event that you fail to comply with any
      provision of this Agreement. You may also terminate this Agreement by
      deleting the Application and all copies thereof from your mobile device or
      from your desktop.
      <br />
      <br />Upon termination of this Agreement, you shall cease all use of the
      Application and delete all copies of the Application from your mobile
      device or from your desktop.
      <br />
      <br />Warranties and Limitation of Liability
      <br />
      <br />a) Nothing in this agreement will prejudice the statutory rights you
      may have as a consumer of the software. Some jurisdictions do not allow
      the exclusion of certain warranties or the limitation of liability as set
      forth in this section. In such jurisdictions, the exclusions and
      limitations below shall apply only to the extent permitted by the laws of
      such jurisdictions. To the fullest extent allowed by applicable law, the
      software and any accompanying documentation and material are being
      provided to you “as is,” “as available,” and “with all faults,” without
      warranty of any kind whatsoever. Without limiting the intended generality
      of the preceding sentence Thiele / Werder GbR does not warrant that the
      software or any portion thereof (i) will be uninterrupted, secure or error
      free, or that defects will be corrected; (ii) is compatible with any
      software, including without limitation internet browser software; (iii) is
      free of infection by viruses, worms, trojan horses or anything else
      manifesting contaminating or destructive properties; (iv) is free of
      defamatory, derogatory or adult-oriented material, or material that some
      individuals may deem offensive or objectionable; (v) will operate
      properly; or (vi) will meet your requirements. To the fullest extent
      allowed by law, Thiele / Werder GbR disclaims all representations, terms,
      warranties, whether express, statutory, or implied, including but not
      limited to any and all implied warranties of merchantability, fitness for
      a particular purpose or use, system integration, accuracy or completeness,
      title, non-interference with your enjoyment, authority, non-infringement,
      results, reasonable care, and workmanlike effort, whether alleged to arise
      under law, by reason of custom or usage in the trade, or by course of
      dealing.
      <br />
      <br />b) Thiele / Werder GbR is not responsible for the conduct, whether
      online or offline, of any user of the software. Under no circumstances
      will Thiele / Werder GbR be responsible for any loss or damage resulting
      from your use of the software, any User Content posted on the software, or
      transmitted to another user, or any transactions between or among you and
      other users, whether online or offline.
      <br />
      <br />c) The software is controlled and provided by Thiele / Werder GbR
      from its facilities in the European Union. To the extent permissible by
      applicable local law and except as expressly set forth in this agreement,
      Thiele / Werder GbR makes no representation that the software is
      appropriate or available for use in other locations.
      <br />
      <br />d) Because some jurisdictions prohibit the exclusion or limitation
      of liability for consequential or incidental damages, the above
      limitations may not apply to you to the extent that such exclusions or
      limitations are not permitted under local law.
      <br />
      <br />e) To the fullest extent allowed by applicable law, Thiele / Werder
      GbR shall not be liable to you or any other person, whether under contract
      or otherwise, for (a) any indirect, special, incidental, or consequential
      damages of any kind, or for lost profits, loss of goodwill, work stoppage,
      accuracy of results, or computer failure or malfunction, and/or (b) any
      amount, in the aggregate, in excess of the amounts paid and/or payable by
      you to Thiele / Werder GbR (or to a third-party platform that provided you
      with any games) in connection with the software in the twelve (12) month
      period preceding this applicable claim, or (c) any matter beyond Thiele /
      Werder GbR’s reasonable control. The foregoing limitation shall be
      applicable even in the event of Thiele / Werder GbR’s fault, tort
      (including negligence), strict liability, indemnity, product liability,
      breach of contract, breach of warranty, or otherwise. Thiele / Werder GbR
      cannot and will not be responsible for any loss of your User Content or
      any interruptions of the software, including but not limited to ISP
      disruptions, software or hardware failures, or any other event which may
      result in a loss of data or a disruption of the software. Notwithstanding
      anything to the contrary in these terms, nothing in these terms shall
      exclude or limit anything which may not be excluded or limited under
      applicable law, including without limitation, death or personal injury
      arising through negligence or for fraud. to the maximum extent permitted
      under applicable law, these limitations and exclusions regarding damages
      apply even if any remedy fails to provide adequate compensation.
      <br />
      <br />f) You own all intellectual property in your own original Content
      you contribute. You must not upload or contribute any Content not
      originally created by you, or any Content which is not properly licensed
      to you by someone else for uploading or contributing.
      <br />
      <br />Severability
      <br />
      <br />If any provision of this Agreement is held to be unenforceable or
      invalid, such provision will be changed and interpreted to accomplish the
      objectives of such provision to the greatest extent possible under
      applicable law and the remaining provisions will continue in full force
      and effect.
      <br />
      <br />Amendments to this Agreement
      <br />
      <br />Thiele / Werder GbR reserves the right, at its sole discretion, to
      modify or replace this Agreement at any time. If a revision is material we
      will provide at least 30 days' notice prior to any new terms taking
      effect. What constitutes a material change will be determined at our sole
      discretion.
      <br />
      <br />Contact Information
      <br />
      <br />If you have any questions about this Agreement, please contact us
      via the E-Mail address contact@elusivedrafts.eu
      <br />
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  name: "Eula",
  components: {},
  data: function() {
    return {};
  },
};
</script>

<style scoped></style>
