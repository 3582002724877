<template>
  <canvas id="forceRefreshCanvas" width="1" height="1"></canvas>
</template>
<script>
export default {
  props: [],
  name: "RefreshCanvas",
  components: {},
  data: function() {
    return {};
  },
  mounted() {
    this.forceRefresh();
  },
  methods: {
    forceRefresh: function() {
      var canvas = document.getElementById("forceRefreshCanvas");
      canvas.width = window.outerWidth;
      canvas.height = window.outerHeight;
      canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
      window.requestAnimationFrame(this.forceRefresh);
    },
  },
};
</script>
<style scoped>
#forceRefreshCanvas {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -99999;
}
</style>
