<template>
  <div class="tip-container">
    <p v-html="visibleTip"></p>
  </div>
</template>
<script>
export default {
  props: [],
  name: "Tip",
  components: {},
  data: function() {
    return {
      tips: [
        this.$t("tips.emojiIntro") + this.$t("reactionTitles.low-effort"),
        this.$t("tips.emojiIntro") + this.$t("reactionTitles.licensed-fool"),
        this.$t("tips.emojiIntro") + this.$t("reactionTitles.nice"),
        this.$t("tips.liking"),
        this.$t("tips.redraw"),
        this.$t("tips.rightDrawings"),
        this.$t("tips.rightGuesses"),
        this.$t("tips.reconnect"),
      ],
      visibleTip: null,
    };
  },
  mounted() {
    this.visibleTip = this.tips[Math.floor(Math.random() * this.tips.length)];
  },
};
</script>

<style scoped>
.tip-container {
  position: relative;
  color: #cfd8dc;
  padding: 0.5em;
  z-index: 0;
  min-width: fit-content;
  min-height: fit-content;
}

.tip-container p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  line-height: 1.25rem;
  text-align: center;
}

.tip-container::after {
  content: "";
  position: absolute;
  top: -5%;
  left: -5%;
  height: 110%;
  width: 110%;
  z-index: -1;
  background: url("../../public/assets/blobs/stroke_tip.svg") top center;
  background-size: 100% 100%;
  box-sizing: border-box;
}
</style>
