<template>
  <div class="turnResultCanvasWrapper">
    <div class="pure-u-1-2 shapeParent">
      <div class="smallerView">
        <div class="ink shape06 middleAlign smallMargin">
          <DrawView v-bind:drawingBlob="turnDrawingBlob" styleClass=""></DrawView>
          <p class="middleAlign guessFont">{{ $t("turnresult.madeby") }}{{ contentCreatorUser }}</p>
        </div>
      </div>
    </div>
    <div id="guessWrapper" class="pure-u-1-2">
      <div id="turnResultCanvas"></div>
      <div id="matterJsElements" class="matterJsElements slideIn">
        <template v-for="guess in guesses">
          <div
            class="ink shape01 guess fadeIn guessFont"
            matter
            :key="guess.key"
            :id="guess.key"
            data-shapetype="guess"
            :data-attractor="guess.key"
            :data-displaydelay="guess.displaydelay"
            :data-correct="guess.correct"
            :data-ai="guess.ai"
            data-phase="0"
          >
            {{ guess.text }}
          </div>
        </template>
      </div>
      <div id="offscreenElements" class="offscreenElements">
        <template v-for="guess in guesses">
          <template v-for="user in guess.internalUsers">
            <div class="DrawViewAvatarWrapper2" matter :key="user.name" data-shapetype="avatar" :data-attractor="guess.key" data-phase="1">
              <DrawView v-bind:drawingBlob="user.userAvatarBlob" :styleClass="'DrawViewAvatarSize noMargin'"></DrawView>
            </div>
          </template>
        </template>
      </div>
      <div id="phase3Elements" class="matterJsElements phase3Elements" v-if="phase === 3">
        <div id="correctGuess" class="ink shape03 correctGuess" data-phase="3">
          {{ guesses.find((g) => g.correct === true).text }}
        </div>
        <div class="correctGuessAvatars">
          <template v-for="user in guesses.find((g) => g.correct === true).internalUsers">
            <div class="DrawViewAvatarWrapper2" :key="user.name" data-shapetype="avatar" data-phase="3">
              <DrawView v-bind:drawingBlob="user.userAvatarBlob" :styleClass="'DrawViewAvatarSize noMargin'"></DrawView>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Matter from "matter-js";
import MatterAttractors from "matter-attractors-f";
import { MatterDomPlugin } from "../libs/matter-dom-plugin/matter-dom-plugin.js";
import DrawView from "./DrawView.vue";

var World = Matter.World;
var Composite = Matter.Composite;
var Engine = Matter.Engine;
var Runner = Matter.Runner;
var Events = Matter.Events;

var guessCategory = 0x0001;
var avatarCategory = 0x0002;

export default {
  props: ["guesses", "turnDrawingBlob", "contentCreatorUser"],
  name: "TurnResultCanvas",
  components: {
    DrawView,
  },
  data: function() {
    return {
      phase: 0,
      engine: null,
      runner: null,
      render: null,
    };
  },
  created() {
    this.$disableZoom();
  },
  destroyed() {
    this.$enableZoom();
  },
  mounted() {
    this.backupClientOffset("#matterJsElements");
    this.backupClientOffset("#offscreenElements");
    this.$nextTick(() => {
      this.init();
      this.SoundManager.playSfx("swoosh");
    });
    setTimeout(
      function() {
        this.addShakeAnimation();
        this.SoundManager.playSfx("drumroll");
        this.gravityOff();
        setTimeout(
          function() {
            this.gravityDown();
            var matterJsElements = document.getElementById("matterJsElements");
            for (var i = 0; i < matterJsElements.children.length; i++) {
              let child = matterJsElements.children[i];
              setTimeout(
                function(child) {
                  if (this.guesses.find((e) => Number(e.key) === Number(child.id)).users.length === 0) {
                    Matter.Body.setStatic(child.matterBody, false);
                    setTimeout(
                      function(child) {
                        this.explodeDomElement(child);
                      }.bind(this),
                      2000,
                      child
                    );
                  }
                  child.matterBody.isShaking = false;
                }.bind(this),
                i * 100,
                child
              );
            }
            let nrOfNotChosenGuesses = this.guesses.filter((e) => e.users.length === 0).length;
            setTimeout(() => {
              this.phase1();
            }, 2000 + nrOfNotChosenGuesses * 100);
          }.bind(this),
          2000
        );
      }.bind(this),
      3000
    );
  },
  methods: {
    init() {
      Matter.Common.logLevel = 0;
      Matter.use(MatterAttractors);
      Matter.use(MatterDomPlugin);

      var RenderDom = Matter.RenderDom;

      this.engine = Engine.create();
      this.gravityOff();

      this.render = RenderDom.create({
        element: document.getElementById("turnResultCanvas"),
        engine: this.engine,
        options: {
          // background: "transparent",
          wireframeBackground: "transparent",
          wireframes: true,
        },
      });

      this.addPhase0Bodies();
      this.addPhase1Bodies();

      RenderDom.run(this.render);
      this.runner = Runner.create();
      Runner.run(this.runner, this.engine);
      Events.on(this.runner, "afterTick", this.afterTick);
    },
    afterTick() {
      if (this.phase === 1) {
        var allBodiesStatic = this.engine.world.bodies.every((body) => {
          return body.isStatic;
        });
        if (allBodiesStatic) {
          this.stopRender();
          if (this.phase < 2) {
            this.phase++;
            this.phase2();
          }
        }
      }
    },
    gravityLeft() {
      this.engine.gravity.y = 0;
      this.engine.gravity.x = -0.05;
    },
    gravityDown() {
      this.engine.gravity.y = 0.05;
      this.engine.gravity.x = 0;
    },
    gravityOff() {
      this.engine.gravity.y = 0;
      this.engine.gravity.x = 0;
    },
    gravityMin() {
      this.engine.gravity.y = 0.01;
      this.engine.gravity.x = 0;
    },
    backupClientOffset(selector) {
      var matterJsElements = document.querySelector(selector);
      for (var i = 0; i < matterJsElements.children.length; i++) {
        var child = matterJsElements.children[i];
        child.matterJsWidth = child.offsetWidth;
        child.matterJsHeight = child.offsetHeight;
      }
    },
    addShakeAnimation() {
      Composite.allBodies(this.engine.world).forEach((body) => {
        if (body.phase === "0") {
          body.isShaking = true;
        }
      });

      Events.on(
        this.engine,
        "beforeUpdate",
        function(event) {
          var engine = event.source;
          this.shakeBodies(engine, event.timestamp);
        }.bind(this)
      );
    },
    shakeBodies: function(engine, timestamp) {
      var Body = Matter.Body;

      var bodies = Composite.allBodies(engine.world);

      for (var i = 0; i < bodies.length; i++) {
        var body = bodies[i];
        if (body.isShaking) {
          body.position.x += (Math.random() - 0.5) * 0.1;
          body.position.y += (Math.random() - 0.5) * 0.1;
          Body.rotate(body, Math.sin(timestamp) * 0.075);
        }
      }
    },
    removeAllBodies() {
      var bodies = Composite.allBodies(this.engine.world);
      for (var i = 0; i < bodies.length; i++) {
        var body = bodies[i];
        World.remove(this.engine.world, body);
      }
    },
    removeBody(domElement) {
      if (domElement.dataset.shapetype === "guess") {
        var matterJsElements = document.querySelector("#matterJsElements");
        matterJsElements.removeChild(domElement);
      } else if (domElement.dataset.shapetype === "avatar") {
        var offscreenElements = document.querySelector("#offscreenElements");
        offscreenElements.removeChild(domElement);
      }

      World.remove(this.engine.world, domElement.matterBody);
    },
    addPhase0Bodies() {
      var DomBodies = Matter.DomBodies;

      var matterJsElements = document.querySelector("#matterJsElements");
      for (let i = 0; i < matterJsElements.children.length; i++) {
        let child = matterJsElements.children[i];

        var posX = child.offsetLeft + child.offsetWidth / 2;
        var posY = child.offsetTop + child.offsetHeight / 2;

        var block = DomBodies.block(posX, posY, {
          isStatic: true,
          Dom: {
            render: this.render,
            element: child,
          },
          render: {
            fillStyle: "transparent",
            strokeStyle: "transparent",
          },
          collisionFilter: {
            group: child.dataset.attractor,
            category: guessCategory, //is of category
            mask: guessCategory | avatarCategory, //shall collide with category
          },
          plugin: {
            attractors: [],
          },
        });
        child.matterBody = block;
        child.matterBody.phase = child.dataset.phase;
        child.matterBody.shapetype = child.dataset.shapetype;
        child.matterBody.attractorKey = child.dataset.attractor;
        World.add(this.engine.world, block);
      }
      for (let i = 0; i < matterJsElements.children.length; i++) {
        let child = matterJsElements.children[i];
        child.classList.remove("guess");
      }
      matterJsElements.classList.remove("matterJsElements");
    },

    addPhase1Bodies() {
      var DomBodies = Matter.DomBodies;

      var offscreenElements = document.querySelector("#offscreenElements");

      for (let i = 0; i < offscreenElements.children.length; i++) {
        let child = offscreenElements.children[i];

        var posX = window.innerWidth / 2 + child.offsetWidth * (i + 1);
        var posY = (window.innerHeight / (offscreenElements.children.length + 2)) * (i + 1);

        var block = DomBodies.block(posX, posY, {
          isStatic: true,
          Dom: {
            render: this.render,
            element: child,
          },
          render: {
            fillStyle: "transparent",
            strokeStyle: "transparent",
          },
          collisionFilter: {
            group: child.dataset.attractor,
            category: avatarCategory, //is of category
            mask: guessCategory, //shall collide with category
          },
          plugin: {
            attractors: [
              function(bodyA, bodyB) {
                if (bodyA.shapetype != bodyB.shapetype && bodyA.attractorKey === bodyB.attractorKey) {
                  var attractorForceFactor = 1;
                  if (bodyA.attractorForceFactor) attractorForceFactor = bodyA.attractorForceFactor;
                  if (bodyB.attractorForceFactor) attractorForceFactor = bodyB.attractorForceFactor;
                  var strength = 0.2e-6 * attractorForceFactor;
                  var force = {
                    x: (bodyA.position.x - bodyB.position.x) * strength,
                    y: (bodyA.position.y - bodyB.position.y) * strength,
                  };

                  if (bodyB.shapetype != "guess") {
                    Matter.Body.applyForce(bodyB, bodyB.position, force);
                  } else if (bodyA.shapetype != "guess") {
                    Matter.Body.applyForce(bodyA, bodyA.position, Matter.Vector.neg(force));
                  }
                }
              },
            ],
          },
        });
        child.matterBody = block;
        child.matterBody.phase = child.dataset.phase;
        child.matterBody.shapetype = child.dataset.shapetype;
        child.matterBody.attractorKey = child.dataset.attractor;
        child.matterBody.attractorForceFactor = 1;
        World.add(this.engine.world, block);
      }
      let collisionDectector = Matter.Detector.create();
      Matter.Detector.setBodies(collisionDectector, this.engine.world.bodies);
      Matter.Events.on(this.engine, "collisionStart", () => {
        for (let pairCol of Matter.Detector.collisions(collisionDectector)) {
          if (
            pairCol.bodyA.attractorKey == pairCol.bodyB.attractorKey &&
            (pairCol.bodyA.shapetype === "guess" || pairCol.bodyB.shapetype === "guess")
          ) {
            Matter.Body.setVelocity(pairCol.bodyA, { x: 0, y: 0 });
            Matter.Body.setVelocity(pairCol.bodyB, { x: 0, y: 0 });
            setTimeout(() => {
              Matter.Body.setStatic(pairCol.bodyA, true);
              Matter.Body.setStatic(pairCol.bodyB, true);
            }, 1000);
            pairCol.bodyA.collisionFilter.mask = guessCategory | avatarCategory;
            pairCol.bodyB.collisionFilter.mask = guessCategory | avatarCategory;
          } else if (pairCol.bodyA.attractorForceFactor) {
            pairCol.bodyA.attractorForceFactor += 1;
            Matter.Body.setVelocity(pairCol.bodyA, { x: Math.random() / 2, y: Math.random() / 2 });
          } else if (pairCol.bodyB.attractorForceFactor) {
            pairCol.bodyB.attractorForceFactor += 1;
            Matter.Body.setVelocity(pairCol.bodyB, { x: Math.random() / 2, y: Math.random() / 2 });
          }
        }
      });

      offscreenElements.classList.remove("offscreenElements");
    },

    unlockPhase1Bodies() {
      var bodies = Composite.allBodies(this.engine.world);
      for (var i = 0; i < bodies.length; i++) {
        var body = bodies[i];
        if (body.isStatic && body.phase == 1) {
          Matter.Body.setStatic(body, false);
        }
      }
    },
    explodeDomElement(child) {
      var rect = child.getBoundingClientRect();
      var x = (rect.left + rect.width / 2) / window.innerWidth;
      var y = (rect.top + rect.height / 2) / window.innerHeight;
      this.$root.$emit("Explosion", x, y);
      this.SoundManager.playSfx("plop");
      this.removeBody(child);
    },

    explodeDomElementAi(child) {
      var rect = child.getBoundingClientRect();
      var x = (rect.left + rect.width / 2) / window.innerWidth;
      var y = (rect.top + rect.height / 2) / window.innerHeight;
      this.$root.$emit("AiExplosion", x, y);
      this.SoundManager.playSfx("plop");
      this.removeBody(child);
    },

    correctGuessPride(child) {
      var rect = child.getBoundingClientRect();
      var x1 = rect.left / window.innerWidth;
      var x2 = rect.right / window.innerWidth;
      var y = rect.top / window.innerHeight;
      this.$root.$emit("Pride", x1, y, x2, y);
    },
    phase1() {
      if (this.phase === 0) {
        this.phase++;
        this.gravityOff();
        this.unlockPhase1Bodies();
        setTimeout(
          function() {
            if (this.phase < 2) {
              this.phase++;
              this.phase2();
            }
          }.bind(this),
          5000
        );
      }
    },
    phase2() {
      var matterJsElements = document.querySelector("#matterJsElements");
      for (let i = 0; i < matterJsElements.children.length; i++) {
        let child = matterJsElements.children[i];
        if (child.dataset.displaydelay === 0) {
          continue;
        }

        setTimeout(
          (child) => {
            if (Boolean(child.dataset.correct) === false) {              
              if (Boolean(child.dataset.ai) === true) {
                this.explodeDomElementAi(child);
              } else {
                this.explodeDomElement(child);
              }
              document.querySelectorAll("[data-attractor='" + child.dataset.attractor + "']").forEach((avatar) => {
                this.explodeDomElement(avatar);
              });
            } else {
              document.querySelectorAll("[data-attractor='" + child.dataset.attractor + "']").forEach((avatar) => {
                avatar.classList.add("fadeOut");
              });
            }
          },
          child.dataset.displaydelay,
          child
        );
      }

      var maxDisplayDelay =
        Math.max.apply(
          Math,
          this.guesses.map(function(guess) {
            return guess.displaydelay;
          })
        ) + 1000;

      setTimeout(
        function() {
          this.phase3();
        }.bind(this),
        maxDisplayDelay
      );
    },
    stopRender() {
      Runner.stop(this.runner);
      this.engine.enabled = false;
      Matter.RenderDom.stop(this.render);
    },
    phase3() {
      if (this.phase < 3) {
        this.stopRender();
        this.phase = 3;
        setTimeout(
          function() {
            let correctGuessElem = document.getElementById("correctGuess");
            setTimeout(
              function(correctGuessElem) {
                this.correctGuessPride(correctGuessElem);
                this.SoundManager.playSfx("guess-correct");
                setTimeout(
                  function() {
                    this.$emit("onFinished");
                  }.bind(this),
                  1500
                );
              }.bind(this),
              500,
              correctGuessElem
            );
          }.bind(this),
          0
        ); //setTimeout 0 to ensure that the correctGuess element is added to the DOM before moving it
      }
    },
  },
};
</script>

<style scoped>
#turnResultCanvas {
  display: none;
}

.fillerElements {
  position: absolute;
  left: 0%;
  top: -70%;
}

.matterJsElements {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  width: 90%;
  margin-right: 10%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.guess {
  flex: 0 1 auto;
  margin: 1em;
}

.turnResultCanvasWrapper {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.middleAlign {
  text-align: center;
}

.shapeParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.smallMargin {
  margin: 2.5em;
}

.fadeIn {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slideIn {
  animation: slidein 3s forwards;
}

@keyframes slidein {
  from {
    transform: translate(300%);
  }
  to {
    transform: translate(0%);
  }
}

.offscreenElements {
  position: absolute;
  left: 80%;
  top: -70%;
}

#offscreenElements {
  transform: translate(0%);
}

.fadeOut {
  animation: fadeOut 0.75s forwards !important;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

.phase3Elements {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.correctGuessAvatars {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 1em;
}

.correctGuess {
  width: fit-content;
  transition: all 0.5s ease;
  animation: scaleUp 0.5s forwards;
  font-size: xxx-large;
}

.DrawViewAvatarSize {
  max-height: inherit !important;
  max-width: 4.5em;
}

.smallerView {
  max-width: 65%;
  margin: auto;
}

.guessFont {
  font-size: 2em;
}
</style>
