import { render, staticRenderFns } from "./RefreshCanvas.vue?vue&type=template&id=199e5d03&scoped=true"
import script from "./RefreshCanvas.vue?vue&type=script&lang=js"
export * from "./RefreshCanvas.vue?vue&type=script&lang=js"
import style0 from "./RefreshCanvas.vue?vue&type=style&index=0&id=199e5d03&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "199e5d03",
  null
  
)

export default component.exports