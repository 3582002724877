<template>
  <div class="maxWidth">
    <div v-if="mode === '1'">
      <h3>{{ $t("contentvote.translateTitle") }}</h3>
      <p>{{ $t("contentvote.introTranslate") }}</p>
      <div class="pure-form pure-form-aligned centerForm">
        <div class="pure-control-group">
          <label for="inplangu">{{ $t("serverbrowser.langu") }}</label>
          <select v-model="voteTranslate.langu" id="inpLangu">
            <option v-for="langu in supportedLanguagesForAutotranslationsList" v-bind:value="langu.code" v-bind:key="langu.code">
              {{ langu.name }}
            </option>
          </select>
        </div>
        <div class="pure-controls">
          <button class="pure-button" @click="getPhrase('a', voteTranslate.langu)">
            {{ $t("contentvote.getword") }}
          </button>
        </div>
        <br />
        <div class="pure-control-group">
          <label>{{ $t("contentvote.phraseinlanguage") }}{{ allLanguagesList.find((l) => l.code === voteTranslate.langu).name }}</label>
          <span class="phrase"
            ><strong>{{ voteTranslate.votable.text }}</strong></span
          >
        </div>
        <div class="pure-controls">
          <button class="pure-button" @click="votePhrase('upvote', 'a')">
            <strong>{{ $t("contentvote.upvote") }}</strong>
          </button>
          <button class="pure-button" @click="votePhrase('downvote', 'a')">
            <strong>{{ $t("contentvote.downvote") }}</strong>
          </button>
          <button class="pure-button" @click="votePhrase('skip', 'a')">
            <strong>{{ $t("contentvote.skip") }}</strong>
          </button>
        </div>
      </div>
    </div>

    <div v-if="mode === '2'">
      <h3>{{ $t("contentvote.submissionTitle") }}</h3>
      <p>{{ $t("contentvote.introSubmission") }}</p>
      <div class="pure-form pure-form-aligned centerForm">
        <div class="pure-control-group">
          <label for="inplangu">{{ $t("serverbrowser.langu") }}</label>
          <select v-model="voteSubmission.langu" id="inpLangu">
            <option v-for="langu in allLanguagesList" v-bind:value="langu.code" v-bind:key="langu.code">
              {{ langu.name }}
            </option>
          </select>
        </div>
        <div class="pure-controls">
          <button class="pure-button" @click="getPhrase('s', voteSubmission.langu)">
            {{ $t("contentvote.getword") }}
          </button>
        </div>
        <br />
        <div class="pure-control-group">
          <label>{{ $t("contentvote.phraseinlanguage") }}{{ allLanguagesList.find((l) => l.code === voteTranslate.langu).name }}</label>
          <span class="phrase"
            ><strong>{{ voteSubmission.votable.text }}</strong></span
          >
        </div>
        <div class="pure-controls">
          <button class="pure-button" @click="votePhrase('upvote', 's')">
            <strong>{{ $t("contentvote.upvote") }}</strong>
          </button>
          <button class="pure-button" @click="votePhrase('downvote', 's')">
            <strong>{{ $t("contentvote.downvote") }}</strong>
          </button>
          <button class="pure-button" @click="votePhrase('skip', 's')">
            <strong>{{ $t("contentvote.skip") }}</strong>
          </button>
        </div>
        <hr />
        <div class="pure-control-group">
          <button class="pure-button" @click="$router.back()">
            {{ $t("hello.back") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import allLanguagesList from "../data/languages.json";
import axios from "axios";

export default {
  props: ["mode"],
  name: "ContentVote",
  components: {},
  data: function() {
    return {
      voteTranslate: {
        langu: "en",
        votable: {
          crossReferenceGuid: "",
          langu: "",
          text: "",
        },
      },
      voteSubmission: {
        langu: "en",
        votable: {
          crossReferenceGuid: "",
          langu: "",
          text: "",
        },
      },
      allLanguagesList: allLanguagesList,
      supportedLanguagesForAutotranslationsList: [],
    };
  },
  mounted() {
    axios
    .get("/api/content/autotranslations/langu/supported", {
      headers: {
        "x-supersketchyownersecret": this.$store.state.superSketchyOwnerSecret,
      },
    })
    .catch(
      function() {
        this.$notify({
          group: "notifications",
          type: "error",
          position: "top center",
          title: this.$getMessage("error.genericError"),
          text: this.$getMessage("error.maintenanceContent"),
        });
      }.bind(this)
    )
    .then((resp) => {
      const supportedLanguageCodes = resp?.data;
      if (supportedLanguageCodes) {
        this.allLanguagesList.forEach(langu => {
          const foundInSupported = supportedLanguageCodes.find(spc => langu.code == spc);
          if(foundInSupported){
            this.supportedLanguagesForAutotranslationsList.push(langu);
          }
        });
      }
    });

    this.$calcZoom();
  },
  methods: {
    getPhrase: function(sContentType, sLangu) {
      axios
        .get("/api/content/votable?langu=" + sLangu + "&contentType=" + sContentType, {
          headers: {
            "x-supersketchyownersecret": this.$store.state.superSketchyOwnerSecret,
          },
        })
        .then(
          function(resp) {
            if (resp.status == 204) {
              this.$notify({
                group: "notifications",
                type: "error",
                position: "top center",
                title: this.$getMessage("error.genericError"),
                text: this.$getMessage("contentvote.nocontent"),
              });
            }
            if (sContentType === "a") {
              this.voteTranslate.votable = resp.data;
            } else if (sContentType === "s") {
              this.voteSubmission.votable = resp.data;
            }
          }.bind(this)
        )
        .catch((err) => {
          if (err.response.status == 401) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: this.$getMessage("error.sessionExpiredError"),
            });
          } else if (err.response.status == 429) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: this.$getMessage("contentvote.toomanyvotes"),
            });
          } else if (err.response) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: err.response.data.message,
            });
          }
        });
    },
    votePhrase: function(sVote, sContentType) {
      var sLangu = sContentType === "a" ? this.voteTranslate.langu : this.voteSubmission.langu;
      var sGuid = sContentType === "a" ? this.voteTranslate.votable.crossReferenceGuid : this.voteSubmission.votable.crossReferenceGuid;
      axios
        .post(
          "/api/content/vote",
          {
            vote: sVote,
            crossReferenceGuid: sGuid,
            langu: sLangu,
            contentType: sContentType,
          },
          {
            headers: {
              "x-supersketchyownersecret": this.$store.state.superSketchyOwnerSecret,
            },
          }
        )
        .then(
          function(resp) {
            if (resp.status == 200) {
              if (sVote !== "skip") {
                this.$notify({
                  group: "notifications",
                  type: "success",
                  position: "top center",
                  text: this.$getMessage("contentvote.success"),
                });
              }
              this.getPhrase(sContentType, sLangu);
            }
          }.bind(this)
        )
        .catch((err) => {
          if (err.response.status == 401) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: this.$getMessage("error.sessionExpiredError"),
            });
          } else if (err.response.status == 409) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: this.$getMessage("contentvote.alreadyvoted"),
            });
          } else if (err.response) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: err.response.data.message,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.maxWidth {
  max-width: 40rem;
}

.phrase {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  width: 10em;
  margin: 0 1em 0 0;
}
</style>
