<template>
  <div class="pauseModal" v-if="manualOpen === true">
    <div class="appIntroContent">
      <div class="pure-g">
        <div class="appIntroHeaderTitle pure-u-1-1">
          <h3>{{ $t("appintro.title") }}</h3>
        </div>
        <div class="appIntroHeaderFixedButton" @click="skipIntro()">
          <button @click="skipIntro()" class="skipButton" id="btnSkipAppIntro">
            {{ $t("appintro.done") }}
          </button>
        </div>
      </div>
      <hr class="appIntroSeperator" />
      <agile :infinite="false" :centerMode="true" :fade="true">
        <div class="slide">
          <div class="slideContent">
            <p></p>
            <p v-html="$t('appintro.slide01_1')"></p>
            <p v-html="$t('appintro.slide01_2')"></p>
            <img
              class="appIntroImg"
              src="/app/assets/img/appIntro_01.png"
              loading="lazy"
              alt="Intro 01"
            />
            <p v-html="$t('appintro.slide01_3')"></p>
          </div>
        </div>
        <div class="slide">
          <div class="slideContent">
            <p v-html="$t('appintro.slide02_1')"></p>
            <p v-html="$t('appintro.slide02_2')"></p>
            <img
              class="appIntroImg"
              src="/app/assets/img/appIntro_02.png"
              loading="lazy"
              alt="Intro 02"
            />
            <p v-html="$t('appintro.slide02_3')"></p>
          </div>
        </div>
        <div class="slide">
          <div class="slideContent">
            <p v-html="$t('appintro.slide03_1')"></p>
            <p v-html="$t('appintro.slide03_2')"></p>
            <img
              class="appIntroImg"
              src="/app/assets/img/appIntro_03.png"
              loading="lazy"
              alt="Intro 03"
            />
            <p v-html="$t('appintro.slide03_3')"></p>
          </div>
        </div>
        <template slot="prevButton" class="appIntroButton">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
          >
            <path
              d="M11.29,12l3.54-3.54a1,1,0,0,0,0-1.41,1,1,0,0,0-1.42,0L9.17,11.29a1,1,0,0,0,0,1.42L13.41,17a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41Z"
            ></path>
          </svg>
        </template>
        <template slot="nextButton" class="appIntroButton">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
          >
            <path
              d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"
            ></path>
          </svg>
        </template>
      </agile>
    </div>
  </div>
</template>
<script>
import { VueAgile } from "vue-agile";

export default {
  props: ["manualOpen", "onClose"],
  components: { agile: VueAgile },
  data: function() {
    return {
      introShown: false,
    };
  },
  methods: {
    skipIntro: function() {
      this.onClose();
    },
  },
};
</script>
<style>
.appIntroContent {
  margin: auto;
  padding: 1em 1em 0.5em 1em;
  width: 40vh;
  margin-top: 5vh;
  border-radius: 0.5em;
  text-align: center;
  background-color: whitesmoke;
  position: relative;
}

.appIntroSeperator {
  border-color: #ddd;
  background-color: #fff;
  background-image: linear-gradient(0deg, #ddd, #ddd 50%, transparent 50%);
  color: #444;
  margin: 0.25em -20px 0em -20px;
  opacity: 50%;
}

.agile__dot {
  margin: 0 10px;
}

.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}

.agile__dot--current button {
  background-color: #888;
  border-radius: 50%;
}

.agile__dot:hover button {
  background-color: #888;
  border-radius: 50%;
}

.agile__nav-button {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.agile__dots {
  margin: auto;
}

.agile__nav-button--prev {
  left: 0;
}

.agile__nav-button--next {
  right: 0;
}

.skipButton {
  border-color: transparent;
  background: none;
  box-shadow: none;
  color: #387ef5;
  float: right;
}

.appIntroHeaderButton {
  /* width: 10%; */
  display: inline-block;
  vertical-align: top;
}

.appIntroHeaderTitle {
  /* width: 90%; */
  display: inline-block;
  vertical-align: top;
}

.appIntroHeaderTitle h3 {
  margin: initial;
  padding: initial;
}

.appIntroImg {
  width: 100%;
  margin: auto;
}

.appIntroHeaderFixedButton{
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
