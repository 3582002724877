<template>
  <div class="pure-form pure-form-aligned ink shape03" ref="root">
    <fieldset class="textAlignStart">
      <legend>{{ $t("settings.settings") }}</legend>
      <div class="pure-control-group" v-if="$isTauri">
        <label for="inpDisplaymode">{{ $t("settings.displaymode") }}</label>
        <select id="inpDisplaymode" v-model="SettingsManager.settings.displaymode" @change="onDisplayModeChange($event)" :gamepadClickable="true">
          <option value="f">{{ $t("settings.fullscreen") }}</option>
          <option value="w">{{ $t("settings.windowed") }}</option>
        </select>
      </div>
      <div class="pure-control-group" v-if="$isTauri">
        <label for="inpResolution">{{ $t("settings.resolution") }}</label>
        <select
          :value="SettingsManager.settings.resolution.resolutionString"
          :disabled="!resolutionEnabled"
          id="inpResolution"
          @change="onResolutionChange($event)"
          :gamepadClickable="true"
        >
          <option v-for="resolution in SettingsManager.resolutionOptions" v-bind:value="resolution.value" v-bind:key="resolution.value">
            {{ resolution.text }}
          </option>
        </select>
      </div>
      <div class="pure-control-group" v-if="!$isMobile">
        <label for="inpVolume">{{ $t("settings.volumeMaster") }}</label>
        <div class="volumeSlider">
          <input
            type="range"
            min="0"
            max="100"
            class="slider"
            v-model.number="SettingsManager.settings.volume.master"
            @change="SoundManager.setVolume(SettingsManager.settings.volume, SettingsManager.settings.volume.master)"
            :gamepadClickable="true"
          />
        </div>
      </div>
      <div class="pure-control-group" v-if="!$isMobile">
        <label for="inpVolume">{{ $t("settings.volumeMusic") }}</label>
        <div class="volumeSlider">
          <input
            type="range"
            min="0"
            max="100"
            class="slider"
            v-model.number="SettingsManager.settings.volume.music"
            @change="SoundManager.setVolume(SettingsManager.settings.volume, SettingsManager.settings.volume.music)"
            :gamepadClickable="true"
          />
        </div>
      </div>
      <div class="pure-control-group" v-if="!$isMobile">
        <label for="inpVolume">{{ $t("settings.volumeSfx") }}</label>
        <div class="volumeSlider">
          <input
            type="range"
            min="0"
            max="100"
            class="slider"
            v-model.number="SettingsManager.settings.volume.sfx"
            @change="SoundManager.setVolume(SettingsManager.settings.volume, SettingsManager.settings.volume.sfx)"
            :gamepadClickable="true"
          />
        </div>
      </div>
      <div class="pure-control-group" v-if="!$isMobile">
        <label for="inpVolume">{{ $t("settings.volumeNarration") }}</label>
        <div class="volumeSlider">
          <input
            type="range"
            min="0"
            max="100"
            class="slider"
            v-model.number="SettingsManager.settings.volume.narration"
            @change="SoundManager.setVolume(SettingsManager.settings.volume, SettingsManager.settings.volume.narration)"
            :gamepadClickable="true"
          />
        </div>
      </div>
      <div class="pure-control-group">
        <label for="inpLangu">{{ $t("party.language") }}</label>
        <select v-model="SettingsManager.settings.uiLangu" id="inpLangu" @change="onLanguChange" :gamepadClickable="true">
          <option v-for="langu in languList" v-bind:value="langu.code" v-bind:key="langu.code">
            {{ langu.name }}
          </option>
        </select>
      </div>
      <div class="pure-control-group">
        <label for="inpNoSleepEnabled">{{ $t("party.noSleepEnabled") }}</label>
        <input
          type="checkbox"
          v-model="SettingsManager.settings.noSleepEnabled"
          id="inpNoSleepEnabled"
          @change="onNoSleepEnabledChange"
          :gamepadClickable="true"
        />
      </div>
      <div class="pure-control-group">
        <label for="inpStreamerMode">{{ $t("party.streamerMode") }}</label>
        <input
          type="checkbox"
          v-model="SettingsManager.settings.streamerMode"
          id="inpStreamerMode"
          @change="streamerModeChange"
          :gamepadClickable="true"
        />
      </div>
      <div class="pure-control-group">
        <label for="inpLowPerformanceMode">{{ $t("party.lowPerformanceMode") }}</label>
        <input
          type="checkbox"
          v-model="SettingsManager.settings.lowPerformanceMode"
          id="inpLowPerformanceMode"
          @change="lowPerformanceModeChange"
          :gamepadClickable="true"
        />
      </div>
      <div class="pure-control-group">
        <label for="inpOpenClipDialog">{{ $t("party.ClipDialog") }}</label>
        <button class="pure-button" @click="onOpenClipDialog">{{ $t("hello.open") }}</button>
      </div>
      <div class="pure-control-group">
        <label for="inpOpenClipDialog">{{ $t("party.ResetClip") }}</label>
        <button class="pure-button" @click="onResetClip">{{ $t("hello.reset") }}</button>
      </div>
      <div class="pure-control-group">
        <label for="inpNoai">{{ $t("party.noai") }}</label>
        <input
          type="checkbox"
          v-model="SettingsManager.settings.noai"
          id="inpNoai"
          @change="noaiChange"
          :gamepadClickable="true"
        />
      </div>
      <div class="pure-controls">
        <button
          class="pure-button"
          @click="onSaveSettings"
          :disabled="SettingsManager.settings.resolution === null && SettingsManager.settings.displaymode === 'w'"
          :gamepadClickable="true"
        >
          {{ $t("hello.back") }}
        </button>
      </div>
    </fieldset>
  </div>
</template>
<script>
import axios from "axios";
import languagesJson from "../data/languages.json";
import { LogicalSize } from "@tauri-apps/api/window";

export default {
  props: ["type"],
  components: {},
  data: function() {
    return {
      sliderMarks: (val) =>
        val % 20 === 0
          ? {
              label: `${val}%`,
              labelStyle: {
                opacity: val * 0.01 * 0.7 + 0.5,
              },
              labelActiveStyle: {
                color: "#cfd8dc",
              },
            }
          : false,
      languagesJson: languagesJson,
      languList: [],
      resolutionEnabled: null,
      zoomScale: 1,
    };
  },
  mounted() {
    this.$registerZoomListener(this);

    this.resolutionEnabled = this.SettingsManager.settings.displaymode === "w";

    axios.get("/api/general/languages").then((resp) => {
      this.languList = resp?.data;
      let aTempList = [];
      if (this.languList) {
        this.languList.forEach(
          function(entry) {
            let sEntry = this.languagesJson.find((el) => el.code === entry);
            aTempList.push({ code: sEntry.code, name: sEntry.name });
          }.bind(this)
        );
      }
      this.languList = aTempList;
    });

    this.SettingsManager.getBrowserSettings(this);
    this.$setControllerViewConfig({ controls: [...this.$tabbable(this.$refs.root), ...[this.$refs.sliderVolume]] });

    this.$root.$on("reset-clip-event", () => {
      this.$notify({
        group: "notifications",
        type: "success",
        position: "top center",
        title: this.$getMessage("hello.success"),
        text: this.$getMessage("hello.clipReset"),
      });
    });
  },
  beforeDestroy() {
    this.$removeZoomListener(this);
  },
  methods: {
    onSaveSettings: function() {
      let resolution = { resolutionEnabled: this.resolutionEnabled };
      if (resolution.resolutionEnabled) {
        resolution.resolutionString = this.SettingsManager.settings.resolution.resolutionString;
        resolution.width = resolution.resolutionString.split("x")[0];
        resolution.height = resolution.resolutionString.split("x")[1];
      }

      let settings = {
        resolution: resolution,
        volume: this.SettingsManager.settings.volume,
        uiLangu: this.SettingsManager.settings.uiLangu,
        noSleepEnabled: this.SettingsManager.settings.noSleepEnabled,
        streamerMode: this.SettingsManager.settings.streamerMode,
        lowPerformanceMode: this.SettingsManager.settings.lowPerformanceMode,
        noai: this.SettingsManager.settings.noai,
      };

      if (!this.$isTauri) {
        this.cookies.set("settings", JSON.stringify(settings), {
          expires: 365,
        });
      } else {
        parent.postMessage(
          {
            type: "setCookie",
            name: "settings",
            value: JSON.stringify(settings),
            expire: 3 * 365,
          },
          "*"
        );
      }

      this.SoundManager.setVolume(this.SettingsManager.settings.volume, false);

      if (this.type === "Ingame") {
        this.$emit("onSaveSettings");
      } else {
        this.$router.back();
      }
    },

    onLanguChange: function() {
      this.SettingsManager.onLanguChange(this);
    },

    onNoSleepEnabledChange: function() {
      this.SettingsManager.onNoSleepEnabledChange();
    },

    streamerModeChange: function() {
      this.SettingsManager.onStreamerModeChange(this);
    },

    lowPerformanceModeChange: function() {
      this.SettingsManager.onLowPerformanceModeChange(this);
    },

    noaiChange: function() {
      this.SettingsManager.onNoaiChange(this);
    },

    onDisplayModeChange: function(event) {
      this.resolutionEnabled = event.target.value === "w";
      if (typeof this.SettingsManager.settings.resolution === "object") {
        this.SettingsManager.settings.resolution.resolutionString = window.innerWidth + "x" + window.innerHeight;
        this.SettingsManager.settings.resolution.width = window.innerWidth;
        this.SettingsManager.settings.resolution.height = window.innerHeight;
      }

      parent.postMessage({ type: "setFullscreen", fullscreen: !this.resolutionEnabled }, "*");

      if (this.resolutionEnabled) {
        parent.postMessage(
          {
            type: "setSize",
            size: new LogicalSize(Number(this.SettingsManager.settings.resolution.width), Number(this.SettingsManager.settings.resolution.height)),
          },
          "*"
        );
      } else {
        this.SettingsManager.settings.resolution = {};
      }
      this.$calcZoom(this.SettingsManager.settings.resolution.width, this.SettingsManager.settings.resolution.height);
    },

    onResolutionChange: function(event) {
      let data = event.target.value.split("x");
      this.SettingsManager.settings.resolution = {};
      this.SettingsManager.settings.resolution.resolutionString = event.target.value;
      this.SettingsManager.settings.resolution.width = Number(data[0]);
      this.SettingsManager.settings.resolution.height = Number(data[1]);
      parent.postMessage({ type: "setSize", size: new LogicalSize(Number(data[0]), Number(data[1])) }, "*");

      setTimeout(() => {
        this.$calcZoom();
      }, 500);
      this.$forceUpdate();
    },

    onOpenClipDialog: function() {
      this.$root.$emit("ClipInstallDialogShow");
    },

    onResetClip: function() {
      this.$root.$emit("ClipReset");
    },
  },
};
</script>
<style scoped>
.volumeSlider {
  width: 10em;
  display: inline-block;
  margin-right: 1em;
  margin-bottom: 1em;
  vertical-align: bottom;
}
</style>
