<template>
  <div class="cardViewWrapper">
    <div class="cardViewDivider">
      <div class="userContainer">
        <button
          :id="user.name"
          class="userItem"
          @click="onUserClick($event)"
          @drop="onCardDrop($event)"
          @dragover.prevent
          @dragenter="onDragEnter($event)"
          @dragleave="onDragLeave($event)"
          v-for="user in users"
          :key="user.name"
        >
          {{ user.name }}
        </button>
      </div>
    </div>
    <div class="cardViewDivider">
      <div class="hand">
        <span
          v-for="(card, index) in $store.state.handcards"
          :key="card"
          :ref="card"
          draggable="true"
          @touchstart="onPickup($event)"
          @touchmove="onMove($event)"
          @touchend="onDrop($event)"
          @click="onCardClick($event)"
          @pointerdown="onCardClick($event)"
        >
          <img :id="card + index" class="cardImage" :src="'/app/assets/img/cards/' + card + '_' + langu + '.svg'" :alt="card" />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["users"],
  name: "CardView",
  components: {},
  data: function() {
    return {
      moving: null,
      movingStartPosition: null,
      langu: "en",
    };
  },
  computed: {
    handcardCount() {
      return this.$store.state.handcards.length;
    },
  },
  watch: {
    handcardCount: function() {
      this.$nextTick(() => {
        this.arrangeCards();
      });
    },
  },
  mounted() {
    this.$root.$on("languChange", this.languChanged.bind(this));
    this.languChanged(this.$root.$i18n.locale);

    axios.post("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/username/" + this.$store.state.username + "/cards/inspect", null, {
      headers: {
        "x-supersketchysecret": this.$store.state.superSketchySecret,
      },
    });
    this.arrangeCards();
  },
  methods: {
    languChanged(langu) {
      this.langu = langu === "de" || langu === "en" ? langu : "en";
    },

    onUserClick(event) {
      let selectedCards = document.getElementsByClassName("cardSelected");
      if (selectedCards.length > 0) {
        let card = this.extractCardNameFromURL(selectedCards[0].src);
        this.sendCardUse(event.target.id, card);
      } else {
        let userItems = document.getElementsByClassName("userItemDragHover");
        if (userItems.length > 0) {
          const formerlySelectedUser = userItems[0];
          if (formerlySelectedUser.id !== event.target.id) {
            event.target.classList.add("userItemDragHover");
          }
          formerlySelectedUser.classList.remove("userItemDragHover");
        } else {
          event.target.classList.add("userItemDragHover");
        }
      }
    },

    onCardClick(event) {
      if (event.pointerType === "mouse") {
        this.onPickup(event);
      }
    },

    onCardDrop(event) {
      let user = event.currentTarget.innerText;
      let card = this.extractCardNameFromURL(event.dataTransfer.files[0].name);

      this.sendCardUse(user, card);
    },

    onPickup(event) {
      this.moving = event.target.parentElement;
      if (event.clientX) {
        this.movingStartPosition = {
          x: event.clientX,
          y: event.clientY,
        };
      } else {
        this.movingStartPosition = {
          x: event.changedTouches[0].clientX,
          y: event.changedTouches[0].clientY,
        };
      }

      let selectedCards = document.getElementsByClassName("cardSelected");
      if (selectedCards.length > 0) {
        const formerlySelectedCard = selectedCards[0];
        if (formerlySelectedCard.id !== event.target.id) {
          event.target.classList.add("cardSelected");
        }
        formerlySelectedCard.classList.remove("cardSelected");
      } else {
        event.target.classList.add("cardSelected");
        let userItems = document.getElementsByClassName("userItemDragHover");
        if (userItems.length > 0) {
          this.sendCardUse(userItems[0].id, this.extractCardNameFromURL(event.target.src));
        }
      }
    },

    onDrop(event) {
      if (this.moving) {
        this.moving.style.left = "";
        this.moving.style.top = "";
        this.moving.style.height = "";
        this.moving.style.width = "";
        this.moving.style.position = "";
        this.moving.style.zIndex = "";
        this.moving.style.opacity = 1;

        this.moving = null;
      }
      let elements = document.elementsFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY);

      let buttons = elements.filter(function(element) {
        return element.className.indexOf("userItem") !== -1;
      });
      if (buttons.length > 0) {
        let user = buttons[0].innerText;
        let card = this.extractCardNameFromURL(event.target.src);
        this.sendCardUse(user, card);
      }
    },

    onDragEnter(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
      event.target.classList.add("userItemDragHover");
    },

    onDragLeave(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
      event.target.classList.remove("userItemDragHover");
    },

    getDistanceBetweenPoints(x1, y1, x2, y2) {
      let y = x2 - x1;
      let x = y2 - y1;
      return Math.sqrt(x * x + y * y);
    },

    onMove(event) {
      let moveDistance = 0;
      if (event.clientX) {
        moveDistance = this.getDistanceBetweenPoints(event.clientX, event.clientY, this.movingStartPosition.x, this.movingStartPosition.y);
      } else {
        moveDistance = this.getDistanceBetweenPoints(
          event.changedTouches[0].clientX,
          event.changedTouches[0].clientY,
          this.movingStartPosition.x,
          this.movingStartPosition.y
        );
      }
      if (this.moving && moveDistance > 10) {
        this.moving.style.height = this.moving.clientHeight;
        this.moving.style.width = this.moving.clientWidth;
        this.moving.style.position = "fixed";
        this.moving.style.opacity = 0.5;
        if (event.clientX) {
          this.moving.style.left = event.clientX - this.moving.clientWidth / 2;
          this.moving.style.top = event.clientY - this.moving.clientHeight / 2;
        } else {
          this.moving.style.left = event.changedTouches[0].clientX - this.moving.clientWidth / 2 + "px";
          this.moving.style.top = event.changedTouches[0].clientY - this.moving.clientHeight / 2 + "px";
        }

        let elements = document.elementsFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
        let buttons = elements.filter(function(element) {
          return element.className.indexOf("userItem") !== -1;
        });
        let activatedName = null;
        if (buttons.length > 0) {
          buttons[0].classList.add("userItemDragHover");
          activatedName = buttons[0].id;
        }
        let userItems = document.getElementsByClassName("userItem");
        for (var i = 0; i < userItems.length; i++) {
          if (userItems[i].id !== activatedName) {
            userItems[i].classList.remove("userItemDragHover");
          }
        }
      }
    },

    arrangeCards() {
      if (this.$store.state.handcards.length === 1) {
        this.$refs[this.$store.state.handcards[0]][0].style.transform = "rotate(" + 0 + "deg)";
      }

      for (let i = 0; i < this.$store.state.handcards.length; i++) {
        var max = (this.$store.state.handcards.length * 8) / 2;
        var min = -max;
        var deg = min + ((max - min) / (this.$store.state.handcards.length - 1)) * i;

        if (this.$refs[this.$store.state.handcards[i]][0]) {
          this.$refs[this.$store.state.handcards[i]][0].style.transform = "rotate(" + deg + "deg)";
        }
      }
    },

    sendCardUse(user, card) {
      var cards = this.$store.state.handcards;
      var removedCards = cards.splice(cards.indexOf(this.removeSVGSuffix(card)), 1);
      this.$store.commit("Handcards", cards);

      axios
        .post(
          "/api/party/" + this.$store.state.partyCode.toUpperCase() + "/username/" + this.$store.state.username + "/cards/use",
          { cardName: this.removeSVGSuffix(card), targetPlayer: user },
          {
            headers: {
              "x-supersketchysecret": this.$store.state.superSketchySecret,
            },
          }
        )
        .catch(
          function(err) {
            if (err.response) {
              if (err.response.status === 409) {
                this.$notify({
                  group: "notifications",
                  type: "warn",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: this.$getMessage("error.doubleCard"),
                });
              } else {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: err.response.data.message,
                });
              }
            }
            var cards = this.$store.state.handcards;
            cards = cards.concat(removedCards);
            this.$store.commit("Handcards", cards);

            this.arrangeCards();
          }.bind(this)
        );

      var userItems = document.getElementsByClassName("userItem");
      for (var i = 0; i < userItems.length; i++) {
        userItems[i].classList.remove("userItemDragHover");
      }

      var selectedCards = document.getElementsByClassName("cardSelected");
      for (var j = 0; j < selectedCards.length; j++) {
        selectedCards[j].classList.remove("cardSelected");
      }

      this.arrangeCards();
    },

    extractCardNameFromURL(url) {
      var segment = url
        .split("/")
        .pop()
        .split(".")[0];
      return segment.substr(0, segment.lastIndexOf("_")) + ".svg";
    },

    removeSVGSuffix(str) {
      return str.replace(".svg", "");
    },
  },
};
</script>

<style scoped>
.cardViewWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.cardViewDivider {
  position: relative;
  width: 100%;
  height: 50%;
}

.cardImage {
  width: 7em;
  transition: transform 250ms;
}

.cardSelected {
  transform: translateY(-2em);
}

.hand {
  margin: auto;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: flex-end;
}

.userContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: auto;
  align-content: flex-start;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .userContainer {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .userContainer {
    width: 60%;
  }
}

.userItem {
  flex: 1 1 auto;
  margin: 10px;
  width: 100%;
  height: 3em;
  border: 0.25rem dashed #4a4a4a;
  background-color: white;
}

.userItemDragHover {
  border: #4a4a4a 0px solid;
  animation: borderAnimation 1s infinite linear;
  background-image: repeating-linear-gradient(0deg, #4a4a4a, #4a4a4a 10px, transparent 10px, transparent 20px, #4a4a4a 20px),
    repeating-linear-gradient(90deg, #4a4a4a, #4a4a4a 10px, transparent 10px, transparent 20px, #4a4a4a 20px),
    repeating-linear-gradient(180deg, #4a4a4a, #4a4a4a 10px, transparent 10px, transparent 20px, #4a4a4a 20px),
    repeating-linear-gradient(270deg, #4a4a4a, #4a4a4a 10px, transparent 10px, transparent 20px, #4a4a4a 20px);
  background-size: 5px calc(100% + 20px), calc(100% + 20px) 5px, 5px calc(100% + 20px), calc(100% + 20px) 5px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

@keyframes borderAnimation {
  from {
    background-position: 0 0, -20px 0, 100% -20px, 0 100%;
  }

  to {
    background-position: 0 -20px, 0 0, 100% 0, -20px 100%;
  }
}
</style>
