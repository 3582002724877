import Vue from "vue";
import Router from "vue-router";
import Hello from "../components/Hello";
import Party from "../components/Party";
import TestView from "../components/TestView";
import ServerBrowser from "../components/ServerBrowser";
import Settings from "../components/Settings";
import ContentSubmission from "../components/ContentSubmission";
import Eula from "../components/EULA";
import Credits from "../components/Credits";
import SubmissionGuidelines from "../components/SubmissionGuidelines";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/tests/1",
      name: "TestView",
      component: TestView,
    },
    {
      path: "/serverbrowser",
      name: "serverbrowser",
      component: ServerBrowser,
    },
    {
      path: "/content",
      name: "contentsubmission",
      component: ContentSubmission,
    },
    {
      path: "/submissionguidelines",
      name: "submissionguidelines",
      component: SubmissionGuidelines,
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
    },
    {
      path: "/eula",
      name: "eula",
      component: Eula,
    },
    {
      path: "/credits",
      name: "credits",
      component: Credits,
    },
    {
      path: "/:partyCode?",
      name: "Hello",
      component: Hello,
      alias: "/watch/:partyCode?",
    },
    {
      path: "/party/:partyCode/:name",
      name: "party",
      component: Party,
      props: (route) => ({ query: route.query.reporterror }),
    },
    {
      path: "/party/:partyCode/",
      name: "partywatch",
      component: Party,
    },
  ],
});
