<template>
  <div class="pauseModal">
    <div class="appIntroContent">
      <div class="pure-g">
        <div class="appIntroHeaderTitle pure-u-1-1">
          <h3>{{ $t("clipinstalldialog.title") }}</h3>
        </div>
        <div class="appIntroHeaderWrapper">
          <div class="" @click="close()">
            <button @click="close()" class="skipButton" id="btnSkipAppIntro">
              {{ $t("appintro.done") }}
            </button>
          </div>
          <div class="" @click="closedeactivate()">
            <button @click="closedeactivate()" class="skipButton" id="btnSkipAppIntro">
              {{ $t("appintro.closedeactivate") }}
            </button>
          </div>
        </div>
      </div>
      <hr class="appIntroSeperator" />
      <div>
        <p>{{ $t("clipinstalldialog.introduction") }}</p>
        <button class="pure-button" @click="onStartClick" :disabled="startDisabled">{{ $t("clipinstalldialog.start") }}</button>
        <p>{{ $t("clipinstalldialog.logtitle") }}</p>
        <textarea :value="log" class="log-textarea" ref="txtAreaLog" disabled> </textarea>
        <div>
          <span>{{ $t("clipinstalldialog.onlinecheck") }}</span>
          <span v-if="!isAiOnline">❌</span>
          <span v-if="isAiOnline">✔️</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      log: "",
      isAiOnline: false,
      aiChecker: null,
      startDisabled: false,
    };
  },
  methods: {
    close: function () {
      this.$root.$emit("closeClipInstallDialog");
      clearInterval(this.aiChecker);
      this.aiChecker = null;
    },

    closedeactivate: async function () {
      let settings = JSON.parse(await this.SettingsManager.getCookie("settings", this.$isTauri));
      settings.noai = true;

      this.SettingsManager.settings = settings;
      this.SettingsManager.saveCookie("settings", JSON.stringify(settings), 365, this.$isTauri);
      this.close();
    },

    onStartClick: function () {
      this.invokeClipInstall();
    },

    invokeClipInstall: function () {
      parent.postMessage({ type: "invokeClipInstall" }, "*");
      this.startDisabled = true;
    },
  },
  mounted: function () {
    this.$root.$on("install-clip-log-event", (payload) => {
      this.log += payload;
      if (this.$refs.txtAreaLog) {
        this.$refs.txtAreaLog.scrollTop = this.$refs.txtAreaLog.scrollHeight;
      }
    });

    this.aiChecker = setInterval(
      function () {
        parent.postMessage({ type: "checkAiOnline" }, "*");
      }.bind(this),
      5000
    );

    this.$root.$on(
      "check-ai-online-result",
      function (payload) {
        if (!this.aiChecker) {
          return;
        }

        this.isAiOnline = payload;

        if (this.isAiOnline) {
          this.$root.$emit("showClipMonitor");

          this.$notify({
            group: "notifications",
            type: "success",
            position: "top center",
            title: this.$getMessage("hello.success"),
            text: this.$getMessage("hello.installClipSuccess"),
          });

          this.close();
        }
      }.bind(this)
    );
  },
  beforeDestroy: function () {
    clearInterval(this.aiChecker);
  },
};
</script>
<style>
.appIntroContent {
  margin: auto;
  padding: 1em 1em 0.5em 1em;
  width: 80vw;
  margin-top: 5vh;
  border-radius: 0.5em;
  text-align: center;
  background-color: whitesmoke;
  position: relative;
}

.appIntroSeperator {
  border-color: #ddd;
  background-color: #fff;
  background-image: linear-gradient(0deg, #ddd, #ddd 50%, transparent 50%);
  color: #444;
  margin: 0.25em -20px 0em -20px;
  opacity: 50%;
}

.log-textarea {
  width: 100%;
  min-height: 40vh;
}

.appIntroHeaderWrapper {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
}
</style>
