<template>
  <div class="DrawViewParent NoHighlights" v-bind:class="[upsideDown == true ? 'DrawViewParent NoHighlights upsideDown ' + styleClass : 'DrawViewParent NoHighlights ' + styleClass]" :id="id">
    <object
      v-if="onlyShownBriefly"
      class="paintBucket"
      ref="paintBucket"
      data="/app/assets/img/paint-bucket.svg"
      alt="paint bucket"
      type="image/svg+xml"
      @load="onPaintBucketLoad"
    />
    <canvas v-if="onlyShownBriefly" class="overlayCanvas" :id="'overlayCanvas' + id"></canvas>
  </div>
</template>
<script>
export default {
  props: ["drawingBlob", "styleClass", "playersoundRef", "onlyShownBriefly", "upsideDown"],
  data: function() {
    return {
      drawingArray: null,
      id: null,
      canvasid: null,
      currentOverlayY: 0,
      color: "black",
    };
  },
  created() {
    this.id = "DrawView" + this._uid;
  },
  mounted() {
    var newImg = document.createElement("img");

    var src = "data:image/png;base64,";

    var spaceCounter = this.getSpaceCounter();
    for (let i = 0; i < spaceCounter; i++) {
      src += " ";
    }
    src += this.drawingBlob;

    newImg.src = src;
    newImg.id = this.id + "img";

    document.getElementById(this.id).appendChild(newImg);

    if (!window.APNGSupport) {
      //eslint-disable-next-line
      APNG.animateImage(document.getElementById(this.id + "img"));
    }

    if (this.playersoundRef) {
      this.SoundManager.playPlayersound(this.playersoundRef);
    }

    if (this.onlyShownBriefly) {
      this.startOverlay();
    }

    this.color = ["rgba(0, 150, 200, 1)", "rgba(126, 251, 122, 1)", "rgba(250, 156, 122, 1)"].at(Math.floor(Math.random() * 3));
  },
  methods: {
    onPaintBucketLoad: function() {
      this.$refs.paintBucket.contentDocument.getElementById("fill1").style.fill = this.color;
      this.$refs.paintBucket.contentDocument.getElementById("fill2").style.fill = this.color;
    },

    base64ToArrayBuffer: function(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },

    startOverlay: function() {
      this.overlayLoop();
    },

    overlayLoop: function() {
      var ctx = document.getElementById("overlayCanvas" + this.id).getContext("2d");

      var startY = 60;
      var startX = 0;
      this.currentOverlayY += 0.25;

      ctx.strokeStyle = ctx.fillStyle = this.color;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      ctx.stroke();
      ctx.fill();

      ctx.beginPath();
      ctx.lineTo(startX, -startY);

      var am = 35;
      var tp = 950;
      var deg = this.currentOverlayY * 10;

      for (var x = startX; x <= ctx.canvas.width; x += 1) {
        var y = am * Math.sin((Math.PI / tp) * (deg + x));
        ctx.lineTo(x, y + this.currentOverlayY - startY);
      }
      ctx.lineTo(ctx.canvas.width, -startY);
      ctx.lineTo(startX, -startY);
      ctx.closePath();

      if (this.currentOverlayY < ctx.canvas.height + startY) {
        requestAnimationFrame(this.overlayLoop);
      }
    },
  },
};
</script>
<style>
.DrawViewParent {
  text-align: center;
  max-height: inherit;
  max-width: inherit;
  margin: 1em;
  margin: auto;
}

.DrawViewParent img:not(.paintBucket) {
  height: inherit;
  max-height: inherit;
  max-width: 75%;
  min-width: 75%;
  background-color: white;
  border: #b0bec5 3px solid;
  border-radius: 15px;
}

.DrawViewParent canvas {
  height: inherit;
  max-height: inherit;
  max-width: 75%;
  min-width: 75%;
  background-color: white;
  border: #b0bec5 3px solid;
  border-radius: 15px;
}

.overlayCanvas {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  min-width: 100% !important;
  min-height: 100% !important;
  z-index: 2;
  margin: 0em !important;
  border: white 0px solid !important;
  background-color: transparent !important;
}

.paintBucket {
  position: absolute;
  top: -4.75em;
  left: -3em;
  max-width: 5em;
  animation: slightshake 3s infinite;
}

@keyframes slightshake{
  0%{transform:rotate(0deg);}
  33%{transform:rotate(5deg);}
  66%{transform:rotate(-5deg);}
  100%{transform:rotate(0deg);}
}

.upsideDown{
  transform: rotate(180deg);
  animation: upsidedown 3s infinite;
}

@keyframes upsidedown {
  0% {
    transform: rotate(180deg);
  }
  33% {
    transform: rotate(185deg);
  }
  66% {
    transform: rotate(175deg);
  }
}
</style>
