var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"pure-sm-1 pure-u-1-3"},_vm._l((_vm.turnAnswerChooseListSorted),function(guess){return _c('div',{key:guess.key},[(guess.index % 2 == 0)?_c('div',{staticClass:"guessContainer",style:('text-align: ' + _vm.getAlign(guess.index) + ';')},[_c('p',{staticClass:"ink shape04",style:('transform: rotate(' +
              _vm.getRotation(_vm.turnAnswerChooseListSorted, guess.index, true) +
              'deg); padding-right: ' +
              _vm.getPadding(_vm.turnAnswerChooseListSorted, guess.index) +
              'em; margin-right: ' +
              _vm.getPadding(_vm.turnAnswerChooseListSorted, guess.index)*2 +
              'em')},[_c('strong',{staticClass:"guessValue"},[_vm._v(_vm._s(guess.value))])])]):_vm._e()])}),0),_c('div',{staticClass:"pure-sm-1 pure-u-1-3 middleAlign"},[_c('div',{staticClass:"ink shape03"},[_c('DrawView',{attrs:{"drawingBlob":_vm.turnDrawingBlob}})],1)]),_c('div',{staticClass:"pure-sm-1 pure-u-1-3"},_vm._l((_vm.turnAnswerChooseListSorted),function(guess){return _c('div',{key:guess.key},[(guess.index % 2 == 1)?_c('div',{staticClass:"guessContainer",style:('text-align: ' + _vm.getAlign(guess.index) + ';')},[_c('p',{staticClass:" ink shape04",style:('transform: rotate(' +
              _vm.getRotation(_vm.turnAnswerChooseListSorted, guess.index, false) +
              'deg); padding-left: ' +
              _vm.getPadding(_vm.turnAnswerChooseListSorted, guess.index) +
              'em; margin-left: ' +
              _vm.getPadding(_vm.turnAnswerChooseListSorted, guess.index)*2 +
              'em')},[_c('strong',{staticClass:"guessValue"},[_vm._v(_vm._s(guess.value))])])]):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }