<template>
  <div>
    <div id="phase1" v-if="phase === 1">
      <TurnResultCanvas
        :guesses="oTurnResult.guesses"
        :turnDrawingBlob="oTurnResult.turnDrawingBlob"
        :contentCreatorUser="oTurnResult.contentCreatorUser"
        @onFinished="toPhase2()"
      >
      </TurnResultCanvas>
    </div>
    <div id="phase2" v-if="phase === 2" class="ink shape03 phase02wrapper">
      <div class="pure-u-1-3">
        <DrawView v-bind:drawingBlob="oTurnResult.turnDrawingBlob"></DrawView>
        <p class="AnswerRight">{{ oTurnResult.correctAnswer }}</p>
        <p>{{ $t("turnresult.madeby") }}{{ oTurnResult.contentCreatorUser }}</p>
      </div>
      <div class="pure-u-2-3 phase02Wrapper">
        <div v-for="summary in oTurnSummary" :key="summary.username" :style="calcPhase02Style()">
          <div class="bubble-wrap-head-padding">
            <div>{{ summary.pointsGained }} {{ $t("turnresult.pts") }}</div>
            <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade">
              <span class="reactionWrapper">
                <span v-for="reaction in summary.reactions" :key="Object.keys(reaction)[0]">
                  <IconDisplay :icon="reactionList.find((x) => x.key == Object.keys(reaction)[0])"></IconDisplay>
                  <small>x</small>
                  {{ Object.values(reaction)[0] }}
                </span>
              </span>
            </transition>
            <div class="bubble-wrap-head-wrap">
              <div class="bubble-wrap-pre-text" v-if="summary.guessIsDecoy === false">
                <DrawView v-bind:drawingBlob="summary.userAvatarBlob" :styleClass="'DrawViewAvatar noMargin'" v-if="!summary.ai"></DrawView>
                <span class="DrawViewAvatar noMargin ai-avatar" v-if="summary.ai">🤖</span>
                <p class="bubble-wrap-pre-username">{{ summary.username }}</p>
              </div>
              <div v-if="summary.guessIsDecoy === true" class="bubble-wrap-pre-text">
                {{ $t("turnresult.decoy") }}
              </div>
              <div class="bubble-wrap">
                <div class="bubble left">
                  <span v-if="summary.isDrawer === false" class="bubble-text"
                    ><strong>{{ summary.guessText }}</strong></span
                  >
                  <span v-if="summary.isDrawer === true" class="bubble-text">🖊️</span>
                  <img
                    v-for="card in summary.newCards"
                    :key="card"
                    :src="'/app/assets/img/cards/' + card + '_' + langu + '.svg'"
                    :alt="card"
                    class="smallCardImage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DrawView from "./DrawView";
import reactionListJson from "../data/reactionList.json";
import IconDisplay from "./IconDisplay";
import TurnResultCanvas from "./TurnResultCanvas";

export default {
  name: "TurnResult",
  props: ["oTurnResult", "oTurnSummary"],
  data: function() {
    return {
      phase: 1,
      reactionList: reactionListJson,
      langu: "en",
    };
  },
  components: {
    DrawView,
    IconDisplay,
    TurnResultCanvas,
  },
  beforeDestroy() {
    clearInterval(this.runner);
  },
  mounted() {
    this.$root.$on("languChange", this.languChanged.bind(this));
    this.languChanged(this.$root.$i18n.locale);
  },
  methods: {
    languChanged(langu) {
      this.langu = langu === "de" || langu === "en" ? langu : "en";
    },
    toPhase2() {
      this.phase = 2;
      this.$calcZoom();

      setTimeout(
        function() {
          this.$emit("onTurnAnnounceEnd");
        }.bind(this),
        7500
      );

      let rightTurnResult = this.oTurnResult.guesses.find((guess) => guess.correct === true);
      let wrongTurnResult = this.oTurnResult.guesses
        .map((u) => u)
        .sort(function(a, b) {
          return a.users.length - b.users.length;
        })
        .find((guess) => guess.correct === false);
      if (rightTurnResult.users.length >= wrongTurnResult.users.length && rightTurnResult.users.length >= 2) {
        this.SoundManager.playNarration("turnresult-two-right", rightTurnResult.text);
      } else {
        if (wrongTurnResult.users.length >= 2) {
          this.SoundManager.playNarration("turnresult-two-wrong", rightTurnResult.text);
        } else {
          this.SoundManager.playNarration("turnresult-divided", rightTurnResult.text);
        }
      }
    },

    calcPhase02Style: function() {
      if (this.oTurnResult.guesses.length > 8) {
        return `flex: 1 0 32%`;
      }
      return `flex: 1 0 49%`;
    },
  },
};
</script>
<style scoped>
.phase02Wrapper {
  display: flex;
  flex-wrap: wrap;
}

.smallCardImage {
  position: absolute;
  top: -1em;
  right: -1em;
  width: 2.5em;
}

.phase02wrapper {
  display: flex;
  z-index: 14;
}

.ai-avatar{
  font-size: xx-large;
}
</style>
