import axios from "axios";

const FlagWrapper = {
  install(Vue) {
    Vue.prototype.$isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    Vue.prototype.$isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    Vue.prototype.$checkTauri = function() {
      Vue.prototype.$isTauri = parent.window !== window;
      return Vue.prototype.$isTauri;
    }.bind(this);

    Vue.prototype.$checkTauri();

    Vue.prototype.$randomIntFromInterval = function(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    };

    Vue.prototype.$xmur3 = function(str) {
      for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++)
        (h = Math.imul(h ^ str.charCodeAt(i), 3432918353)), (h = (h << 13) | (h >>> 19));
      return function() {
        h = Math.imul(h ^ (h >>> 16), 2246822507);
        h = Math.imul(h ^ (h >>> 13), 3266489909);
        return (h ^= h >>> 16) >>> 0;
      };
    };

    Vue.prototype.$xmur3n = function(str) {
      return Vue.prototype.$xmur3(str)()/Math.pow(2,32);
    };

    Vue.prototype.$uuidv4 = function() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    };

    Vue.prototype.$recycledCanvases = [];
    Vue.prototype.$appResizer = null;
    Vue.prototype.$SensorSuppressor = false;
    Vue.prototype.$zoomEnabled = true;

    Vue.prototype.$attachZoomHandler = function() {
      if (!Vue.prototype.$SensorSuppressor) {
        if (!Vue.prototype.$appResizer) {
          Vue.prototype.$appResizer = new Vue.prototype.ElementQueries.ResizeSensor(document.getElementById("app"), Vue.prototype.$calcZoom);
        }
        Vue.prototype.$calcZoom();
      }
    };

    Vue.prototype.$calcZoom = function(windowWidth, windowHeight) {
      if (!Vue.prototype.$zoomEnabled) return;
      setTimeout(function() {
        if (!Vue.prototype.$zoomEnabled) return;
        var appContainer = document.getElementById("app");
        var ratioY = (windowWidth ? windowWidth : window.innerHeight) / appContainer.scrollHeight;
        var ratioX = (windowHeight ? windowHeight : window.innerWidth) / appContainer.scrollWidth;

        var ratio = Math.min(ratioX, ratioY);
        ratio = Math.min(Math.max(ratio, 0.2), 2);

        if (!isFinite(ratio)) {
          return;
        } else {
          ratio -= 0.075;
        }

        var x = (appContainer.scrollWidth - appContainer.scrollWidth * ratio) / 2;
        var y = (appContainer.scrollHeight - appContainer.scrollHeight * ratio) / 2;
        appContainer.style.transform = "translateX(" + x + "px) translateY(" + y + "px) scale(" + ratio + ")";
        appContainer.style.transformOrigin = "0 0";
        window.scrollTo(0, 1);
        Vue.prototype.ZoomListeners.forEach((listener) => {
          listener.zoomScale = ratio;
        });
      }, 100);
    };

    window.addEventListener("resize", Vue.prototype.$calcZoom);

    Vue.prototype.ZoomListeners = [];

    Vue.prototype.$registerZoomListener = function(that) {
      Vue.prototype.ZoomListeners.push(that);
    };

    Vue.prototype.$removeZoomListener = function(that) {
      let index = Vue.prototype.ZoomListeners.indexOf(that);
      if (index !== -1) {
        Vue.prototype.ZoomListeners.splice(index, 1);
      }
    };

    Vue.prototype.$setSensorSuppressor = function(bVal) {
      Vue.prototype.$SensorSuppressor = bVal;
    };

    Vue.prototype.$resetZoom = function() {
      Vue.prototype.$stopSensor();
      var appContainer = document.getElementById("app");
      appContainer.style.transform = "translateX(" + 0 + "px) translateY(" + 0 + "px) scale(" + 1 + ")";
      appContainer.style.transformOrigin = "0 0";
    };

    Vue.prototype.$disableZoom = function() {
      Vue.prototype.$stopSensor();
      var appContainer = document.getElementById("app");
      appContainer.style.transform = "";
      appContainer.style.transformOrigin = "";
      Vue.prototype.$zoomEnabled = false;
    };

    Vue.prototype.$enableZoom = function() {
      Vue.prototype.$zoomEnabled = true;
    };

    Vue.prototype.$startSensor = function() {
      if (!Vue.prototype.$SensorSuppressor) {
        Vue.prototype.$appResizer = new Vue.prototype.ElementQueries.ResizeSensor(document.getElementById("app"), Vue.prototype.$calcZoom);
        Vue.prototype.$calcZoom();
      }
    };

    Vue.prototype.$stopSensor = function() {
      Vue.prototype.$appResizer?.detach();
    };

    Vue.prototype.$pollForSteamTicket = async function(fn, ms) {
      while (!window.steamTicket) {
        await this.$wait(ms);
      }
      fn();
    };

    Vue.prototype.$wait = function(ms = 1000) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    };

    Vue.prototype.$isCanvasBlank = function(aCanvas) {
      for (let i = 0; i < aCanvas.length; i++) {
        if (!this.$isCanvasContextBlank(aCanvas[i].ctx)) {
          return false; //One none blank frame is enough
        }
      }
      return true;
    };

    Vue.prototype.$isCanvasContextBlank = function(oContext) {
      var isAllBlank = true;
      isAllBlank = !oContext.getImageData(0, 0, oContext.canvas.width, oContext.canvas.height).data.some((x) => x !== 255);
      return isAllBlank; //One none blank frame is enough
    };

    Vue.prototype.$axiosErrorInterceptor = function(err) {
      if (err.response.status === 502) {
        this.$notify({
          group: "notifications",
          type: "error",
          position: "top center",
          title: this.$getMessage("error.genericError"),
          text: this.$getMessage("error.maintenance"),
        });
      } else {
        return Promise.reject(err);
      }
    };

    Vue.prototype.$getMessage = function(key) {
      return this.$t(key);
    };

    Vue.prototype.$authCheck = async function() {
      if (!this.$store.state.superSketchyOwnerSecret) {
                
        var cookieSecret = await Vue.prototype.SettingsManager.getCookie("superSketchyOwnerSecret", this.$isTauri);
        if (cookieSecret) {
          this.$store.commit("OwnerSecret", { value: cookieSecret, vue: this });
        }

        if (this.$store.state.superSketchyOwnerSecret) {
          return;
        }
      }

      if (document?.steamapi?.ticket === "tests") {
        this.$store.commit("OwnerSecret", { value: "tests", vue: this });
        return;
      }

      await this.$checkTauri();

      //OpenID
      if (this.$route.fullPath.indexOf("openid.ns") > -1) {
        axios
          .post("/api/authentication/secret/openid", this.$route.fullPath.substr(1), {
            headers: {
              "Content-Type": "plain/text",
            },
          })
          .then((resp) => {
            this.$store.commit("OwnerSecret", { value: resp.data, vue: this });
            this.$router.replace({
              name: "Hello",
            });
            this.$root.$emit("authInProgress");
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: this.$getMessage("error.dontOwnError"),
                });
              } else {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: err.response.data.message,
                });
              }
            }
          });
      } else if (this.$isTauri && !this.$store.state.superSketchyOwnerSecret && window.steamTicket) {        
        await axios
          .post("/api/authentication/secret/ticket", window.steamTicket, {
            headers: {
              "Content-Type": "plain/text",
            },
          })
          .then((resp) => {
            this.$store.commit("OwnerSecret", { value: resp.data, vue: this });
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: this.$getMessage("error.dontOwnError"),
                });
              } else {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: err.response.data.message,
                });
              }
            }
          });
      }
    };
  },
};

export default FlagWrapper;
