<template>
  <div>
    <div class="pure-u-1-1 ink shape02">
      <tabs>
        <tab :title="$t('submission.submitnewphrase')" @click="$calcZoom()">
          <p>
            <span v-html="$t('submission.guidelines.linkText')"></span>&nbsp;
            <a
              @click="
                $router.push({
                  name: 'submissionguidelines',
                })
              "
              class="link"
              >{{ $t("submission.guidelines.title") }}</a
            >
          </p>
          <p>{{ $t("submission.guidelines.addedwhen") }}</p>
          <div class="pure-form pure-form-aligned centerForm">
            <fieldset>
              <div class="pure-control-group">
                <label for="inpText">{{ $t("submission.text") }}</label>
                <input v-model="submission.text" autocomplete="off" id="inpSubmission" />
              </div>
              <div class="pure-control-group">
                <label for="inpLangu">{{ $t("party.language") }}</label>
                <select v-model="submission.langu" id="inpLangu">
                  <option v-for="langu in allLanguagesList" v-bind:value="langu.code" v-bind:key="langu.code">
                    {{ langu.name }}
                  </option>
                </select>
              </div>
              <div class="pure-controls">
                <button class="pure-button" @click="$router.back()">
                  {{ $t("hello.back") }}
                </button>
                <button class="pure-button" @click="onContentSubmit" id="btnSubmit">
                  {{ $t("party.submitGuess") }}
                </button>
              </div>
            </fieldset>
          </div>
        </tab>
        <!-- <tab :title="$t('submission.voteatranslation')" @click="$calcZoom()">
          <ContentVote mode="1"></ContentVote>
        </tab> -->
        <tab :title="$t('submission.voteaphrase')" @click="$calcZoom()">
          <ContentVote mode="2"></ContentVote>
        </tab>
      </tabs>
    </div>
    <br /><br />
    <div class="pure-u-1-1 ink shape04">
      <h2>{{ $t("submission.yourSubmissions") }}</h2>
      <v-table
        :data="submittedList"
        :currentPage.sync="submittedListPage"
        :pageSize="8"
        @totalPagesChanged="submittedListTotalPages = $event"
        class="pure-table centerSelf textAlignReset"
        id="tblSubmissions"
      >
        <thead slot="head">
          <th id="submittedOn">{{ $t("submission.submittedOn") }}</th>
          <th id="language">{{ $t("party.language") }}</th>
          <th id="text">{{ $t("submission.text") }}</th>
          <th id="accepted">{{ $t("submission.accepted") }}</th>
        </thead>
        <tbody slot="body" slot-scope="{ displayData }">
          <tr v-for="row in displayData" :key="row.text">
            <td>
              {{ new Date(row.submittedOn).toLocaleDateString("de-DE") }}
            </td>
            <td>{{ row.langu }}</td>
            <td>{{ row.text }}</td>
            <td>
              {{ $t("submission.accepted" + row.status) }}
            </td>
          </tr>
          <tr v-if="submittedList.length == 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </v-table>
      <smart-pagination :currentPage.sync="submittedListPage" :totalPages="submittedListTotalPages" :maxPageLinks="1" />
      <br />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { Tabs, Tab } from "vue-slim-tabs";
import allLanguagesList from "../data/languages.json";
import ContentVote from "./ContentVote";

export default {
  props: [],
  name: "ContentSubmission",
  components: { Tabs, Tab, ContentVote },
  data: function() {
    return {
      submittedList: [],
      submittedListPage: 1,
      submittedListTotalPages: 0,

      submission: {
        text: "",
        langu: "en",
      },
      allLanguagesList: allLanguagesList,
    };
  },
  async mounted() {
    this.$checkTauri();
    if (!this.$isTauri) {
      await this.$authCheck();
    } else {
      this.$pollForSteamTicket(this.$authCheck.bind(this), 500);
      this.SettingsManager.getBrowserSettings(this);
    }

    this.contentRefresh();
  },
  methods: {
    contentRefresh: function() {
      axios
        .get("/api/content/submitted", {
          headers: {
            "x-supersketchyownersecret": this.$store.state.superSketchyOwnerSecret,
          },
        })
        .catch(
          function(err) {
            if (err.response.status === 406) {
              this.$notify({
                group: "notifications",
                type: "error",
                position: "top center",
                title: this.$getMessage("error.genericError"),
                text: this.$getMessage("error.steamSigninFail"),
              });
              this.$router.back();
            } else {
              this.$notify({
                group: "notifications",
                type: "error",
                position: "top center",
                title: this.$getMessage("error.genericError"),
                text: this.$getMessage("error.maintenanceContent"),
              });
            }
          }.bind(this)
        )
        .then((resp) => {
          this.submittedList = resp?.data;
          if (!this.submittedList) {
            this.submittedList = [];
          }
          this.$calcZoom();
        });
    },

    onContentSubmit: function() {
      axios
        .post("/api/content/submit?superSketchyOwnerSecret=" + this.$store.state.superSketchyOwnerSecret, { content: [this.submission] })
        .then(
          function(resp) {
            if (resp.status === 208) {
              this.contentRefresh();
              this.$notify({
                group: "notifications",
                type: "error",
                position: "top center",
                title: this.$getMessage("error.genericError"),
                text: this.$getMessage("submission.alreadyReported"),
              });
            }
            this.contentRefresh();
          }.bind(this)
        )
        .catch(
          function(err) {
            if (err.response.status === 422) {
              this.contentRefresh();
              this.$notify({
                group: "notifications",
                type: "error",
                position: "top center",
                title: this.$getMessage("error.genericError"),
                text: this.$getMessage("error.genericError"),
              });
            } else if (err.response.status === 401) {
              this.$store.commit("clearOwnerSecret", { vue: this});
              this.$notify({
                group: "notifications",
                type: "error",
                position: "top center",
                title: this.$getMessage("error.genericError"),
                text: this.$getMessage("error.sessionExpiredError"),
              });
              this.showLoginNeededHost = true;
              this.state = "Mainmenu";
            }
          }.bind(this)
        );
    },
  },
};
</script>
<style scoped></style>
