import Pizzicato from "./pizzicato";

var SoundManager = {
  oBackgroundGroup: new Pizzicato.Group(),
  oDrawGroup: new Pizzicato.Group(),
  oSfxGroup: new Pizzicato.Group(),
  oNarrationGroup: new Pizzicato.Group(),

  aBackgroundMusic: require("../data/backgroundMusic.json"),
  aDrawMusic: require("../data/drawMusic.json"),
  aSfx: require("../data/sfx.json"),
  aNarration: [],
  aNarrationi18n: {
    cs : require("../data/narration_cs.json"),
    da : require("../data/narration_da.json"),
    el : require("../data/narration_el.json"),
    es : require("../data/narration_es.json"),
    et : require("../data/narration_et.json"),
    fi : require("../data/narration_fi.json"),
    fr : require("../data/narration_fr.json"),
    hu : require("../data/narration_hu.json"),
    it : require("../data/narration_it.json"),
    ja : require("../data/narration_ja.json"),
    lt : require("../data/narration_lt.json"),
    lv : require("../data/narration_lv.json"),
    nl : require("../data/narration_nl.json"),
    pl : require("../data/narration_pl.json"),
    pt : require("../data/narration_pt.json"),
    ro : require("../data/narration_ro.json"),
    ru : require("../data/narration_ru.json"),
    sk : require("../data/narration_sk.json"),
    sl : require("../data/narration_sl.json"),
    sv : require("../data/narration_sv.json"),
    zh : require("../data/narration_zh.json"),
    uk : require("../data/narration_uk.json"),
    tr : require("../data/narration_tr.json"),
    nb : require("../data/narration_nb.json"),
    ko : require("../data/narration_ko.json"),
    id : require("../data/narration_id.json"),
    bg : require("../data/narration_bg.json"),
    en : require("../data/narration_en.json"),
    de : require("../data/narration_de.json")
  },
  oActiveNarration: null,
  oSubtitleTimeout: null,

  aBackgroundMusicLoaded: [],
  aDrawMusicLoaded: [],

  isMuted: false,
  isStopped: false,
  isPlayingNarration: false,
  hasUserTouched: false,

  activeBackgroundTrack: null,
  activeDrawingTrack: null,
  oDemoSound: new Pizzicato.Sound("/app/assets/sounds/sfx/plop.opus"),

  startBackgroundMusic: async function() {
    if (this.isMuted || this.hasUserTouched === false) {
      return;
    }

    this.isStopped = false;
    this.oDrawGroup.pause();

    if (!this.activeBackgroundTrack) {
      this.playRandomBackgroundTrack();
    } else if (
      this.activeBackgroundTrack.paused === true ||
      this.activeBackgroundTrack.playing === false
    ) {
      this.activeBackgroundTrack.play();
    }
  },

  startDrawingMusic: function() {
    if (this.isMuted || this.hasUserTouched === false) {
      return;
    }

    this.isStopped = false;
    this.oBackgroundGroup.pause();

    if (!this.activeDrawingTrack) {
      this.playRandomDrawingTrack();
    } else if (
      this.activeDrawingTrack.paused === true ||
      this.activeDrawingTrack.playing === false
    ) {
      this.activeDrawingTrack.play();
    }
  },

  playRandomBackgroundTrack: function() {
    if (this.hasUserTouched === false) {
      return;
    }

    if (!this.isStopped) {
      this.activeBackgroundTrack = this.chooseRandomMusic(
        this.aBackgroundMusic,
        this.aBackgroundMusicLoaded,
        this.playRandomBackgroundTrack,
        this.oBackgroundGroup,
        this
      );
    }
  },

  playRandomDrawingTrack: function() {
    if (this.hasUserTouched === false) {
      return;
    }

    if (!this.isStopped) {
      this.activeDrawingTrack = this.chooseRandomMusic(
        this.aDrawMusic,
        this.aDrawMusicLoaded,
        this.playRandomDrawingTrack,
        this.oDrawGroup,
        this
      );
    }
  },

  chooseRandomMusic: function(
    aArray,
    aArrayLoaded,
    fEndcallback,
    oGroup,
    oContext
  ) {
    var i = Math.floor(Math.random() * aArray.length);

    var oMusic = new Pizzicato.Sound(
      {
        source: "file",
        options: {
          path: aArray[i].path,
          loop: false,
          release: 1,
          attack: 1
        }
      },
      function() {
        oMusic.on("end", fEndcallback.bind(oContext));
        oGroup.addSound(oMusic);
        oMusic.play();
      }
    );

    aArrayLoaded[i] = oMusic;
    return oMusic;
  },

  playNarration(sNarration, sSalt) {
    if (
      this.isMuted ||
      this.isPlayingNarration ||
      window.vm === undefined ||
      window.vm.$store.state.playType !== "watch" ||
      this.hasUserTouched === false
    ) {
      return;
    }

    let oNarrationSource = null;
    if (!Array.isArray(this.aNarration[sNarration])) {
      oNarrationSource = this.aNarration[sNarration];
    } else if (Array.isArray(this.aNarration[sNarration])) {
      var i = window.vm.$xmur3n(
        window.vm.$store.state.partyCode +
          "" +
          window.vm.$store.state.round +
          "" +
          Math.floor(new Date().getTime() / 100000) +
          "" +
          sSalt
      );
      oNarrationSource = this.aNarration[sNarration][
        Math.floor(i * this.aNarration[sNarration].length)
      ];
    }

    var oNarration = new Pizzicato.Sound(
      {
        source: "file",
        options: {
          path: oNarrationSource.path,
          loop: false
        }
      },
      function(error) {
        if (error) {
          return;
        }
        if (Array.isArray(oNarrationSource.subtitles)) {
          this.setSubtitleText(oNarrationSource.subtitles, 0);
        }
        this.isPlayingNarration = true;
        oNarration.on(
          "end",
          function() {
            this.isPlayingNarration = false;
            this.oActiveNarration = null;
          }.bind(this)
        );
        this.oNarrationGroup.addSound(oNarration);
        oNarration.play();
      }.bind(this)
    );
    this.oActiveNarration = oNarration;
  },

  stopNarration() {
    if (this.oActiveNarration) {
      this.oActiveNarration.stop();
      this.oActiveNarration = null;
      this.isPlayingNarration = false;
      clearTimeout(this.oSubtitleTimeout);
      
      var subtitleElement = document.getElementById("subtitlesLabel");
      subtitleElement.innerHTML = "";
      subtitleElement.style.display = "none";
    }
  },

  srtTimeToNumber(sSrtTime) {
    var aTime = sSrtTime.replace(",", ":").split(":");
    var iHours = Number(aTime[0]);
    var iMinutes = Number(aTime[1]);
    var iSeconds = Number(aTime[2]);
    var iMilliseconds = Number(aTime[3]);
    return (
      (iHours * 3600 + iMinutes * 60 + iSeconds + iMilliseconds / 1000) * 1000
    );
  },

  setSubtitleText(oSubtitles, iIndex) {
    var subtitle = oSubtitles[iIndex];
    var subtitleElement = document.getElementById("subtitlesLabel");

    if (iIndex === -1) {
      subtitleElement.innerHTML = "";
      subtitleElement.style.display = "none";
      return;
    } else {
      subtitleElement.innerHTML = subtitle.text;
      subtitleElement.style.display = "block";
    }

    var timeToNext =
      this.srtTimeToNumber(subtitle.endTime) -
      this.srtTimeToNumber(subtitle.startTime);
    this.oSubtitleTimeout = setTimeout(
      function() {
        var isNextOutOfBounds = iIndex + 1 === oSubtitles.length;
        this.setSubtitleText(
          oSubtitles,
          isNextOutOfBounds === true ? -1 : iIndex + 1
        );
      }.bind(this),
      timeToNext
    );
  },

  preloadAllSfx: function() {
    for (let i = 0; i < Object.keys(this.aSfx).length; i++) {
      var sfx = this.aSfx[Object.keys(this.aSfx)[i]];
      if (!Array.isArray(sfx)) {
        this.aSfx[Object.keys(this.aSfx)[i]] = this.preloadSfx(sfx);
      } else if (Array.isArray(sfx)) {
        for (let j = 0; j < sfx.length; j++) {
          this.aSfx[Object.keys(this.aSfx)[i]][j] = this.preloadSfx(sfx[j]);
        }
      }
    }
  },

  preloadSfx: function(sfx) {
    var oSfx = new Pizzicato.Sound(
      {
        source: "file",
        options: {
          path: sfx.path,
          loop: false
          // release: 1,
        }
      },
      function() {
        this.oSfxGroup.addSound(oSfx);
        oSfx.on(
          "play",
          function() {
            this.onSfxPlay();
          }.bind(this)
        );
        oSfx.on(
          "end",
          function() {
            this.easyIn(
              this.oBackgroundGroup.volume,
              this.fMusicVolumeBackup,
              function(val) {
                this.oBackgroundGroup.volume = val;
              }.bind(this)
            );
            this.oBackgroundGroup.volume *= 2;
          }.bind(this)
        );
      }.bind(this)
    );
    return oSfx;
  },

  playSfx: function(sName) {
    if (this.hasUserTouched === false) {
      return;
    }

    var oSound = this.aSfx[sName];

    if (Array.isArray(oSound)) {
      oSound = oSound[Math.floor(Math.random() * oSound.length)];
    }

    if (oSound) {
      oSound.play();
    }
  },

  playPlayersound(sFilename) {
    if (this.hasUserTouched === false) {
      return;
    }

    var oSfx = new Pizzicato.Sound(
      {
        source: "file",
        options: {
          path: "/app/playersounds/" + sFilename,
          loop: false
        }
      },
      function() {
        this.oSfxGroup.addSound(oSfx);
        oSfx.on(
          "play",
          function() {
            this.onSfxPlay();
          }.bind(this)
        );
        oSfx.on(
          "end",
          function() {
            oSfx.disconnect();
            this.easyIn(
              this.oBackgroundGroup.volume,
              this.fMusicVolumeBackup,
              function(val) {
                this.oBackgroundGroup.volume = val;
              }.bind(this)
            );
            this.oBackgroundGroup.volume *= 2;
          }.bind(this)
        );
        oSfx.play();
      }.bind(this)
    );
  },

  onSfxPlay: function() {
    this.oBackgroundGroup.volume = this.fMusicVolumeBackup * 0.5;
  },

  easyIn: async function(start, end, callbackFunc) {
    var steps = 25;
    for (var i = 1; i < steps + 1; i++) {
      var val = i * ((end - start) / steps) + start;
      callbackFunc(val);
      await new Promise(resolve => setTimeout(resolve, 50));
    }
  },

  stopAll: function() {
    this.oBackgroundGroup.stop();
    this.oDrawGroup.stop();
    this.oSfxGroup.stop();
    this.isStopped = true;
  },

  fMusicVolumeBackup: 0,

  setVolume: function(aVolume, iDemo) {
    if (aVolume.master > 0) {
      this.isMuted = false;
    } else {
      this.isMuted = true;
    }

    Pizzicato.volume = aVolume.master / 100;
    this.oBackgroundGroup.volume = aVolume.music / 100;
    this.oDrawGroup.volume = aVolume.music / 100;
    this.oNarrationGroup.volume = aVolume.narration / 100;
    this.oSfxGroup.volume = aVolume.sfx / 100;
    this.fMusicVolumeBackup = aVolume.music / 100;

    if (iDemo) {
      this.oDemoSound.volume = iDemo / 100;
      this.oDemoSound.play();
    }
  },

  mute: function() {
    this.isMuted = true;
  },

  unmute: function() {
    this.isMuted = false;
  },

  onInit: function() {
    document.body.addEventListener(
      "click",
      function(event) {
        if (
          event.target.nodeName === "BUTTON" ||
          (event.target.nodeName === "LI" &&
            event.target.getAttribute("role") === "tab") ||
          event.target.parentElement.nodeName === "BUTTON"
        ) {
          this.playSfx("button-press");
        }
      }.bind(this)
    );

    document.addEventListener("mouseup", this.onFirstTouchClick.bind(this));
    document.addEventListener("touchend", this.onFirstTouchClick.bind(this));

    window.vm.$root.$on("languChange", this.languChanged.bind(this));
    this.languChanged(window.vm.$root.$i18n.locale);

    this.preloadAllSfx();
  },

  languChanged: function(langu) {
    this.aNarration = this.aNarrationi18n[langu] != null ?  this.aNarrationi18n[langu] : this.aNarrationi18n["en"];
  },

  onFirstTouchClick: function() {
    if (this.hasUserTouched === true) {
      return;
    }

    this.hasUserTouched = true;
  }
};

export default SoundManager;
