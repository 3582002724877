<template>
  <div class="verticalAlign ink shape04">
    <p v-if="activatedCards.length > 0" class="title">{{ $t("party.activatedCards") }}</p>
    <template v-for="card in activatedCards">
      <p :key="card.cardName + card.targetPlayer + card.sourcePlayer" class="flexWrapper fadeInAnimation">
        <span>
          {{ card.sourcePlayer }}
        </span>
        <span class="arrowCardWrapper">
          <img src="/app/assets/img/arrow-right.svg" alt="arrow-right" class="arrowRight" />
          <img :src="'/app/assets/img/cards/' + card.cardName + '_' + langu + '.svg'" :alt="card.cardName" class="smallCardImage wiggle" />
        </span>
        <span>
          {{ card.targetPlayer }}
        </span>
      </p>
    </template>
  </div>
</template>
<script>
export default {
  props: ["activatedCards"],
  name: "ActivatedCardView",
  components: {},
  data: function() {
    return {
      langu: "en",
    };
  },
  mounted() {
    this.$root.$on("languChange", this.languChanged.bind(this));
    this.languChanged(this.$root.$i18n.locale);
  },
  methods: {
    languChanged(langu) {
      this.langu = langu === "de" || langu === "en" ? langu : "en";
    },
  },
};
</script>
<style scoped>
.smallCardImage {
  position: absolute;
  left: 1.5em;
  top: -1.5em;
  width: 2.5em;
  z-index: 4;
}

.verticalAlign {
  vertical-align: middle;
  margin-top: 2em;
}

.arrowRight {
  position: absolute;
  left: 0.5em;
  top: -0.6em;
  z-index: 3;
}

.arrowCardWrapper {
  position: relative;
  min-width: 6em;
}

.flexWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexWrapper:not(:last-child) {
  margin-bottom: 2.5em;
}

.title {
  margin-bottom: 1.5em;
  margin-top: 0em;
}

.fadeInAnimation {
  animation: fadeInAnimation 0.5s;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wiggle {
  animation: wiggle 2.5s infinite;
}
</style>
