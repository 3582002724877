<template>
  <div>
    <button
      v-if="state === 'closed'"
      class="pure-button"
      @click="
        state = 'open';
        $calcZoom();
      "
    >
      {{ $t("party.kickPlayer") }}
    </button>
    <div v-if="state === 'open'">
      <h2 class="centerContent">{{ $t("party.players") }}</h2>
      <table>
        <thead>
          <th id="user"></th>
          <th id="btn"></th>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.name">
            <td>
              <p class="username">{{ user.name }}</p>
            </td>
            <td>
              <button class="pure-button" @click="onKickPlayer(user.name)" :disabled="user.name === partyHost" v-if="username === partyHost">
                {{ $t("party.kickPlayer") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["users", "partyHost", "username"],
  name: "IngameKick",
  components: {},
  data: function() {
    return {
      state: "closed", //close, open
    };
  },
  mounted() {
    this.state = "closed";
  },
  methods: {
    onKickPlayer: function(username) {
      axios
        .put("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/kick/" + username, null, {
          headers: {
            "Content-Type": "text/plain",
            "x-supersketchysecret": this.$store.state.superSketchySecret,
          },
        })
        .then(
          function(resp) {
            if (resp.status == 204) {
              this.$notify({
                group: "notifications",
                type: "success",
                position: "top center",
                text: this.$getMessage("party.kickAfterRound"),
              });
            }
          }.bind(this)
        )
        .catch((err) => {
          if (err.response) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: err.response.data.message,
            });
          }
        });
    },
  },
};
</script>

<style scoped></style>
