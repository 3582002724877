<template>
  <span> </span>
</template>
<script>
const confetti = require("canvas-confetti");

export default {
  props: [],
  name: "Confetti",
  components: {},
  data: function() {
    return {
      confettiCanvas: null,
      confetti: null,
    };
  },
  mounted() {
    this.confettiCanvas = document.createElement("canvas");
    this.confettiCanvas.id = "confettiCanvas";
    this.confettiCanvas.classList.add("confettiCanvas");
    document.body.appendChild(this.confettiCanvas);

    this.confetti = confetti.create(this.confettiCanvas, {
      resize: true,
      useWorker: true,
    });

    this.$root.$on("Confetti", () => {
      this.sprayConfetti();
    });

    this.$root.$on("Explosion", (x, y) => {
      this.spawnExplosion(x, y);
    });

    this.$root.$on("AiExplosion", (x, y) => {
      this.spawnAiExplosion(x, y);
    });

    this.$root.$on("Pride", (x1, y1, x2, y2) => {
      this.correctGuessPride(x1, y1, x2, y2);
    });
  },
  methods: {
    sprayConfetti: function() {
      this.confetti({
        particleCount: 200,
        spread: 90,
        origin: { y: 0.5 },
      });
    },

    spawnExplosion: function(x, y) {
      if (x > 0 && x < 1 && y > 0 && y < 1) {
        this.confetti({
          particleCount: 100,
          spread: 360,
          origin: { x: x, y: y },
          colors: ["#546e7a", "#4e6773", "#607d8b"],
          shapes: ["circle", "triangle", "square"],
          sizes: [0.5, 1, 2],
          startVelocity: 25,
        });
      }
    },

    spawnAiExplosion: function(x, y) {
      if (x > 0 && x < 1 && y > 0 && y < 1) {
        this.confetti({
          spread: 360,
          ticks: 50,
          gravity: 0,
          decay: 0.94,
          startVelocity: 30,
          shapes: ["star"],
          colors: ["FFE400", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
          particleCount: 250,
          scalar: 1.2,
        });
      }
    },

    correctGuessPride: function(x1, y1, x2, y2) {
      var end = Date.now() + 1000;
      var confetti = this.confetti;
      (function frame() {
        confetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: { x: x1, y: y1 },
          colors: ["#FFD700", "#DAA520", "#FAFAD2"],
          shapes: ["circle", "triangle", "square"],
          sizes: [0.5, 1, 2],
          startVelocity: 25,
        });
        confetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: { x: x2, y: y2 },
          colors: ["#FFD700", "#DAA520", "#FAFAD2"],
          shapes: ["circle", "triangle", "square"],
          sizes: [0.5, 1, 2],
          startVelocity: 25,
        });
        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
    },
  },
};
</script>

<style scoped></style>
