<template>
  <span>
    <span v-if="icon.type === 'emoji'">{{ icon.emoji }}</span>
    <span v-if="icon.type === 'svg'"><img :src="icon.emoji" alt="icon" class="iconSvg"/></span>
  </span>
</template>
<script>
export default {
  props: ["icon"],
  name: "IconDisplay",
};
</script>

<style scoped>
</style>
