<template>
  <div class="menuPadding ink shape04">
    <button class="pure-button" @click="$router.back()">
      {{ $t("hello.back") }}
    </button>
    <div class="guidelineWrapper">
      <h2>{{ $t("submission.guidelines.title") }}</h2>
      <ol>
        <li>{{ $t("submission.guidelines.rule1") }}</li>
        <li>{{ $t("submission.guidelines.rule2") }}</li>
      </ol>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  name: "SubmissionGuidelines",
  components: {},
  data: function() {
    return {};
  },
};
</script>

<style scoped></style>
