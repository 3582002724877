<template>
  <div>
    <div class="dragBarContainer" v-if="showDragbar">
      <div class="dragBar" id="dragBar" @mousedown="onStartDrag"></div>
      <div class="dragBarButtonContainer">
        <button id="max-btn" class="dragBarBtn" @click="onMinimize()">-</button>
        <button id="max-btn" class="dragBarBtn" @click="onMaximize()">+</button>
        <button id="close-btn" class="dragBarBtn" @click="onQuit">x</button>
      </div>
    </div>
    <RefreshCanvas v-if="showDragbar"></RefreshCanvas>
    <Confetti></Confetti>
    <BackgroundCanvas></BackgroundCanvas>
    <vue-progress-bar v-if="$router.currentRoute.name != 'Hello'"></vue-progress-bar>
    <WatchTimer v-if="$router.currentRoute.name != 'Hello'"></WatchTimer>
    <AppIntro :manualOpen="manualOpenAppIntro" :onClose="onCloseAppIntro"></AppIntro>
    <ClipInstallDialog v-if="showClipInstallDialog"></ClipInstallDialog>
    <div id="subtitles"><p id="subtitlesLabel"></p></div>
    <LoginPopup v-if="openLoginPopup"></LoginPopup>
    <h2
      id="lobbyCodeLbl"
      class="lobbyCode"
      v-if="$store.state.showPartyCode && $store.state.playType === 'watch'"
      v-html="$store.state.partyCode"
    ></h2>
    <notifications group="notifications" />
    <div id="app">
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="bounceUp" mode="out-in" :duration="5">
        <router-view></router-view>
      </transition>
    </div>
    <div v-if="isPaused == true" class="pauseModal">
      <div class="pauseModalContent ink shape04">
        <button
          v-if="pauseState === 'Main'"
          class="pure-button"
          id="btnContinue"
          @click="
            isPaused = !isPaused;
            pauseState = 'Main';
          "
        >
          {{ $t("party.continue") }}</button
        ><br />
        <button v-if="pauseState === 'Main'" class="pure-button" id="btnSettings" @click="pauseState = 'Settings'">
          {{ $t("settings.settings") }}
        </button>
        <br />
        <button v-if="pauseState === 'Main'" class="pure-button" id="btnLeave" @click="$root.$emit('Leave', true)">
          {{ $t("party.leave") }}
        </button>
      </div>
    </div>
    <div v-if="isPaused == true && pauseState === 'Settings'" class="pauseModal">
      <div class="pauseModalSettings ink shape04">
        <Settings v-if="pauseState === 'Settings'" @onSaveSettings="onSaveSettings" type="Ingame" class="menuPadding"></Settings>
      </div>
    </div>
    <portal-target name="overlay-outlet"></portal-target>
    <ClipMonitor v-if="showClipMonitor"></ClipMonitor>
  </div>
</template>

<script>
import BackgroundCanvas from "./components/BackgroundCanvas";
import WatchTimer from "./components/WatchTimer";
import Settings from "./components/Settings";
import AppIntro from "./components/AppIntro";
import LoginPopup from "./components/LoginPopup";
import Cookies from "js-cookie";
import RefreshCanvas from "./components/RefreshCanvas";
import Confetti from "./components/Confetti";
import ClipInstallDialog from "./components/ClipInstallDialog";
import ClipMonitor from "./components/ClipMonitor";

import { LogicalSize } from "@tauri-apps/api/window";

export default {
  name: "app",
  components: {
    BackgroundCanvas,
    WatchTimer,
    Settings,
    AppIntro,
    LoginPopup,
    RefreshCanvas,
    Confetti,
    ClipInstallDialog,
    ClipMonitor,
  },
  data() {
    return {
      isPaused: false,
      pauseState: "Main", //Main / Settings
      manualOpenAppIntro: false,
      openLoginPopup: false,
      showDragbar: false,
      showClipInstallDialog: false,
      showClipMonitor: false,
    };
  },
  created() {
    if (this.$isMobile) {
      this.SettingsManager.settings.lowPerformanceMode = true;
      this.$root.$emit("lowPerformanceModeChange", this.SettingsManager.settings.lowPerformanceMode);
    }

    if (this.$isTauri) {
      this.SettingsManager.getBrowserSettings(this);

      window.addEventListener(
        "message",
        function (event) {
          switch (event.data.type) {
            case "steam-ticket-event":
              window.steamTicket = event.data.payload;
              this.$checkTauri();
              break;
            case "calcZoom":
              this.$calcZoom();
              break;
            case "settings":
              this.SettingsManager.settings = event.data.payload;
              this.SettingsManager.applySettings(this);
              break;
            case "getCookie":
              this.SettingsManager.resolveCookie(event.data.name, event.data.payload);
              break;
            case "check-clip-installed-event":
              this.$root.$emit("check-clip-installed-event", event.data.payload);
              break;
            case "install-clip-log-event":
              this.$root.$emit("install-clip-log-event", event.data.payload);
              break;
            case "check-ai-online-result":
              this.$root.$emit("check-ai-online-result", event.data.payload);
              break;
            case "retrieve-ai-performance-result":
              this.$root.$emit("retrieve-ai-performance-result", event.data.payload);
              break;
            case "ai-recognition-result":
              this.$root.$emit("ai-recognition-result", event.data.payload);
              break;
            case "reset-clip-event":
              this.$root.$emit("reset-clip-event");
              break;
          }
        }.bind(this),
        false
      );

      parent.postMessage({ type: "ssp_setup" }, "*");
    }

    parent.postMessage(
      {
        type: "getSteamTicket",
      },
      "*"
    );

    this.$checkTauri();
  },
  mounted() {
    this.showDragbar = this.$isTauri;

    this.$root.$on(
      "PauseMenuToggle",
      function () {
        this.isPaused = !this.isPaused;
        this.pauseState = "Main";
      }.bind(this)
    );

    this.$root.$on(
      "PauseMenuClose",
      function () {
        this.isPaused = false;
      }.bind(this)
    );

    this.$root.$on(
      "AppIntroShow",
      function () {
        this.manualOpenAppIntro = true;
      }.bind(this)
    );

    this.$root.$on(
      "ClipInstallDialogShow",
      async function () {
        let noai = JSON.parse(await this.SettingsManager.getCookie("settings", this.$isTauri)).noai;
        if (!noai) {
          this.showClipInstallDialog = true;
        }
      }.bind(this)
    );

    this.$root.$on("ClipReset", function () {
      parent.postMessage({ type: "resetClip" }, "*");
    });

    this.$root.$on(
      "closeClipInstallDialog",
      function () {
        this.showClipInstallDialog = false;
      }.bind(this)
    );

    this.$root.$on(
      "LoginPopupShow",
      function () {
        this.openLoginPopup = true;
      }.bind(this)
    );

    this.$root.$on(
      "LoginPopupClose",
      function () {
        this.openLoginPopup = false;
      }.bind(this)
    );

    this.$root.$on(
      "requestAiPerformance",
      parent.postMessage(
        {
          type: "requestAiPerformance",
        },
        "*"
      )
    );

    this.$root.$on(
      "showClipMonitor",
      function () {
        this.showClipMonitor = true;
      }.bind(this)
    );

    let partySettings = Cookies.get("partySettings");
    if (partySettings) {
      this.$store.commit("PartySettings", JSON.parse(partySettings));
    }

    this.$setControlleri18n({
      gamepadConnected: this.$t("gamepad.connected"),
      gamepadDisconnected: this.$t("gamepad.disconnected"),
      gamepadControlDeactivated: this.$t("gamepad.controlDeactivated"),
    });

    this.loadResolution();
  },
  methods: {
    onSaveSettings: function () {
      this.pauseState = "Main";
    },

    onCloseAppIntro: function () {
      this.manualOpenAppIntro = false;
    },

    onMinimize: function () {
      parent.postMessage({ type: "minimize" }, "*");
    },

    onMaximize: function () {
      parent.postMessage({ type: "toggleMaximize" }, "*");
    },

    onQuit: function () {
      parent.postMessage({ type: "quit" }, "*");
    },

    onStartDrag: function () {
      parent.postMessage({ type: "startDragging" }, "*");
    },

    loadResolution: function () {
      if (this.$isTauri) {
        let resolution = Cookies.get("resolution") !== undefined ? JSON.parse(Cookies.get("resolution")) : null;
        if (resolution && resolution.resolutionEnabled === true) {
          parent.postMessage({ type: "setSize", size: new LogicalSize(Number(resolution.width), Number(resolution.height)) }, "*");
        }
      }
    },
  },
};
</script>
<style scoped>
.dragBarContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  z-index: 1;
}

.dragBarButtonContainer {
  flex: 10%;
  text-align: right;
}

.dragBar {
  height: 5vh;
  flex: 90%;
  text-align: right;
}

.dragBarBtn {
  font-family: "Roboto", sans-serif;
  position: relative;
  transition: background-color 0.2s;
  margin: auto;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  height: 2em;
}

.dragBarBtn:hover {
  background-color: #d4d4d4;
}

#close-btn:hover {
  background-color: #dc322f;
}

#subtitles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  display: flex;
  pointer-events: none;
  align-items: flex-end;
  justify-content: center;
}

#subtitlesLabel {
  background-color: rgba(0, 0, 0, 0.7);
  color: #d4d4d4;
  padding: 0.25em;
  display: none;
}
</style>
