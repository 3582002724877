<template>
  <div class="helloWrapper" ref="root">
    <br v-if="state === 'Mainmenu'" />
    <div v-if="state === 'Mainmenu'" class="ink shape01 mainMenuDiv" appear>
      <img src="/app/assets/img/logo.png" class="logo" alt="Logo" />
    </div>
    <br v-if="state === 'Mainmenu'" />
    <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="bounceUp" mode="out-in" :duration="5">
      <div v-if="state === 'Mainmenu'" class="ink shape03">
        <button
          id="btnCreateParty"
          ref="btnCreateParty"
          class="pure-button"
          @click="onCreateParty"
          :gamepadClickable="true"
          :disabled="authInProgress"
        >
          {{ $t("hello.createParty") }}
        </button>
      </div>
    </transition>
    <br v-if="state === 'Mainmenu'" />
    <div v-if="state === 'Mainmenu'" class="ink shape02">
      <button id="btnJoinPartyAsPlayer" ref="btnJoinPartyAsPlayer" class="pure-button" @click="state = 'Join'">
        {{ $t("hello.joinParty") }}
      </button>
    </div>
    <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="bounceUp" mode="out-in" :duration="5">
      <div class="ink shape01" v-if="state === 'Join' && !recordPlayersound && !drawAvatar">
        <div class="pure-form pure-form-stacked">
          <label for="inpJoin">{{ $t("global.partyCode") }}</label>
          <input
            id="inpJoin"
            class="centerSelf PartyCode"
            v-model="$store.state.partyCode"
            :maxlength="4"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
          <br />
          <label for="inpName">{{ $t("hello.yourName") }}</label>
          <input
            id="inpName"
            class="centerSelf"
            v-model="name"
            v-on:keyup.enter="onJoinParty"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            :maxlength="15"
          />
          <br />
          <small
            ><span v-html="$t('hello.eula_1')"></span>&nbsp;
            <a
              @click="
                $router.push({
                  name: 'eula',
                })
              "
              class="link"
              >{{ $t("hello.eula_2") }}</a
            ></small
          >
          <br />
          <br />
          <button class="pure-button" @click="state = 'Mainmenu'">
            {{ $t("hello.back") }}
          </button>
          <button id="btnJoinParty" v-if="!drawAvatar" class="pure-button" @click="onJoinParty">
            {{ $t("hello.join") }}
          </button>
          <div v-if="$isMobile">
            <br />
            <small
              ><span v-html="$t('hello.addtohomescreen')"></span>&nbsp;<strong
                ><a
                  @click="
                    state = 'AddToHomescreen';
                    resizeVideoContainer();
                  "
                  class="link"
                  ><span v-html="$t('hello.why')"></span></a></strong
            ></small>
          </div>
        </div>
      </div>
    </transition>
    <br v-if="state === 'Mainmenu'" />
    <div v-if="!drawAvatar">
      <div v-if="state === 'Mainmenu'" class="ink shape01">
        <button class="pure-button" ref="btnAddPresentation" @click="state = 'Watch'" :gamepadClickable="true">
          {{ $t("hello.watch") }}
        </button>
      </div>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="bounceUp" mode="out-in" :duration="5">
        <div v-if="state === 'Watch'" class="pure-form pure-form-aligned ink shape01" ref="addPresentation">
          <fieldset>
            <label for="inpWatch">{{ $t("global.partyCode") }}</label>
            <br />
            <input
              id="inpWatch"
              class="centerSelf PartyCode"
              v-on:keyup.enter="onWatchparty"
              v-model="partyCodeWatch"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              :maxlength="4"
              :gamepadClickable="true"
            />
            <br />
            <input
              id="inpMusic"
              type="checkbox"
              class="musicCheckbox"
              v-model="musicCheckboxEnabled"
              @change="onInpMusicChange"
              :gamepadClickable="true"
            />
            <label for="inpMusic">{{ $t("hello.joinWithMusic") }}</label>
            <br />
            <button class="pure-button" @click="state = 'Mainmenu'" :gamepadClickable="true">
              {{ $t("hello.back") }}
            </button>
            <button class="pure-button" @click="onWatchparty" :gamepadClickable="true">
              {{ $t("hello.join") }}
            </button>
          </fieldset>
        </div>
      </transition>
      <br />
    </div>
    <div v-if="state === 'Mainmenu'" class="ink shape01">
      <button class="pure-button" ref="btnServerBrowser" @click="onPublicListNavigate()">
        {{ $t("hello.serverbrowser") }}
      </button>
    </div>
    <div v-if="!drawAvatar">
      <br v-if="state === 'Mainmenu'" />
      <span v-if="state === 'Mainmenu'">
        <div class="ink shape02">
          <button
            id="btnContentSubmission"
            ref="btnContentSubmission"
            class="pure-button"
            @click="onContentSubmissionNavigate"
            :disabled="authInProgress"
          >
            {{ $t("hello.contentsubmission") }}
          </button>
        </div>
        <br />
        <div class="ink shape04">
          <button id="btnSettings" ref="btnSettings" class="pure-button" @click="onChangeToSettings" :gamepadClickable="true">
            {{ $t("settings.settings") }}
          </button>
        </div>
        <br />
        <div class="ink shape02">
          <button id="btnHelp" ref="btnHelp" class="pure-button" @click="$root.$emit('AppIntroShow')">
            {{ $t("hello.help") }}
          </button>
        </div>
        <br />
        <div class="ink shape04">
          <button
            id="btnCredits"
            class="pure-button"
            @click="
              $router.push({
                name: 'credits',
              })
            "
          >
            {{ $t("hello.credits") }}
          </button>
        </div>
        <br />
        <div v-if="$isTauri" class="ink shape01">
          <button id="btnQuitGame" ref="btnQuitGame" class="pure-button" @click="onQuitGame" :gamepadClickable="true">
            {{ $t("hello.quit") }}
          </button>
        </div>
      </span>
      <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="bounceUp" mode="out-in" :duration="5">
        <div v-if="state === 'AddToHomescreen'" class="menuPadding ink shape03">
          <p v-html="$t('hello.addtohomescreen_explain')"></p>
          <p v-if="$isiOS" v-html="$t('hello.addtohomescreen_explain_ios')"></p>
          <div v-if="$isiOS" class="videoContainer">
            <video v-if="$isiOS" class="videoInsert" controls="true" src="/app/assets/video/ios.mp4" canplay="$calcZoom();"></video>
          </div>
          <p v-if="!$isiOS" v-html="$t('hello.addtohomescreen_explain_android')"></p>
          <div v-if="!$isiOS" class="videoContainer">
            <video v-if="!$isiOS" class="videoInsert" controls="true" src="/app/assets/video/android.mp4" canplay="$calcZoom();"></video>
          </div>
          <button class="pure-button" @click="state = 'Join'">
            {{ $t("hello.back") }}
          </button>
        </div>
      </transition>
    </div>
    <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="bounceUp" mode="out-in" :duration="5">
      <div class="ink shape01" v-if="drawAvatar" :key="NaN">
        <label>{{ $t("hello.yourAvatar") }}</label>
        <DrawArea :type="'avatar'" ref="DrawAreaHello" :key="NaN" :renderAdditionalButtons="false" v-if="drawAvatar"></DrawArea>
        <button
          class="pure-button"
          @click="
            drawAvatar = false;
            drawError = false;
          "
        >
          {{ $t("hello.back") }}
        </button>
        <button id="btnJoinParty" class="pure-button" @click="onToRecordPlayersound">
          {{ $t("hello.next") }}
        </button>
      </div>
    </transition>
    <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="bounceUp" mode="out-in" :duration="5">
      <div class="ink shape01" v-if="recordPlayersound" :key="NaN">
        <SoundRecorder></SoundRecorder>
        <button
          class="pure-button"
          @click="
            drawAvatar = true;
            drawError = false;
            recordPlayersound = false;
          "
        >
          {{ $t("hello.back") }}
        </button>
        <button id="btnJoinParty" class="pure-button" @click="onJoinParty" :disabled="$store.state.avatarBlob === null">
          {{ $t("hello.join") }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import DrawArea from "./DrawArea";
import SoundRecorder from "./SoundRecorder";
// import { process } from "@tauri-apps/api";

export default {
  name: "Hello",
  data() {
    return {
      partyCodeWatch: "",
      name: "",
      drawAvatar: false,
      drawError: false,
      recordPlayersound: false,
      state: "Mainmenu",
      musicCheckboxEnabled: true,
      authInProgress: false,
    };
  },
  components: {
    DrawArea,
    SoundRecorder,
  },
  metaInfo() {
    return {
      title: "Super Sketchy Party",
    };
  },
  created() {
    document.body.classList.remove("noScrollWrapper");

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      let vhr = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vhr}px`);
    });

    axios.interceptors.response.use(undefined, this.$axiosErrorInterceptor.bind(this));
  },
  computed: {
    ownedButtonsEnabled() {
      return this.$store.state.superSketchyOwnerSecret !== "";
    },
  },
  watch: {
    state: function(sNewState) {
      if (sNewState === "Watch") {
        this.$nextTick(function() {
          this.$setControllerViewConfig({ controls: this.$tabbable(this.$refs.addPresentation) });
        });
      } else {
        this.$nextTick(function() {
          this.$setControllerViewConfig({ controls: this.$tabbable(this.$refs.root) });
        });
      }
    },
  },
  mounted() {
    if (this.$route.params.partyCode) {
      this.$store.commit("PartyCode", { code: this.$route.params.partyCode });
      if (this.$store.state.partyCode !== "" && this.$route.fullPath.indexOf("watch") === -1) {
        this.state = "Join";
      } else if (this.$store.state.partyCode !== "" && this.$route.fullPath.indexOf("watch") > -1) {
        this.state = "Watch";
        this.partyCodeWatch = this.$route.params.partyCode;
      } else {
        this.state = "Mainmenu";
      }
    }

    document.body.classList.add("noScrollWrapper");
    this.$attachZoomHandler();
    this.SoundManager.stopAll();
    this.$checkTauri();

    if (this.$isTauri) {
      this.$pollForSteamTicket(this.$authCheck.bind(this), 500);
      this.checkClipInstalled();
    }

    this.SettingsManager.getBrowserSettings(this);
    this.$authCheck();
    this.changei18n();

    if (this.$route.fullPath.indexOf("openid.ns") > -1) {
      this.authInProgress = true;
    } else {
      this.authInProgress = false;
    }

    this.$setControllerViewConfig({ controls: this.$tabbable(this.$refs.root) });

    this.$root.$on(
      "LoginPopupClose",
      function() {
        this.$setControllerViewConfig({ controls: this.$tabbable(this.$refs.root) });
      }.bind(this)
    );

    this.$root.$on(
      "authInProgress",
      function() {
        this.authInProgress = false;
      }.bind(this)
    );
  },
  methods: {
    changei18n: function() {
      if (this.SettingsManager.settings.uiLangu) {
        this.$root.$i18n.locale = this.SettingsManager.settings.uiLangu;
      }
    },

    onCreateParty: async function() {
      await this.$authCheck();

      if (this.$store.state.superSketchyOwnerSecret) {
        this.$store.commit("PlayType", "watch");
        axios
          .post("/api/party", {
            superSketchyOwnerSecret: this.$store.state.superSketchyOwnerSecret,
            langu: this.$root.$i18n.locale.substr(0, 2),
            description: "",
            public: false,
            awardCardsAtGameStart: true,
            carryOverCardsToNextGame: true,
          })
          .then(
            function(resp) {
              this.SoundManager.startBackgroundMusic();
              this.$store.commit("PartyCode", {
                code: resp.data.publicPartyId.toUpperCase(),
              });
              this.$router.push({
                name: "partywatch",
                params: {
                  partyCode: this.$store.state.partyCode.toUpperCase(),
                },
              });
            }.bind(this)
          )
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$store.commit("clearOwnerSecret", { vue: this });
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: this.$getMessage("error.sessionExpiredError"),
                });
                this.$root.$emit("LoginPopupShow");
              } else {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: err.response.data.message,
                });
              }
            }
          });
      } else if (this.$isTauri) {
        this.$notify({
          group: "notifications",
          type: "error",
          position: "top center",
          title: this.$getMessage("error.genericError"),
          text: this.$getMessage("error.steamSigninFail"),
        });
      } else {
        this.$root.$emit("LoginPopupShow");
      }
    },

    onContentSubmissionNavigate: async function() {
      await this.$authCheck();

      if (!this.$store.state.superSketchyOwnerSecret) {
        this.$root.$emit("LoginPopupShow");
      } else {
        this.$router.push({
          name: "contentsubmission",
        });
      }
    },

    onPublicListNavigate: async function() {
      await this.$authCheck();

      if (!this.$store.state.superSketchyOwnerSecret) {
        this.$root.$emit("LoginPopupShow");
      } else {
        this.$router.push({
          name: "serverbrowser",
        });
      }
    },

    onWatchparty: function() {
      if (this.partyCodeWatch) {
        this.$store.commit("PlayType", "watch");
        axios
          .get(`/api/party/${this.partyCodeWatch.toUpperCase()}`)
          .then(() => {
            this.SoundManager.startBackgroundMusic();
            this.$router.push({
              name: "partywatch",
              params: {
                partyCode: this.partyCodeWatch.toUpperCase(),
              },
            });
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 404) {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: this.$getMessage("error.partyDoesNotExist"),
                });
              }
            }
          });
      } else {
        this.$notify({
          group: "notifications",
          type: "error",
          position: "top center",
          title: this.$getMessage("error.joinDetailsNotFilledTitle"),
          text: this.$getMessage("error.watchDetailsNotFilled"),
        });
      }
    },

    onToRecordPlayersound: function() {
      if (!this.$isCanvasBlank(this.$refs.DrawAreaHello.apngFrames)) {
        this.drawAvatar = false;
        this.recordPlayersound = true;
      } else {
        this.$notify({
          group: "notifications",
          type: "error",
          position: "top center",
          title: this.$getMessage("error.genericError"),
          text: this.$getMessage("error.emptyDrawing"),
        });
        this.drawError = true;
        this.recordPlayersound = false;
        return;
      }

      this.$refs.DrawAreaHello.exportAPNG()
        .then((oResolveObject) => {
          if (oResolveObject.oBlob.size > 0) {
            this.$store.commit("AvatarBlob", oResolveObject.oBlob);
            this.$store.commit("PerformanceData", oResolveObject.oPerformanceInfo);
          } else {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.joinDetailsNotFilledTitle"),
              text: this.$getMessage("error.joinDetailsNotFilledAvatar"),
            });
            this.drawError = true;
          }
        })
        .catch((err) => {
          if (err == "no_frames_error") {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.joinDetailsNotFilledTitle"),
              text: this.$getMessage("error.joinDetailsNotFilledAvatar"),
            });
          }
        });
    },

    onJoinParty: function() {
      if (!this.drawAvatar && !this.recordPlayersound) {
        //Step 1: Check the party
        if (this.$store.state.partyCode.toUpperCase() === "") {
          this.$notify({
            group: "notifications",
            type: "error",
            position: "top center",
            title: this.$getMessage("error.genericError"),
            text: this.$getMessage("error.watchDetailsNotFilled"),
          });
          return;
        }

        if (this.name === "") {
          this.$notify({
            group: "notifications",
            type: "error",
            position: "top center",
            title: this.$getMessage("error.genericError"),
            text: this.$getMessage("error.joinDetailsNotFilledName"),
          });
          return;
        }

        axios
          .get(`/api/party/${this.$store.state.partyCode.toUpperCase()}/username/${this.name}`)
          .then(() => {
            this.drawAvatar = true;
            window.scrollTo(0, 1);
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 404) {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: this.$getMessage("error.partyDoesNotExist"),
                });
              } else if (err.response.status === 416) {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: this.$getMessage("error.maxPlayersReached"),
                });
              } else if (err.response.status === 423) {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: this.$getMessage("error.gameInProgress"),
                });
              } else if (err.response.status === 409) {
                this.$notify({
                  group: "notifications",
                  type: "error",
                  position: "top center",
                  title: this.$getMessage("error.genericError"),
                  text: this.$getMessage("error.sameName"),
                });
              } else if (err.response.status === 302) {
                //Already in the party, instant rejoin
                this.name = this.name.trim();
                this.$router.push({
                  name: "party",
                  params: {
                    partyCode: this.$store.state.partyCode.toUpperCase(),
                    name: this.name,
                  },
                });
              }
            }
          });
      } else if (!this.drawAvatar && this.recordPlayersound) {
        if (this.$store.state.selectedSound === "") {
          this.$notify({
            group: "notifications",
            type: "error",
            position: "top center",
            title: this.$getMessage("error.joinDetailsNotFilledTitle"),
            text: this.$getMessage("error.soundNotFilled"),
          });
          return;
        }

        //Step 3: Actually join the party
        this.name = this.name.trim();
        var secret = this.$uuidv4();
        this.$store.commit("PlayType", "play");

        if (this.name !== "" && this.$store.state.partyCode !== "") {
          var formData = new FormData();
          formData.append("performanceData", JSON.stringify(this.$store.state.performanceData));
          formData.append("playersoundRef", this.$store.state.playersoundRef);
          if (this.$store.state.playersoundBlob) {
            formData.append("playersoundBlob", this.$store.state.playersoundBlob);
          }
          formData.append("avatarBlob", this.$store.state.avatarBlob);
          axios
            .put("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/username/" + this.name, formData, {
              "Content-Type": undefined,
              headers: {
                "x-supersketchysecret": secret,
              },
            })
            .then(
              function() {
                this.drawAvatar = false;
                this.drawError = false;
                this.$store.commit("Secret", { value: secret, name: this.name, vue: this });

                this.$router.push({
                  name: "party",
                  params: {
                    partyCode: this.$store.state.partyCode.toUpperCase(),
                    name: this.name,
                  },
                });
              }.bind(this)
            )
            .catch(
              function(err) {
                if (err.response) {
                  this.drawError = true;
                  this.drawAvatar = false;

                  if (err.response.status === 404) {
                    this.$notify({
                      group: "notifications",
                      type: "error",
                      position: "top center",
                      title: this.$getMessage("error.genericError"),
                      text: this.$getMessage("error.partyDoesNotExist"),
                    });
                  } else if (err.response.status === 406) {
                    this.$notify({
                      group: "notifications",
                      type: "error",
                      position: "top center",
                      title: this.$getMessage("error.genericError"),
                      text: this.$getMessage("error.unrecoverableParty"),
                    });
                  } else if (err.response.status === 416) {
                    this.$notify({
                      group: "notifications",
                      type: "error",
                      position: "top center",
                      title: this.$getMessage("error.genericError"),
                      text: this.$getMessage("error.maxPlayersReached"),
                    });
                  } else if (err.response.status === 423) {
                    this.$notify({
                      group: "notifications",
                      type: "error",
                      position: "top center",
                      title: this.$getMessage("error.genericError"),
                      text: this.$getMessage("error.gameInProgress"),
                    });
                  } else if (err.response.status === 409) {
                    this.$notify({
                      group: "notifications",
                      type: "error",
                      position: "top center",
                      title: this.$getMessage("error.genericError"),
                      text: this.$getMessage("error.sameName"),
                    });
                  } else {
                    this.$notify({
                      group: "notifications",
                      type: "error",
                      position: "top center",
                      title: this.$getMessage("error.genericError"),
                      text: err.response.data,
                    });
                  }
                }
              }.bind(this)
            );
        } else {
          this.$notify({
            group: "notifications",
            type: "error",
            position: "top center",
            title: this.$getMessage("error.joinDetailsNotFilledTitle"),
            text: this.$getMessage("error.joinDetailsNotFilled"),
          });
          this.drawError = true;
        }
      }
    },

    onInpMusicChange: function() {
      if (!this.musicCheckboxEnabled) {
        this.SoundManager.mute();
      } else {
        this.SoundManager.unmute();
      }
    },

    onQuitGame: function() {
      parent.postMessage({ type: "quit" }, "*");
    },

    onChangeToSettings: function() {
      this.$router.push({
        name: "settings",
      });
    },

    resizeVideoContainer: function() {
      setTimeout(
        function() {
          document.getElementsByClassName("videoContainer").forEach((element) => {
            element.style.width = window.innerWidth * 0.53 + "px";
          });
          this.$calcZoom();
        }.bind(this),
        1000
      );
    },

    checkClipInstalled: function() {
      this.$root.$on(
        "check-clip-installed-event",
        function(isInstalled) {
          if (!isInstalled) {
            this.$root.$emit("ClipInstallDialogShow");
          } else {
            parent.postMessage(
              {
                type: "runClip",
              },
              "*"
            );
            this.$root.$emit("showClipMonitor");
          }
        }.bind(this)
      );
      parent.postMessage({ type: "checkClipInstalled" }, "*");
    },
  },
};
</script>
