<template>
  <div>
    <div v-if="isSupported">
      <br />
      <label>{{ $t("hello.yourSound") }}</label>
      <div class="soundWrapper">
        <button class="soundButton" id="playButton1" @click="onPlaySound(1)">
          <svg>
            <circle cx="50%" cy="50%" r="40px"></circle>
          </svg>
          <img src="assets/img/sound_1.svg" alt="Sound 1" />
        </button>
        <button class="soundButton" id="playButton2" @click="onPlaySound(2)">
          <svg>
            <circle cx="50%" cy="50%" r="40px"></circle>
          </svg>
          <img src="assets/img/sound_2.svg" alt="Sound 2" />
        </button>
        <button class="soundButton" id="playButton3" @click="onPlaySound(3)">
          <svg>
            <circle cx="50%" cy="50%" r="40px"></circle>
          </svg>
          <img src="assets/img/sound_3.svg" alt="Sound 3" />
        </button>
        <audio id="sound1" :src="'/app/playersounds/' + this.aPlayersounds[1]" class="audio audio--hidden" controls />
        <audio id="sound2" :src="'/app/playersounds/' + this.aPlayersounds[2]" class="audio audio--hidden" controls />
        <audio id="sound3" :src="'/app/playersounds/' + this.aPlayersounds[3]" class="audio audio--hidden" controls />
      </div>
      <label>{{ $t("hello.recordSound") }}</label>
      <div class="waveform">
        <canvas class="waveformCanvas" id="recorderCanvas" @click="onClickCanvas"></canvas>
      </div>
      <div class="toolbar">
        <button class="recordButton recordButton--record" id="recordButton" @click="onRecord">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
          >
            <g transform="matrix(1,0,0,1,-1.75895,-2.84139)">
              <g transform="matrix(0.729392,0,0,0.729392,5.00626,6.08869)">
                <path
                  d="M12,16.5C14.481,16.5 16.5,14.481 16.5,12L16.5,6C16.5,3.519 14.481,1.5 12,1.5C9.519,1.5 7.5,3.519 7.5,6L7.5,12C7.5,14.481 9.519,16.5 12,16.5ZM8.5,6C8.5,4.07 10.07,2.5 12,2.5C13.93,2.5 15.5,4.07 15.5,6L15.5,12C15.5,13.93 13.93,15.5 12,15.5C10.07,15.5 8.5,13.93 8.5,12L8.5,6Z"
                  style="fill-rule:nonzero;"
                />
              </g>
              <g transform="matrix(0.729392,0,0,0.729392,5.00626,6.08869)">
                <path
                  d="M6,8.5C5.724,8.5 5.5,8.724 5.5,9L5.5,12C5.5,15.067 7.638,17.638 10.5,18.318L10.5,21.5L10,21.5C9.724,21.5 9.5,21.724 9.5,22C9.5,22.276 9.724,22.5 10,22.5L14,22.5C14.276,22.5 14.5,22.276 14.5,22C14.5,21.724 14.276,21.5 14,21.5L13.5,21.5L13.5,18.318C16.362,17.638 18.5,15.067 18.5,12L18.5,9C18.5,8.724 18.276,8.5 18,8.5C17.724,8.5 17.5,8.724 17.5,9L17.5,12C17.5,15.032 15.032,17.5 12,17.5C8.968,17.5 6.5,15.032 6.5,12L6.5,9C6.5,8.724 6.276,8.5 6,8.5ZM12.5,18.475L12.5,21.5L11.5,21.5L11.5,18.475C11.666,18.488 11.831,18.5 12,18.5C12.169,18.5 12.334,18.487 12.5,18.475Z"
                  style="fill-rule:nonzero;"
                />
              </g>
              <g transform="matrix(0.729392,0,0,0.729392,5.00626,6.08869)">
                <path
                  d="M11,12.5L13,12.5C13.276,12.5 13.5,12.276 13.5,12C13.5,11.724 13.276,11.5 13,11.5L11,11.5C10.724,11.5 10.5,11.724 10.5,12C10.5,12.276 10.724,12.5 11,12.5Z"
                  style="fill-rule:nonzero;"
                />
              </g>
              <g transform="matrix(0.729392,0,0,0.729392,5.00626,6.08869)">
                <path
                  d="M11,9.5L13,9.5C13.276,9.5 13.5,9.276 13.5,9C13.5,8.724 13.276,8.5 13,8.5L11,8.5C10.724,8.5 10.5,8.724 10.5,9C10.5,9.276 10.724,9.5 11,9.5Z"
                  style="fill-rule:nonzero;"
                />
              </g>
              <g transform="matrix(0.729392,0,0,0.729392,5.00626,6.08869)">
                <path
                  d="M11,6.5L13,6.5C13.276,6.5 13.5,6.276 13.5,6C13.5,5.724 13.276,5.5 13,5.5L11,5.5C10.724,5.5 10.5,5.724 10.5,6C10.5,6.276 10.724,6.5 11,6.5Z"
                  style="fill-rule:nonzero;"
                />
              </g>
            </g>
          </svg>
        </button>
        <button class="recordButton recordButton--play recordButton--disabled" id="playButton" @click="onPlay">
          <svg
            v-if="!isPlaying"
            width="100%"
            height="100%"
            viewBox="0 0 64 64"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
          >
            <g transform="matrix(1,0,0,1,-9.20105,-9.20105)">
              <g transform="matrix(0.712467,0,0,0.712467,18.4021,18.4021)">
                <rect x="0" y="-0" width="64" height="64" style="fill:none;" />
              </g>
              <g transform="matrix(0.712467,0,0,0.712467,18.4021,18.4021)">
                <path
                  d="M32,8C18.754,8 8,18.754 8,32C8,45.246 18.754,56 32,56C45.246,56 56,45.246 56,32C56,18.754 45.246,8 32,8ZM32,10C44.142,10 54,19.858 54,32C54,44.142 44.142,54 32,54C19.858,54 10,44.142 10,32C10,19.858 19.858,10 32,10Z"
                />
              </g>
              <g transform="matrix(0.712467,0,0,0.712467,18.4021,18.4021)">
                <path
                  d="M26.555,21.168C26.248,20.963 25.853,20.944 25.528,21.118C25.203,21.292 25,21.631 25,22L25,42C25,42.369 25.203,42.708 25.528,42.882C25.853,43.056 26.248,43.037 26.555,42.832L41.555,32.832C41.833,32.647 42,32.334 42,32C42,31.666 41.833,31.353 41.555,31.168L26.555,21.168ZM27,40.131L39.197,32L27,23.869L27,40.131Z"
                />
              </g>
            </g>
          </svg>
          <svg
            v-if="isPlaying"
            width="100%"
            height="100%"
            viewBox="0 0 64 64"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"
          >
            <g transform="matrix(1,0,0,1,-9.20105,-9.20105)">
              <g transform="matrix(0.712467,0,0,0.712467,18.4021,18.4021)">
                <rect x="0" y="-0" width="64" height="64" style="fill:none;" />
              </g>
              <g transform="matrix(0.712467,0,0,0.712467,18.4021,18.4021)">
                <path
                  d="M32,8C18.754,8 8,18.754 8,32C8,45.246 18.754,56 32,56C45.246,56 56,45.246 56,32C56,18.754 45.246,8 32,8ZM32,10C44.142,10 54,19.858 54,32C54,44.142 44.142,54 32,54C19.858,54 10,44.142 10,32C10,19.858 19.858,10 32,10Z"
                />
              </g>
            </g>
            <g transform="matrix(1,0,0,1,-0.0280948,0.477612)">
              <g transform="matrix(1,0,0,1,27.5891,1.6295)">
                <path d="M0.309,21.802L0.253,38.04" style="fill:none;stroke:black;stroke-width:1px;" />
              </g>
              <g transform="matrix(1,0,0,1,35.9052,1.57331)">
                <path d="M0.309,21.802L0.253,38.04" style="fill:none;stroke:black;stroke-width:1px;" />
              </g>
            </g>
          </svg>
        </button>
        <audio class="audio audio--hidden" id="audioPlayer" controls />
      </div>
    </div>
    <div v-if="!isSupported">{{ $t("hello.audioNotSupported") }}</div>
  </div>
</template>
<script>
import AudioRecorder from "audio-recorder-polyfill";

export default {
  name: "SoundRecorder",
  props: [],
  data: function() {
    return {
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      audioContext: new (window.AudioContext || window.webkitAudioContext)(),
      analyser: null,
      input: null,
      scriptProcessor: null,
      audioPlayer: null,

      recorderCanvas: null,
      recordButton: null,
      playButton: null,

      barWidth: 2,
      barGutter: 2,
      barColor: "#ced7db",
      bars: [],
      width: 0,
      height: 0,
      halfHeight: 0,
      drawing: false,
      recordTime: 2000,

      isRecording: false,
      isPlaying: false,
      isSupported: true,

      aPlayersounds: [
        "beer-open.wav",
        "blah.wav",
        "boing.wav",
        "crickets.wav",
        "goat.wav",
        "laughing.wav",
        "record-scratch.wav",
        "robot.wav",
        "sheep.wav",
        "slide.wav",
        "swaaaash.wav",
        "whoosh.wav",
        "yiih.wav",
        "yike.wav",
      ],
    };
  },
  mounted() {
    this.recorderCanvas = document.getElementById("recorderCanvas");
    this.recordButton = document.getElementById("recordButton");
    this.playButton = document.getElementById("playButton");
    this.audioPlayer = document.getElementById("audioPlayer");

    this.aPlayersounds = this.aPlayersounds.sort(() => 0.5 - Math.random());

    this.$store.commit("SelectedSound", this.aPlayersounds[0]);
    this.$store.commit("PlayersoundRef", this.aPlayersounds[0]);

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(
        function(stream) {
          this.mediaRecorder = new AudioRecorder(stream, {
            mimeType: "audio/wav",
          });
          this.input = this.audioContext.createMediaStreamSource(stream);
          this.scriptProcessor = this.audioContext.createScriptProcessor(2048, 1, 1);
          this.analyser = this.audioContext.createAnalyser();

          this.analyser.smoothingTimeConstant = 0.3;
          this.analyser.fftSize = 1024;

          var canvasContext = this.recorderCanvas.getContext("2d");

          this.width = this.recorderCanvas.offsetWidth;
          this.height = this.recorderCanvas.offsetHeight;
          this.halfHeight = this.recorderCanvas.offsetHeight / 2;

          canvasContext.canvas.width = this.width;
          canvasContext.canvas.height = this.height;

          this.input.connect(this.analyser);
          this.analyser.connect(this.scriptProcessor);
          this.scriptProcessor.connect(this.audioContext.destination);
          this.scriptProcessor.onaudioprocess = this.processInput;

          this.audioPlayer.addEventListener(
            "ended",
            function() {
              this.isPlaying = false;
              this.playButton.classList.remove("recordButton--active");
              this.stopRecording();
            }.bind(this)
          );
        }.bind(this)
      )
      .catch(
        function() {
          // The function is not supported or the URL is non https (iOS & macOS)
          this.isSupported = false;
        }.bind(this)
      );

    //make an array with 50x the number 4
    var tempBar = [];
    for (var i = 0; i < 50; i++) {
      tempBar.push(4);
    }

    this.renderBars(tempBar);
  },
  beforeDestroy() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    }
  },
  methods: {
    onRecord: function() {
      if (!this.isRecording) {
        setTimeout(
          function() {
            this.renderCountdown(3);
          }.bind(this),
          0
        );

        setTimeout(
          function() {
            this.renderCountdown(2);
          }.bind(this),
          1000
        );

        setTimeout(
          function() {
            this.renderCountdown(1);
          }.bind(this),
          2000
        );

        setTimeout(
          function() {
            if (this.bars.length > 0) {
              // Reset the recording
              this.audioChunks = [];
              this.bars = [];
            }

            this.mediaRecorder.start();
            this.recordButton.classList.add("recordButton--active");
            this.isRecording = true;

            this.mediaRecorder.addEventListener(
              "dataavailable",
              function(event) {
                this.audioChunks.push(event.data);

                this.audioPlayer.setAttribute("src", URL.createObjectURL(event.data));
              }.bind(this)
            );

            this.mediaRecorder.addEventListener(
              "stop",
              function() {
                this.stopRecording();
              }.bind(this)
            );

            setTimeout(
              function() {
                this.mediaRecorder.stop();
              }.bind(this),
              this.recordTime
            );
          }.bind(this),
          3000
        );
      } else {
        this.mediaRecorder.stop();
        this.audioPlayer.play();
      }
    },

    onPlay: function() {
      this.isPlaying = true;
      this.audioPlayer.play();
      this.playButton.classList.add("recordButton--active");

      this.onClickCanvas();
    },

    onPlaySound: function(iIndex) {
      document.getElementById("sound" + iIndex).play();

      this.stopPlayer();

      this.clearSelectables();
      document.getElementById("playButton" + iIndex).classList.add("selectedSound");

      this.$store.commit("SelectedSound", this.aPlayersounds[iIndex]);
      this.$store.commit("PlayersoundRef", this.aPlayersounds[iIndex]);
    },

    clearSelectables: function() {
      document.getElementById("playButton1").classList.remove("selectedSound");
      document.getElementById("playButton2").classList.remove("selectedSound");
      document.getElementById("playButton3").classList.remove("selectedSound");
      document.getElementById("recorderCanvas").classList.remove("selectedCanvas");
    },

    onClickCanvas: function() {
      if(this.audioChunks.length === 0) {
        this.recordButton.classList.add("shakeControl");
        setTimeout(
          function() {
            this.recordButton.classList.remove("shakeControl");
          }.bind(this),
          500
        );
        return;
      }

      this.clearSelectables();
      document.getElementById("recorderCanvas").classList.add("selectedCanvas");
      this.$store.commit("SelectedSound", "Blob");
      this.$store.commit("PlayersoundRef", "");
    },

    stopRecording: function() {
      this.isRecording = false;

      this.stopPlayer();
      this.playButton.classList.remove("recordButton--disabled");

      this.$store.commit("PlayersoundBlob", new Blob(this.audioChunks));
      this.$store.commit("SelectedSound", "Blob");
      this.$store.commit("PlayersoundRef", "");

      this.clearSelectables();
      document.getElementById("recorderCanvas").classList.add("selectedCanvas");
    },

    stopPlayer: function() {
      this.recordButton.classList.remove("recordButton--active");
      this.playButton.classList.remove("recordButton--active");

      this.audioPlayer.pause();
      this.audioPlayer.currentTime = 0;
      this.isPlaying = false;
    },

    processInput: function() {
      if (this.isRecording === true) {
        const array = new Uint8Array(this.analyser.frequencyBinCount);

        this.analyser.getByteFrequencyData(array);
        this.bars.push(this.getAverageVolume(array));

        if (this.bars.length <= Math.floor(this.width / (this.barWidth + this.barGutter))) {
          this.renderBars(this.bars);
        } else {
          this.renderBars(this.bars.slice(this.bars.length - Math.floor(this.width / (this.barWidth + this.barGutter))), this.bars.length);
        }
      }
    },

    getAverageVolume: function(array) {
      const length = array.length;

      let values = 0;
      let i = 0;

      for (; i < length; i++) {
        values += array[i];
      }

      return values / length;
    },

    renderBars: function(bars) {
      var canvasContext = this.recorderCanvas.getContext("2d");
      var magicOffset = 8;

      window.requestAnimationFrame(
        function() {
          canvasContext.clearRect(0, 0, this.width, this.height);

          bars.forEach((bar, index) => {
            canvasContext.fillStyle = this.barColor;
            canvasContext.fillRect(
              magicOffset + index * (this.barWidth + this.barGutter),
              this.halfHeight,
              this.barWidth,
              this.halfHeight * (bar / 100)
            );
            canvasContext.fillRect(
              magicOffset + index * (this.barWidth + this.barGutter),
              this.halfHeight - this.halfHeight * (bar / 100),
              this.barWidth,
              this.halfHeight * (bar / 100)
            );
          });
        }.bind(this)
      );
    },

    renderCountdown(sCountdown) {
      var canvasContext = this.recorderCanvas.getContext("2d");

      window.requestAnimationFrame(
        function() {
          canvasContext.clearRect(0, 0, this.width, this.height);
          canvasContext.font = "30px Atkinson Hyperlegible";
          canvasContext.fillStyle = "#ced7db";
          canvasContext.fillText(sCountdown, this.width / 2 - 7, this.height / 2 + 7);
        }.bind(this)
      );
    },
  },
};
</script>
<style scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.waveform {
  position: relative;
  padding: 1rem 4rem 1rem 4rem;
}

.waveformCanvas {
  width: 11rem;
  height: 7.5rem;
  margin: auto;
  border: 2px solid;
  image-rendering: auto;
}

.toolbar {
  text-align: center;
}

.recordButton {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  margin: 0 1rem;
  padding: 0;
  background: #ced7db;
  border-radius: 50%;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.5);
  font-size: 4rem;
  cursor: pointer;
}

.recordButton--record::before {
  animation: spin 4s linear infinite;
  transition: opacity 0.4s ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 4rem;
  height: 4rem;
  margin: -0.4rem;
  padding: -0.4rem;
  background: transparent;
  opacity: 0;
  border-radius: 50%;
  border: 0.4rem solid rgba(46, 116, 143, 0.8);
  border-top-color: rgba(46, 116, 143, 0);
}

.soundButton {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 4rem;
  margin: 0 1rem;
  padding: 0;
  background: #ced7db;
  border-radius: 50%;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.5);
  font-size: 4rem;
  cursor: pointer;
}

.soundButton svg {
  display: none;
}

.selectedSound svg {
  position: absolute;
  display: block !important;
  animation: spin 10s linear infinite;
  position: absolute;
  left: -0.5em;
  top: -0.5em;
  width: 2em;
  height: 2em;
  z-index: 999;
}

.selectedSound circle {
  stroke-width: 8;
  stroke-dasharray: 12, 16;
  fill: none;
  stroke: #ced7db;
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

.selectedCanvas {
  border: #ced7db 0px solid;
  animation: borderAnimation 1s infinite linear;
  background-image: repeating-linear-gradient(0deg, #ced7db, #ced7db 10px, transparent 10px, transparent 20px, #ced7db 20px),
    repeating-linear-gradient(90deg, #ced7db, #ced7db 10px, transparent 10px, transparent 20px, #ced7db 20px),
    repeating-linear-gradient(180deg, #ced7db, #ced7db 10px, transparent 10px, transparent 20px, #ced7db 20px),
    repeating-linear-gradient(270deg, #ced7db, #ced7db 10px, transparent 10px, transparent 20px, #ced7db 20px);
  background-size: 5px calc(100% + 20px), calc(100% + 20px) 5px, 5px calc(100% + 20px), calc(100% + 20px) 5px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

@keyframes borderAnimation {
  from {
    background-position: 0 0, -20px 0, 100% -20px, 0 100%;
  }

  to {
    background-position: 0 -20px, 0 0, 100% 0, -20px 100%;
  }
}

.recordButton--record.recordButton--active {
  background: #ed6a5f;
  color: #ced7db;
}

.recordButton--record.recordButton--active::before {
  opacity: 1;
}

.button--play {
  width: 5rem;
  height: 5rem;
  font-size: 2rem;
  vertical-align: inherit;
}

.recordButton--play.recordButton--active {
  background: #49f1d5;
  color: #ced7db;
}

.recordButton--disabled {
  opacity: 0.2;
  pointer-events: none;
  cursor: not-allowed;
}

.audio {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: 0;
}

.soundWrapper {
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>
