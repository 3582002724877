<template>
  <video autoplay muted loop id="backgroundVideo" class="backgroundVideo" disablePictureInPicture>
    <source src="/app/assets/video/bg.mp4" type="video/mp4" />
  </video>
</template>
<script>
import StateMachine from "javascript-state-machine";

export default {
  name: "BackgroundCanvas",
  props: ["type"],
  data: function() {
    return {
      fsm: null,
      currentTime: 0,
      videoSegments: [
        { name: "A", from: 1.3, to: 8.0 },
        { name: "B", from: 11.3, to: 18.0 },
        { name: "C", from: 22.3, to: 39.0 },
        { name: "D", from: 39.5, to: 49.5 },
      ],
      respectState: true,
    };
  },
  mounted() {
    this.fsm = new StateMachine({
      init: "A",
      transitions: [
        {
          name: "change",
          from: ["A"],
          to: "B",
        },
        {
          name: "change",
          from: ["B"],
          to: "C",
        },
        {
          name: "change",
          from: ["C"],
          to: "D",
        },
        {
          name: "change",
          from: ["D"],
          to: "A",
        },
      ],
    });

    var backgroundVideo = document.getElementById("backgroundVideo");

    backgroundVideo.addEventListener(
      "timeupdate",
      function() {
        this.currentTime = backgroundVideo.currentTime;
        if (this.currentTime > this.videoSegments[this.videoSegments.findIndex((e) => e.name === this.fsm.state)].to && this.respectState === true) {
          this.currentTime = this.videoSegments[this.videoSegments.findIndex((e) => e.name === this.fsm.state)].from;
          backgroundVideo.currentTime = this.currentTime;
        }
        if (
          this.respectState === false &&
          this.currentTime > this.videoSegments[this.videoSegments.findIndex((e) => e.name === this.fsm.state)].from
        ) {
          this.respectState = true;
        }
      }.bind(this)
    );

    this.$root.$on("backgroundChange", this.changeToNext);

    backgroundVideo.play();
  },
  methods: {
    changeToNext: function() {
      var backgroundVideo = document.getElementById("backgroundVideo");
      backgroundVideo.currentTime = this.videoSegments[this.videoSegments.findIndex((e) => e.name === this.fsm.state)].to;
      this.fsm.change();
      this.respectState = false;
    },
  },
};
</script>
<style scoped>
.backgroundVideo {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
</style>
