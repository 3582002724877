<template>
  <div>
    <div class="ink shape03 topContainer" v-if="question">
      <div class="questionDiv">
        <strong class="questionText">{{ question }}</strong>
      </div>
      <div>
        <button
          v-if="renderAdditionalButtons == true"
          :disabled="$store.state.numberQuestionRedraws <= 0"
          @click="onRedrawQuestion()"
          class="pure-button drawAreaButton"
        >
          <span class="buttonContent">
            <object data="/app/assets/img/redraw.svg" type="image/svg+xml"></object>
            <span v-if="$store.state.numberQuestionRedraws >= 0">
              {{
                $t("party.redraw", {
                  numberQuestionRedraws: $store.state.numberQuestionRedraws,
                })
              }}
            </span>
            <span v-if="$store.state.numberQuestionRedraws === -1">
              {{
                $t("party.redraw", {
                  numberQuestionRedraws: 0,
                })
              }}
            </span>
          </span>
        </button>
      </div>
    </div>
    <div class="bottomContainer">
      <div @touchstart="onColorChange" @click="onColorChange" class="colorPickerWrapper">
        <div :id="colorPicker1Id" class="colorPicker" v-bind:class="[activecolorInputId == colorPicker1Id ? 'colorPickerActive' : '']"></div>
      </div>
      <div @touchstart="onColorChange" @click="onColorChange" class="colorPickerWrapper" v-if="!onlyBW">
        <div :id="colorPicker2Id" class="colorPicker" v-bind:class="[activecolorInputId == colorPicker2Id ? 'colorPickerActive' : '']"></div>
      </div>
      <div @touchstart="onColorChange" @click="onColorChange" class="colorPickerWrapper">
        <object v-if="noEraser" data="/app/assets/img/forbidden.svg" type="image/svg+xml" class="eraserForbidden"></object>
        <div :id="colorPicker3Id" class="colorPicker" v-bind:class="[activecolorInputId == colorPicker3Id ? 'colorPickerActive' : '']"></div>
      </div>
      <button v-if="renderAdditionalButtons == true" class="pure-button drawAreaButton" id="btnSubmitDrawing" @click="onSubmitDrawing(true)">
        {{ $t("party.doneDrawing") }}
      </button>
    </div>
    <div class="DrawAreaParent NoHighlights" :id="id">
      <canvas id="DrawAreaCanvas" class="DrawAreaCanvas" width="475" height="590"></canvas>
      <div v-if="lightsOut" class="lightsOut"></div>
      <canvas id="DrawAreaCanvasCache" class="DrawAreaCanvasCache" width="475" height="590"></canvas>
    </div>
  </div>
</template>
<script>
import getStroke from "perfect-freehand";

export default {
  name: "DrawArea",
  props: ["type", "renderAdditionalButtons", "round", "question", "noEraser", "onlyBW", "lightsOut"],
  data: function() {
    return {
      handlers: {
        pointerdown: this.handlePointerDown,
        pointermove: this.handlePointerMove,
      },

      drawing: null,
      drawingBlob: null,
      id: null,
      colorPicker1Id: null,
      colorPicker2Id: null,
      colorPicker3Id: null,
      canvasRef: null,
      canvasContextRef: null,
      canvasCacheRef: null,
      canvasCacheContextRef: null,
      activecolorInput: null,
      activecolorInputId: null,
      colorInput1: null,
      colorInput2: null,
      colorInput3: null,
      apngFrames: [],
      zoomScale: 1,
      possibleColors: [
        ["#68E074", "#ED24AD", "#E0AA41", "#4592EB", "#8730EB"],
        ["#8C96F0", "#36EBD6", "#CA29E3", "#F2B818", "#66D97D"],
        ["#EBCD73", "#30E392", "#DB30D3", "#B473F5", "#DAB9B7"],
        ["#D571EB", "#F0754E", "#00F576", "#0AE4EB", "#C6EFCE"],
        ["#B448DB", "#76DF8B", "#A9FC49", "#F0AA71", "#57CFF7"],
        ["#ED05B7", "#F55656", "#D3DB90", "#2FF761", "#4DA8F7"],
        ["#5AF25F", "#4F56DB", "#DB5F07", "#5DD5E8", "#A170E6"],
        ["#B471E3", "#61C9E8", "#FACF73", "#DB4662", "#3B24ED"],
        ["#E3722B", "#F50C14", "#7272F2", "#9411F2", "#7DDB46"],
        ["#F536D5", "#F5A947", "#61CFFA", "#0C04DB", "#AAF55B"],
        ["#E6A853", "#6673E3", "#ED9511", "#6FFC7B", "#E342B0"],
        ["#05E5FA", "#7A30E3", "#C975EB", "#A356F5", "#6DDEA7"],
        ["#7F64D9", "#E35A05", "#FA2D86", "#68E4FC", "#A2E332"],
        ["#7483F2", "#FC0D7D", "#E85F5F", "#3458D9", "#2BB6ED"],
        ["#1478FA", "#E302C1", "#D98366", "#60DBAC", "#F0D36C"],
        ["#DEA731", "#D9E80C", "#BE33F5", "#ED3990", "#0D25D9"],
        ["#D777ED", "#20FA94", "#78EAF5", "#E843CA", "#D8F03E"],
        ["#57A1DE", "#0CBEEB", "#8B59E3", "#91DE5B", "#6446EB"],
        ["#46DBB4", "#F2A229", "#E742ED", "#0DE8FC", "#D9821E"],
        ["#6DF7F7", "#AC5FE3", "#E3FA70", "#F7614A", "#41E099"],
        ["#EB0744", "#FAE673", "#37D7E6", "#083DFC", "#6E6CE6"],
        ["#779EF2", "#951DDB", "#FA6F1E", "#0DDBB9", "#ED1F56"],
        ["#C2FA1B", "#FCBB74", "#F73E14", "#E69232", "#E37566"],
        ["#A254F0", "#D9CE04", "#F24B99", "#E6986E", "#8539DB"],
        ["#CA0BDB", "#FA55CE", "#F76A7D", "#B5DB39", "#E0201D"],
        ["#D861F2", "#4E63F2", "#55D934", "#FC0366", "#41B0D9"],
        ["#FAFA75", "#D9366A", "#E05738", "#F233B2", "#16F5D0"],
        ["#4956DE", "#E68C73", "#EBBB4D", "#6884F2", "#410DFC"],
        ["#EBA431", "#E0DD2F", "#7449EB", "#16D1F2", "#EB0EB7"],
      ],

      points: [],
      pathData: null,
      isDrawing: false,

      normalStrokeOptions: {
        size: 10, // * devicePixelRatio,
        thinning: 0.5,
        smoothing: 0.5,
        streamline: 0.5,
        easing: (t) => t,
        simulatePressure: true,
        last: true,
        start: {
          cap: true,
          taper: 0,
          easing: (t) => t,
        },
        end: {
          cap: true,
          taper: 0,
          easing: (t) => t,
        },
      },
      eraserStrokeOptions: {
        size: 35, // * devicePixelRatio,
        thinning: 0,
        smoothing: 0,
        streamline: 0,
        easing: (t) => t,
        simulatePressure: true,
        last: true,
        start: {
          cap: true,
          taper: 0,
          easing: (t) => t,
        },
        end: {
          cap: true,
          taper: 0,
          easing: (t) => t,
        },
      },
    };
  },
  created() {
    this.id = "DrawArea" + this._uid;
    this.activecolorInputId = this.colorPicker1Id = "colorPicker1" + this._uid;
    this.colorPicker2Id = "colorPicker2" + this._uid;
    this.colorPicker3Id = "colorPicker3" + this._uid;

    if (this.type === "avatar") {
      this.maxFrames = 45;
    } else if (this.type === "drawing") {
      this.maxFrames = this.$isiOS === true ? 25 : 45;
    }
  },
  async mounted() {
    this.$registerZoomListener(this);
    this.$nextTick(
      function() {
        this.$calcZoom();
      }.bind(this)
    );

    this.canvasRef = document.getElementById("DrawAreaCanvas");
    this.canvasContextRef = this.canvasRef.getContext("2d");
    this.canvasContextRef.imageSmoothingEnabled = false;

    this.canvasCacheRef = document.getElementById("DrawAreaCanvasCache");
    this.canvasCacheContextRef = this.canvasCacheRef.getContext("2d");

    this.canvasRef.addEventListener("pointerdown", this.handlePointerDown);
    this.canvasRef.addEventListener("pointermove", this.handlePointerMove);
    this.canvasRef.addEventListener("pointerup", this.handlePointerUp);
    this.canvasRef.addEventListener("pointerenter", this.handlePointerEnter);
    this.canvasRef.addEventListener("pointerleave", this.handlePointerLeave);

    var drawingColors = await this.SettingsManager.getCookie("drawingColors", this.$isTauri);
    this.colorInput3 = "#ffffff";
    if (!drawingColors) {
      var i = this.$randomIntFromInterval(0, this.possibleColors.length - 1);
      var j1 = this.$randomIntFromInterval(0, 4);
      var j2;
      do {
        j2 = this.$randomIntFromInterval(0, 4);
      } while (j1 === j2);

      this.colorInput1 = this.possibleColors[i][j1];
      this.colorInput2 = this.possibleColors[i][j2];

      this.SettingsManager.saveCookie("drawingColors", `${this.possibleColors[i][j1]}|${this.possibleColors[i][j2]}`, new Date(new Date().getTime() + 15 * 60 * 1000), this.$isTauri);
    } else {
      this.colorInput1 = drawingColors.split("|")[0];
      this.colorInput2 = drawingColors.split("|")[1];
    }

    if (this.onlyBW) {
      this.activecolorInput = this.colorInput1;
      this.colorInput1 = "#000000";
    }

    this.activecolorInput = this.colorInput1;
    document.getElementById(this.colorPicker1Id).style.backgroundColor = this.colorInput1.toString("#rrggbb");
    if (!this.onlyBW) {
      document.getElementById(this.colorPicker2Id).style.backgroundColor = this.colorInput2.toString("#rrggbb");
    }
    document.getElementById(this.colorPicker3Id).style.backgroundColor = "#ffffff";

    this.$registerZoomListener(this);
  },
  beforeDestroy() {
    this.clearCanvas();
    this.$removeZoomListener(this);
  },
  methods: {
    handlePointerDown: function(e) {
      this.isDrawing = true;
      e.target.setPointerCapture(e.pointerId);

      const rect = this.canvasRef.getBoundingClientRect();
      var zoomScaleX = 475 / rect.width;
      var zoomScaleY = 590 / rect.height;

      const x = (e.clientX - rect.left) * zoomScaleX;
      const y = (e.clientY - rect.top) * zoomScaleY;
      this.points = [[x, y, e.pressure]];

      this.drawCanvas();
    },

    handlePointerMove: function(e) {
      if (!this.isDrawing) return;
      if (e.buttons !== 1) return;

      e.preventDefault();

      const rect = this.canvasRef.getBoundingClientRect();
      var zoomScaleX = 475 / rect.width;
      var zoomScaleY = 590 / rect.height;

      var x = (e.clientX - rect.left) * zoomScaleX;
      var y = (e.clientY - rect.top) * zoomScaleY;
      this.points = [...(this.points ?? []), [x, y, e.pressure]];

      this.drawCanvas();
    },

    handlePointerUp: function(e) {
      e.preventDefault();
      this.isDrawing = false;
      this.points = [];

      this.copyCanvasToCache();

      if (!this.points) return;
      this.drawCanvas();
      //ended
    },
    handlePointerEnter: function(e) {
      if (e.buttons === 1) {
        this.handlePointerDown(e);
      }
    },
    handlePointerLeave: function(e) {
      if (!this.isDrawing) return;
      this.handlePointerUp(e);
    },

    copyCanvasToCache: function() {
      this.canvasCacheContextRef.drawImage(this.canvasRef, 0, 0);
    },

    loadCacheToCanvas: function() {
      this.canvasContextRef.drawImage(this.canvasCacheRef, 0, 0);
    },

    drawCanvas: function() {
      var option = this.activecolorInputId === this.colorPicker3Id ? this.eraserStrokeOptions : this.normalStrokeOptions;

      this.pathData = this.getSvgPathFromStroke(getStroke(this.points, option));
      const path2d = new Path2D(this.pathData);
      this.canvasContextRef.fillStyle = this.activecolorInput;
      this.canvasContextRef.clearRect(0, 0, this.canvasRef.width, this.canvasRef.height);
      this.loadCacheToCanvas();
      this.canvasContextRef.fill(path2d);

      this.update();
    },

    getSvgPathFromStroke: function(stroke) {
      if (!stroke.length) return "";

      const d = stroke.reduce(
        (acc, [x0, y0], i, arr) => {
          const [x1, y1] = arr[(i + 1) % arr.length];
          acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
          return acc;
        },
        ["M", ...stroke[0], "Q"]
      );

      d.push("Z");
      return d.join(" ");
    },

    onColorChange: function(oEvent) {
      if (
        oEvent.srcElement.id.indexOf(this.colorPicker1Id) > -1 ||
        (oEvent.srcElement.firstChild && oEvent.srcElement.firstChild.id.indexOf(this.colorPicker1Id) > -1)
      ) {
        this.activecolorInput = this.colorInput1;
        this.activecolorInputId = this.colorPicker1Id;
      } else if (
        oEvent.srcElement.id.indexOf(this.colorPicker2Id) > -1 ||
        (oEvent.srcElement.firstChild && oEvent.srcElement.firstChild.id.indexOf(this.colorPicker2Id) > -1)
      ) {
        this.activecolorInput = this.colorInput2;
        this.activecolorInputId = this.colorPicker2Id;
      } else if (
        oEvent.srcElement.id.indexOf(this.colorPicker3Id) > -1 ||
        (oEvent.srcElement.firstChild && oEvent.srcElement.firstChild.id.indexOf(this.colorPicker3Id) > -1)
      ) {
        if (this.noEraser) {
          return;
        }
        this.activecolorInput = this.colorInput3;
        this.activecolorInputId = this.colorPicker3Id;
      }
    },

    clearCanvas: function() {
      this.points = [];
      this.canvasContextRef.clearRect(0, 0, this.canvasRef.width, this.canvasRef.height);
      this.recycleAllFrames();
      this.apngFrames = [];
    },

    recycleAllFrames: function() {
      for (let i = 0; i < this.apngFrames.length; i++) {
        const frame = this.apngFrames[i];
        frame.ctx.canvas.width = frame.ctx.canvas.height = 0;
        this.$recycledCanvases.push(frame.ctx);
      }
    },

    update: function() {
      var destCanvas = null;
      var destCanvasContext = null;
      if (this.$recycledCanvases.length === 0) {
        destCanvas = document.createElement("CANVAS");
        destCanvasContext = destCanvas.getContext("2d");
      } else {
        destCanvasContext = this.$recycledCanvases.splice(0, 1)[0];
      }

      destCanvasContext.canvas.width = this.canvasRef.width;
      destCanvasContext.canvas.height = this.canvasRef.height;
      destCanvasContext.clearRect(0, 0, this.canvasRef.width, this.canvasRef.height);
      destCanvasContext.drawImage(this.canvasRef, 0, 0);

      this.apngFrames.push({
        ctx: destCanvasContext,
        timestamp: new Date().getTime(),
      });

      this.compressFrames();
    },

    exportAPNG: function() {
      if (this.type === "drawing") {
        this.$emit("onSendDoneDrawing");
      }

      var oStartTime = new Date();

      return new Promise(
        function(resolve, reject) {
          if (this.apngFrames.length === 0) {
            reject("no_frames_error");
          }

          var apngBuffers = [];
          for (let i = 0; i < this.apngFrames.length; i++) {
            const frame = this.apngFrames[i];
            var buffer = {
              timestamp: frame.timestamp,
              data: frame.ctx.getImageData(0, 0, this.canvasRef.width, this.canvasRef.height).data.buffer,
            };
            if (!this.$isCanvasContextBlank(frame.ctx)) {
              apngBuffers.push(buffer);
            }
          }
          if (apngBuffers.length > 0) {
            this.canvasRef.toBlob(
              function(blob) {
                this.drawingBlob = blob;
                if (this.drawingBlob) {
                  var aJustFrames = [];
                  apngBuffers.forEach((f) => aJustFrames.push(f.data));
                  var oMessage = {
                    width: this.canvasRef.width,
                    height: this.canvasRef.height,
                    maxFrames: this.maxFrames,
                  };
                  Object.assign(oMessage, aJustFrames);

                  this.drawingWorker.send(oMessage, aJustFrames).then((reply) => {
                    this.drawingBlob = reply;
                    var oResolveObject = {
                      oBlob: this.drawingBlob,
                      oPerformanceInfo: {
                        useragent: navigator.userAgent,
                        duration: new Date().getTime() - oStartTime,
                      },
                    };
                    this.SettingsManager.removeCookie("drawingColors", this.$isTauri);
                    resolve(oResolveObject);
                  });
                }
              }.bind(this)
            );
          } else {
            reject("no_frames_error");
          }
        }.bind(this)
      );
    },

    compressFrames: function() {
      while (this.apngFrames.length > this.maxFrames) {
        var minDiff = 9999;
        var minDiffIndex = null;
        for (let i = 0; i < this.apngFrames.length; i++) {
          if (this.apngFrames[i + 1] && this.apngFrames[i]) {
            var diff = this.apngFrames[i + 1].timestamp - this.apngFrames[i].timestamp;
            if (diff < minDiff) {
              minDiff = diff;
              minDiffIndex = i + 1;
            }
          }
        }
        var rCanvas = this.apngFrames.splice(minDiffIndex, 1)[0].ctx;
        rCanvas.canvas.width = rCanvas.canvas.height = 0;
        this.$recycledCanvases.push(rCanvas);
      }
    },

    onRedrawQuestion: function() {
      this.$emit("onRedrawQuestion");
    },

    onSubmitDrawing: function() {
      this.$emit("onSubmitDrawing", true);
    },
  },
};
</script>
<style scoped>
.topContainer {
  display: flex;
  justify-content: space-between;
}

.questionText {
  overflow-wrap: break-word;
  font-size: larger;
}

.questionDiv {
  display: flex;
  align-items: center;
  justify-items: center;
}

.bottomContainer {
  display: flex;
  justify-content: space-evenly;
  max-width: 95%;
  margin: auto;
}

.colorPicker {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  display: inline-block;
  border: #5f5f5f 5px solid;
  margin: 10px;
}

.colorPickerActive {
  border: #4a4a4a 0px solid;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  animation: borderAnimation 1s infinite linear;
  background-image: repeating-linear-gradient(0deg, #4a4a4a, #4a4a4a 10px, transparent 10px, transparent 20px, #4a4a4a 20px),
    repeating-linear-gradient(90deg, #4a4a4a, #4a4a4a 10px, transparent 10px, transparent 20px, #4a4a4a 20px),
    repeating-linear-gradient(180deg, #4a4a4a, #4a4a4a 10px, transparent 10px, transparent 20px, #4a4a4a 20px),
    repeating-linear-gradient(270deg, #4a4a4a, #4a4a4a 10px, transparent 10px, transparent 20px, #4a4a4a 20px);
  background-size: 5px calc(100% + 20px), calc(100% + 20px) 5px, 5px calc(100% + 20px), calc(100% + 20px) 5px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

@keyframes borderAnimation {
  from {
    background-position: 0 0, -20px 0, 100% -20px, 0 100%;
  }

  to {
    background-position: 0 -20px, 0 0, 100% 0, -20px 100%;
  }
}

.colorPickerWrapper {
  display: inline-block;
}

.drawAreaButton {
  vertical-align: top;
  min-height: 60px;
  font-size: larger;
}

#btnSubmitDrawing {
  color: lightgreen;
}

.DrawAreaCanvas {
  image-rendering: optimizeSpeed;
  width: 475px;
  height: 590px;
}

.DrawAreaCanvasCache {
  display: none;
}

.DrawAreaParent {
  text-align: center;
  touch-action: none;
}

.DrawAreaParent canvas {
  border: #b0bec5 5px solid;
  background-color: white;
}

.eraserForbidden {
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 2;
  cursor: not-allowed;
}

.buttonContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightsOut {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 475px;
  height: 590px;
  margin: 0.25em;
  margin-bottom: 2.25em;
  animation: flickeringLights 8s forwards;
  pointer-events: none;
}

@keyframes flickeringLights {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  10% {
    background-color: rgba(0, 0, 0, 0.15);
  }
  20% {
    background-color: rgba(0, 0, 0, 0.35);
  }
  30% {
    background-color: rgba(0, 0, 0, 0.4);
  }
  40% {
    background-color: rgba(0, 0, 0, 0.35);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.6);
  }
  60% {
    background-color: rgba(0, 0, 0, 0.5);
  }
  70% {
    background-color: rgba(0, 0, 0, 0.75);
  }
  80% {
    background-color: rgba(0, 0, 0, 0.8);
  }
  90% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  100% {
    background-color: rgba(0, 0, 0, 1);
  }
}
</style>
