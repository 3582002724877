<template>
  <div class="pure-u-1-1 ink shape04">
    <h2>{{ $t("serverbrowser.title") }}</h2>
    <v-table
      :data="publiclist"
      :currentPage.sync="publiclistPage"
      :pageSize="8"
      @totalPagesChanged="publiclistTotalPages = $event"
      class="pure-table centerSelf textAlignReset"
    >
      <thead slot="head">
        <th id="publicPartyId">{{ $t("serverbrowser.publicPartyId") }}</th>
        <th id="description">{{ $t("serverbrowser.description") }}</th>
        <th id="numberPlayersInLobby">{{ $t("serverbrowser.numberPlayersInLobby") }}</th>
        <th id="langu">{{ $t("serverbrowser.langu") }}</th>
        <th id="numberQuestionRedraws">{{ $t("serverbrowser.numberQuestionRedraws") }}</th>
        <th id="numberRounds">{{ $t("serverbrowser.numberRounds") }}</th>
        <th id="join"></th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.text">
          <td>{{ row.publicPartyId }}</td>
          <td>{{ row.description }}</td>
          <td>{{ row.numberPlayersInLobby }} / {{ row.maxPlayers }}</td>
          <td>{{ languagesJson.find((el) => el.code === row.langu).name }}</td>
          <td>{{ row.numberQuestionRedraws }}</td>
          <td>{{ row.numberRounds }}</td>
          <td>
            <router-link :to="'watch/' + row.publicPartyId">{{
              $t("hello.join")
            }}</router-link>
          </td>
        </tr>
        <tr v-if="publiclist.length == 0">
          <td>{{ $t("serverbrowser.noGames") }}</td>
        </tr>
      </tbody>
    </v-table>
    <smart-pagination
      :currentPage.sync="publiclistPage"
      :totalPages="publiclistTotalPages"
      :maxPageLinks="1"
    />
    <br />
    <button class="pure-button" @click="$router.back()">
      {{ $t("hello.back") }}
    </button>
    <button class="pure-button" @click="onRefresh" :disabled="isInCooldown">
      {{ $t("serverbrowser.refresh") }}
    </button>
  </div>
</template>
<script>
import axios from "axios";
import languagesJson from "../data/languages.json";

export default {
  props: [],
  name: "ServerBrowser",
  components: {},
  data: function() {
    return {
      publiclist: [],
      publiclistPage: 1,
      publiclistTotalPages: 0,
      languagesJson: languagesJson,
      refreshCooldown: 3,
      isInCooldown: false,
      autoRefresh: null,
    };
  },
  mounted() {
    this.contentRefresh();

    this.autoRefresh = setInterval(
      function() {
        this.contentRefresh();
      }.bind(this),
      15000
    );
  },
  beforeDestroy() {
    clearInterval(this.autoRefresh);
  },
  methods: {
    contentRefresh: function() {
      axios
        .get("/api/party/publiclist", {
          headers: {
            "x-supersketchyownersecret": this.$store.state
              .superSketchyOwnerSecret,
          },
        })
        .then((resp) => {
          this.publiclist = resp?.data;
          if (!this.publiclist) {
            this.publiclist = [];
          }
        });
    },

    onRefresh: function() {
      this.contentRefresh();
      this.isInCooldown = true;
      setTimeout(
        function() {
          this.isInCooldown = false;
        }.bind(this),
        this.refreshCooldown * 1000
      );
    },
  },
};
</script>

<style scoped></style>
