<template>
  <div class="pure-u-1-1 centerContent">
    <div v-bind:class="[$store.state.username === partyHost ? 'ink shape06' : '']">
      <div v-bind:class="[$store.state.playType == 'watch' ? 'noWrap' : '']">
        <div class="centerContent">
          <div class="pure-u-1-4 ink shape02 partyLobbyContainer" v-if="$store.state.playType == 'watch'">
            <h2>{{ $t("hello.reactionGuide") }}</h2>
            <div class="">
              <p><IconDisplay :icon="reactionList[0]"></IconDisplay>&nbsp;{{ $t("reactionTitles.low-effort") }}</p>
              <p><IconDisplay :icon="reactionList[1]"></IconDisplay>&nbsp;{{ $t("reactionTitles.nice") }}</p>
              <p><IconDisplay :icon="reactionList[2]"></IconDisplay>&nbsp;{{ $t("reactionTitles.licensed-fool") }}</p>
            </div>
          </div>
          <div
            v-bind:class="[
              $store.state.username === partyHost ? 'pure-u-1-1' : 'pure-u-1-4 ink shape01 partyLobbyContainer partyLobbyContainerMiddle',
              $store.state.playType != 'watch' ? 'noMargin partyLobbyContainerMiddle' : '',
            ]"
          >
            <h2>{{ $t("party.partyLobby") }} 🎉</h2>
            <p class="partyLobbyParagraphMiddle">
              {{ users.length }}
              {{
                $t("party.playersJoined", {
                  numberWatchModes: numberWatchModes,
                  numberAIWatchModes: numberAIWatchModes
                })
              }}
            </p>
            <p v-if="$store.state.playType === 'watch' || $store.state.username === partyHost" v-html="calculateGameLengthText()"></p>
            <div v-if="$store.state.playType != 'watch' && $store.state.username !== partyHost">
              <qrcode-vue class="qrcode" :value="currentUrl" :background="'#cfd8dc'"></qrcode-vue>
              <p>
                {{ $t("party.showQrCode") }}
              </p>
            </div>
            <div class="extraWidth">
              <button class="pure-button" id="btnLeave" @click="onLeave(true)" :gamepadClickable="true">
                {{ $t("party.leave") }}
              </button>
              <button
                class="pure-button"
                id="btnStartGame"
                @click="onStartGame"
                v-if="$store.state.username === partyHost"
                :disabled="numberWatchModes == 0 || users.length < 3"
              >
                {{ $t("party.startGame") }}
              </button>
            </div>
          </div>
          <div
            size="500"
            class="pure-u-1-5 qrcode ink shape03 partyLobbyContainer"
            v-if="!SettingsManager.settings.streamerMode && $store.state.playType == 'watch'"
          >
            <p class="verticalCenterInline bigSmartphoneEmoji">📱</p>
            <qrcode-vue class="verticalCenterInline" :value="currentUrl" :background="'#cfd8dc'"></qrcode-vue>
          </div>
          <div v-if="$store.state.username === partyHost" class="pure-form pure-form-aligned centerForm">
            <hr />
            <fieldset style="text-align: start">
              <h2 class="centerContent">{{ $t("party.settings") }}</h2>
              <div class="pure-control-group">
                <label for="numberQuestionRedraws">{{ $t("party.numberQuestionRedraws") }}</label>
                <input
                  id="numberQuestionRedraws"
                  type="number"
                  min="0"
                  max="10"
                  v-model="$store.state.partySettings.numberQuestionRedraws"
                  autocomplete="off"
                  style="max-width: 40%;"
                  @change="onSavePartySettings"
                />
              </div>
              <div class="pure-control-group">
                <label for="numberRounds">{{ $t("party.numberRounds") }}</label>
                <input
                  id="numberRounds"
                  type="number"
                  min="1"
                  max="5"
                  v-model="$store.state.partySettings.numberRounds"
                  autocomplete="off"
                  style="max-width: 40%;"
                  @change="onSavePartySettings"
                />
              </div>
              <div class="pure-control-group">
                <label for="langu">{{ $t("party.language") }}</label>
                <select v-model="$store.state.partySettings.langu" id="langu" @change="onSavePartySettings">
                  <option v-for="langu in languList" v-bind:value="langu.code" v-bind:key="langu.code">
                    {{ langu.name }}
                  </option>
                </select>
                <label>{{ $t("party.ainotice") }}</label>
              </div>
              <div class="pure-control-group">
                <label class="pure-checkbox" for="awardCardsAtGameStart">{{ $t("party.awardCardsAtGameStart") }}</label>
                <input type="checkbox" id="awardCardsAtGameStart" v-model="$store.state.partySettings.awardCardsAtGameStart" @change="onSavePartySettings" checked/>
              </div>
              <div class="pure-control-group">
                <label class="pure-checkbox" for="carryOverCardsToNextGame">{{ $t("party.carryOverCardsToNextGame") }}</label>
                <input type="checkbox" id="carryOverCardsToNextGame" v-model="$store.state.partySettings.carryOverCardsToNextGame" @change="onSavePartySettings" checked/>
              </div>
              <div class="pure-control-group">
                <label class="pure-checkbox" for="public">{{ $t("party.publicgame") }}</label>
                <input type="checkbox" id="public" v-model="$store.state.partySettings.public" @change="onSavePartySettings" />
              </div>
              <div class="pure-control-group" v-if="$store.state.partySettings.public">
                <label for="maxPlayers">{{ $t("serverbrowser.maxPlayers") }}</label>
                <input
                  id="maxPlayers"
                  type="number"
                  min="1"
                  max="12"
                  v-model="$store.state.partySettings.maxPlayers"
                  autocomplete="off"
                  @change="onSavePartySettings"
                />
              </div>
              <div class="pure-control-group" v-if="$store.state.partySettings.public">
                <label for="description">{{ $t("serverbrowser.description") }}</label>
                <input
                  id="description"
                  type="text"
                  maxlength="25"
                  v-model="$store.state.partySettings.description"
                  autocomplete="off"
                  @change="onSavePartySettings"
                  class="inpDescription"
                />
              </div>
            </fieldset>
            <hr />
            <h2 class="centerContent">{{ $t("party.players") }}</h2>
            <div class="playerClipper">
              <table>
                <tbody>
                  <tr v-for="user in users" :key="user.name">
                    <td>
                      <p class="username">{{ user.name }}</p>
                    </td>
                    <td>
                      <button
                        class="pure-button"
                        @click="onKickPlayer(user.name)"
                        :disabled="user.name === partyHost"
                        v-if="$store.state.username === partyHost"
                      >
                        {{ $t("party.kickPlayer") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br />
    <div class="ink shape04" v-if="$store.state.playType == 'watch'">
      <br v-if="users.length > 0" />
      <div class="pure-sm-1 pure-u-1-6" v-for="(user, index) in users" :key="user.name">
        <DrawView
          v-bind:drawingBlob="user.avatarBlob"
          v-bind:playersoundRef="user.playersoundRef"
          :styleClass="'DrawViewAvatar'"
          v-bind:class="'isConnected_' + user.connected"
        ></DrawView>
        <p :id="'userName' + index" class="username">
          <span class="usernameAlign" v-if="user.connected === false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 90 73"
              xml:space="preserve"
              style="height: 1rem; fill: white; padding-right: 0.5em"
            >
              <g id="g12" transform="translate(-5.1,-13.5)">
                <path
                  d="m 86.3,13.5 -10.6,10.6 c -23.3,-10.2 -51.5,-5.9 -70.6,13.2 l 9,9 c 14,-14 34.1,-18.1 51.7,-12.3 l -10.5,10.5 c -11.4,-1.6 -23.4,2 -32.2,10.8 l 9,9 c 2.8,-2.8 6,-4.8 9.5,-6 l -24.6,24.5 3.7,3.7 69.3,-69.3 z"
                  id="path4"
                />
                <path d="m 42.4,72 -1.3,1.3 8.9,8.9 9,-9 c -4.5,-4.5 -11.6,-4.9 -16.6,-1.2 z" id="path6" />
                <path d="m 56.7,57.7 c 4.1,1.1 8,3.3 11.2,6.5 l 9,-9 c -3.1,-3.1 -6.6,-5.6 -10.4,-7.4 z" id="path8" />
                <path d="m 85.1,29.3 -9.2,9.2 c 3.6,2.1 6.9,4.7 10,7.8 l 9,-9 c -3,-3 -6.3,-5.7 -9.8,-8 z" id="path10" />
              </g>
            </svg>
          </span>
          <span class="usernameAlign">{{ user.name }}</span>
        </p>
        <p v-if="user.name === partyHost">
          <em>{{ $t("party.partyHost") }}</em>
        </p>
      </div>
      <p
        v-if="users.length == 0 && $store.state.playType == 'watch'"
        class="verticalCenterInline"
        style="width: 60rem"
        v-html="
          $t('party.lobbyEmpty', {
            partyCode: partyCodeDisplay,
            url: currentUrlDisplay,
          })
        "
      ></p>
      <br />
    </div>
    <br />
    <div id="usersView" class="noWrap" v-if="$store.state.playType == 'watch'">
      <div>
        <div class="pure-u-1-2">
          <div class="ink inkSmallMargin shape03 joinDiv">
            <h2>{{ $t("hello.watchDescription") }} 📺</h2>
            <p>{{ $t("party.hostExplain") }}</p>
            <p>
              <a :href="currentWatchUrl" class="lobbyLink" target="_blank" v-if="!$isTauri">{{ currentWatchUrlDisplay }}</a>
              <span id="lobbyShareUrl" class="lobbyLink" v-if="$isTauri" @click="copyToClipboard(currentWatchUrl)">{{ currentWatchUrlDisplay }}</span>
              <button @click="copyToClipboard(currentWatchUrl)" class="pure-button" style="padding: 0.3em" :gamepadClickable="true">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 64 64"
                  enable-background="new 0 0 64 64"
                  xml:space="preserve"
                  style="width: 1em; height: 1em; fill: white"
                >
                  <g>
                    <path
                      d="M53.9791489,9.1429005H50.010849c-0.0826988,0-0.1562004,0.0283995-0.2331009,0.0469999V5.0228 C49.7777481,2.253,47.4731483,0,44.6398468,0h-34.422596C7.3839517,0,5.0793519,2.253,5.0793519,5.0228v46.8432999c0,2.7697983,2.3045998,5.0228004,5.1378999,5.0228004h6.0367002v2.2678986C16.253952,61.8274002,18.4702511,64,21.1954517,64 h32.783699c2.7252007,0,4.9414978-2.1725998,4.9414978-4.8432007V13.9861002 C58.9206467,11.3155003,56.7043495,9.1429005,53.9791489,9.1429005z M7.1110516,51.8661003V5.0228 c0-1.6487999,1.3938999-2.9909999,3.1062002-2.9909999h34.422596c1.7123032,0,3.1062012,1.3422,3.1062012,2.9909999v46.8432999 c0,1.6487999-1.393898,2.9911003-3.1062012,2.9911003h-34.422596C8.5049515,54.8572006,7.1110516,53.5149002,7.1110516,51.8661003z M56.8888474,59.1567993c0,1.550602-1.3055,2.8115005-2.9096985,2.8115005h-32.783699 c-1.6042004,0-2.9097996-1.2608986-2.9097996-2.8115005v-2.2678986h26.3541946 c2.8333015,0,5.1379013-2.2530022,5.1379013-5.0228004V11.1275997c0.0769005,0.0186005,0.1504021,0.0469999,0.2331009,0.0469999 h3.9682999c1.6041985,0,2.9096985,1.2609005,2.9096985,2.8115005V59.1567993z"
                    />
                    <path
                      d="M38.6031494,13.2063999H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0158005 c0,0.5615997,0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4542999,1.0158997-1.0158997 C39.6190491,13.6606998,39.16465,13.2063999,38.6031494,13.2063999z"
                    />
                    <path
                      d="M38.6031494,21.3334007H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0157986 c0,0.5615005,0.4544001,1.0159016,1.0159006,1.0159016h22.3491974c0.5615005,0,1.0158997-0.454401,1.0158997-1.0159016 C39.6190491,21.7877007,39.16465,21.3334007,38.6031494,21.3334007z"
                    />
                    <path
                      d="M38.6031494,29.4603004H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997 s0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4543991,1.0158997-1.0158997 S39.16465,29.4603004,38.6031494,29.4603004z"
                    />
                    <path
                      d="M28.4444485,37.5872993H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997 s0.4544001,1.0158997,1.0159006,1.0158997h12.1904964c0.5615025,0,1.0158005-0.4543991,1.0158005-1.0158997 S29.0059509,37.5872993,28.4444485,37.5872993z"
                    />
                  </g>
                </svg>
              </button>
            </p>
          </div>
        </div>
        <div class="pure-u-1-2 joinContainerMargin">
          <div class="ink inkSmallMargin shape04 joinDiv">
            <h2>{{ $t("hello.normal") }} 📱</h2>
            <p>{{ $t("party.playerExplain") }}</p>
            <p>
              <a id="lobbyShareUrl" :href="currentUrl" class="lobbyLink" target="_blank" v-if="!$isTauri">{{ currentUrlDisplay }}</a>
              <span id="lobbyShareUrl" class="lobbyLink" v-if="$isTauri" @click="copyToClipboard(currentUrl)">{{ currentUrlDisplay }}</span>
              <button @click="copyToClipboard(currentUrl)" class="pure-button" style="padding: 0.3em" :gamepadClickable="true">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 64 64"
                  enable-background="new 0 0 64 64"
                  xml:space="preserve"
                  style="width: 1em; height: 1em; fill: white"
                >
                  <g>
                    <path
                      d="M53.9791489,9.1429005H50.010849c-0.0826988,0-0.1562004,0.0283995-0.2331009,0.0469999V5.0228 C49.7777481,2.253,47.4731483,0,44.6398468,0h-34.422596C7.3839517,0,5.0793519,2.253,5.0793519,5.0228v46.8432999c0,2.7697983,2.3045998,5.0228004,5.1378999,5.0228004h6.0367002v2.2678986C16.253952,61.8274002,18.4702511,64,21.1954517,64 h32.783699c2.7252007,0,4.9414978-2.1725998,4.9414978-4.8432007V13.9861002 C58.9206467,11.3155003,56.7043495,9.1429005,53.9791489,9.1429005z M7.1110516,51.8661003V5.0228 c0-1.6487999,1.3938999-2.9909999,3.1062002-2.9909999h34.422596c1.7123032,0,3.1062012,1.3422,3.1062012,2.9909999v46.8432999 c0,1.6487999-1.393898,2.9911003-3.1062012,2.9911003h-34.422596C8.5049515,54.8572006,7.1110516,53.5149002,7.1110516,51.8661003z M56.8888474,59.1567993c0,1.550602-1.3055,2.8115005-2.9096985,2.8115005h-32.783699 c-1.6042004,0-2.9097996-1.2608986-2.9097996-2.8115005v-2.2678986h26.3541946 c2.8333015,0,5.1379013-2.2530022,5.1379013-5.0228004V11.1275997c0.0769005,0.0186005,0.1504021,0.0469999,0.2331009,0.0469999 h3.9682999c1.6041985,0,2.9096985,1.2609005,2.9096985,2.8115005V59.1567993z"
                    />
                    <path
                      d="M38.6031494,13.2063999H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0158005 c0,0.5615997,0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4542999,1.0158997-1.0158997 C39.6190491,13.6606998,39.16465,13.2063999,38.6031494,13.2063999z"
                    />
                    <path
                      d="M38.6031494,21.3334007H16.253952c-0.5615005,0-1.0159006,0.4542999-1.0159006,1.0157986 c0,0.5615005,0.4544001,1.0159016,1.0159006,1.0159016h22.3491974c0.5615005,0,1.0158997-0.454401,1.0158997-1.0159016 C39.6190491,21.7877007,39.16465,21.3334007,38.6031494,21.3334007z"
                    />
                    <path
                      d="M38.6031494,29.4603004H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997 s0.4544001,1.0158997,1.0159006,1.0158997h22.3491974c0.5615005,0,1.0158997-0.4543991,1.0158997-1.0158997 S39.16465,29.4603004,38.6031494,29.4603004z"
                    />
                    <path
                      d="M28.4444485,37.5872993H16.253952c-0.5615005,0-1.0159006,0.4543991-1.0159006,1.0158997 s0.4544001,1.0158997,1.0159006,1.0158997h12.1904964c0.5615025,0,1.0158005-0.4543991,1.0158005-1.0158997 S29.0059509,37.5872993,28.4444485,37.5872993z"
                    />
                  </g>
                </svg>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DrawView from "./DrawView";
import QrcodeVue from "qrcode.vue";
import languagesJson from "../data/languages.json";
import axios from "axios";
import reactionListJson from "../data/reactionList.json";
import IconDisplay from "./IconDisplay";

export default {
  name: "PartyLobby",
  components: {
    DrawView,
    QrcodeVue,
    IconDisplay,
  },
  props: ["users", "partyHost", "numberWatchModes", "numberAIWatchModes"],
  watch: {
    users: function(aNewUsers, aOldUsers) {
      this.$calcZoom();

      if (this.$store.state.playType === "watch") {
        if (aNewUsers.length > aOldUsers.length) {
          this.SoundManager.playSfx("join");
          this.SoundManager.playNarration("player-joined", aNewUsers[aNewUsers.length - 1].name);
        } else if (aNewUsers.length < aOldUsers.length) {
          this.SoundManager.playSfx("leave");
          this.SoundManager.playNarration("player-left", aNewUsers[aNewUsers.length - 1].name);
        }
      }
    },
  },
  data: function() {
    return {
      languList: [],
      currentUrlDisplay: "",
      currentWatchUrlDisplay: "",
      partyCodeDisplay: "",
      blacklistCharacter: "■■■■",
      currentUrl: "",
      currentWatchUrl: "",
      reactionList: reactionListJson,
      emptyTimeout: null,
    };
  },
  async mounted() {
    this.currentUrl = location.origin + "/app/" + this.$store.state.partyCode;
    this.currentWatchUrl = location.origin + "/app/watch/" + this.$store.state.partyCode;

    this.streamerModeChange(this.SettingsManager.settings.streamerMode);
    this.$root.$on(
      "streamerModeChange",
      function(streamerMode) {
        this.streamerModeChange(streamerMode);
      }.bind(this)
    );

    axios.get("/api/general/languages").then(
      function(resp) {
        this.languList = resp.data;
        var aTempList = [];
        this.languList.forEach(
          function(entry) {
            var sEntry = languagesJson.find((el) => el.code === entry);
            aTempList.push({ code: sEntry.code, name: sEntry.name });
          }.bind(this)
        );
        this.languList = aTempList;
      }.bind(this)
    );

    this.emptyTimeout = setTimeout(
      function() {
        if (this.users.length === 0) {
          this.SoundManager.playNarration("lobby-empty");
        }
      }.bind(this),
      45000
    );

    this.$calcZoom();
    this.SoundManager.playNarration("lobby-intro");
  },
  beforeDestroy() {
    clearTimeout(this.emptyTimeout);
  },
  methods: {
    onKickPlayer: function(username) {
      this.$emit("onKickPlayer", username);
    },

    onLeave: function(bKickYourself) {
      this.$emit("onLeave", bKickYourself);
    },

    onStartGame: function() {
      this.$emit("onStartGame");
    },

    copyToClipboard: function(sLink) {
      navigator.clipboard.writeText(sLink);
    },

    streamerModeChange: function(streamerMode) {
      if (streamerMode) {
        this.currentUrlDisplay = this.currentUrl.slice(0, -4) + this.blacklistCharacter;
        this.currentWatchUrlDisplay = this.currentWatchUrl.slice(0, -4) + this.blacklistCharacter;
        this.partyCodeDisplay = this.blacklistCharacter;
      } else {
        this.currentUrlDisplay = this.currentUrl;
        this.currentWatchUrlDisplay = this.currentWatchUrl;
        this.partyCodeDisplay = this.$store.state.partyCode;
      }
    },

    onSavePartySettings: function(oEvent, bSilent) {
      if (oEvent) {
        this.$store.commit("PartySetting", {
          setting: oEvent.target.id,
          value: oEvent.target.type === "checkbox" ? oEvent.target.checked : oEvent.target.value,
          vue: this
        });
      }

      axios
        .put("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/settings", this.$store.state.partySettings, {
          headers: {
            "x-supersketchysecret": this.$store.state.superSketchySecret,
          },
        })
        .then(
          function() {
            if (!bSilent) {
              this.$notify({
                group: "notifications",
                type: "success",
                position: "top center",
                title: "Success",
                text: this.$getMessage("party.settingsChanged"),
              });
            }
          }.bind(this)
        )
        .catch((err) => {
          if (err.response) {
            if (!bSilent) {
              this.$notify({
                group: "notifications",
                type: "error",
                position: "top center",
                title: this.$getMessage("error.genericError"),
                text: err.response.data.message,
              });
            }
          }
        });
    },

    calculateGameLength: function() {
      // RoundAnnounce: 3s
      var roundAnnounce = 3;
      // timeToDrawPicture: Partysettings variable (default: 60s)
      // timeToGuess: Partysettings variable (default: 45s)
      // timeToPick: Partysettings variable (default: 30s)
      // turnResult Phase 1: 4750ms * Players
      var turnResultPhase1 = 4.75 * this.users.length;
      // turnResult Phase 2: 4s
      var turnResultPhase2 = 4;
      // numberRounds: Partysettings variable (default: 2)
      // EndGame: ~40s
      var endGame = 40;

      return (
        this.$store.state.partySettings.numberRounds *
          (roundAnnounce +
            this.$store.state.partySettings.timeToDrawPicture +
            this.users.length *
              (this.$store.state.partySettings.timeToGuess + this.$store.state.partySettings.timeToPick + turnResultPhase1 + turnResultPhase2)) +
        endGame
      );
    },

    calculateGameLengthText: function() {
      var gameLength = this.calculateGameLength();
      if (gameLength >= 20 * 60) {
        return this.$t("party.longParty", { gameLength: Math.floor(gameLength / 60) });
      }
    },
  },
};
</script>
<style scoped>
#lobbyShareUrl {
  max-width: 65ch;
}

label,
input {
  display: inline-block !important;
}

label {
  width: 45% !important;
  text-align: right !important;
}

.inpDescription {
  max-width: 45%;
}

html,
button,
input,
select,
textarea,
.pure-g [class*="pure-u"] {
  font-size: larger;
}

.playerClipper {
  max-height: 16em;
  overflow-y: auto;
  padding-left: 1.5em;
  padding-right: 1.50em;
}

.partyLobbyParagraphMiddle {
  min-width: 30rem;
}

.extraWidth {
  display: inline-block;
  white-space: nowrap;
}
</style>
