<template>
  <div>
    <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: 300, leave: 0 }">
      <div v-if="slideNo <= maxSlide && $store.state.playType === 'watch'">
        <div v-if="slideNo === 0" class="endgameContainer">
          <h2 class="centerContent theend">{{ $t("party.endofgame") }}</h2>
          <p class="centerContent">{{ $t("party.endofgamewait") }}</p>
          <div class="snippet centerContent" data-title=".dot-bricks">
            <div class="stage">
              <div class="dot-bricks"></div>
            </div>
          </div>
        </div>
        <div v-if="slideNo > 0 && slideNo < maxSlide && $store.state.playType === 'watch'">
          <div>
            <h2>
              {{ content[slideNo - 1].emoji }}
              {{ content[slideNo - 1].title }}:
              {{ content[slideNo - 1].winner }}
            </h2>
          </div>
        </div>
        <h2 v-if="slideNo === maxSlide" class="centerContent">{{ $t("party.scoreboard") }}</h2>
        <div class="chart-container" style="position: relative; height:40vh; width:80vw">
          <canvas ref="ResultCanvas" class="ResultCanvas"></canvas>
        </div>
      </div>
    </transition>
    <transition @before-leave="$stopSensor" @after-enter="$startSensor" name="fade" :duration="{ enter: 300, leave: 0 }">
      <div v-if="slideNo > maxSlide">
        <div id="divScoreboard" class="ink shape02" v-if="overviewSlideData.scores.length > 0">
          <h2>{{ $t("party.scoreboard") }}</h2>
          <div class="pure-u-1-2 centerContent" v-for="dataPoint in overviewSlideData.scores" :key="dataPoint.username">
            <p>
              <strong>{{ dataPoint.username }}</strong
              >: {{ dataPoint.score }}
            </p>
          </div>
        </div>
        <br />
        <div class="reactionDiv ink shape03" v-if="overviewSlideData.reactions.length > 0">
          <h2>{{ $t("party.reactions") }}</h2>
          <table class="pure-table centerForm endTable">
            <thead>
              <th id="pin">📌</th>
              <th v-for="reaction in overviewSlideData.reactions" :key="reaction.key" :id="'th' + reaction.key">
                {{ reaction.emoji }}
                {{ $t(reactionList.find((x) => x.key === reaction.key).key) }}
              </th>
            </thead>
            <tbody>
              <tr v-for="user in users" :key="user.name">
                <td>
                  {{ user.name }}
                </td>
                <td v-for="reaction in overviewSlideData.reactions" :key="reaction.key + user.name">
                  <strong
                    class="reactionWinner"
                    v-if="reaction.data.find((x) => x.username == user.name) && reaction.data.find((x) => x.username == user.name).isWinner === true"
                  >
                    {{ reaction.data.find((x) => x.username == user.name).count }}
                  </strong>
                  <span
                    v-if="reaction.data.find((x) => x.username == user.name) && reaction.data.find((x) => x.username == user.name).isWinner === false"
                  >
                    {{ reaction.data.find((x) => x.username == user.name).count }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div class="centerContent ink shape01">
          <p>
            {{ users.filter((user) => user.connected === true).length }}
            {{ $t("party.stillConnected") }}
          </p>
          <p
            v-if="users.filter((user) => user.name == partyHost).length == 0 || users.filter((user) => user.name == partyHost)[0].connected == false"
          >
            {{ $t("party.partyLeaderDisconnected") }}
          </p>
          <p>{{ $t("party.steamreview") }}</p>
        </div>
      </div>
    </transition>
    <br />
    <div class="centerContent ink shape04">
      <div class="centerContent">
        <button class="pure-button endgameButton" @click="onBack">
          {{ $t("party.backMainmenu") }}
        </button>
        <button class="pure-button endgameButton" :disabled="!isPartyHost" @click="onNewRound">
          {{ $t("party.newRound") }}
        </button>
      </div>
      <div class="centerContent" style="margin-top:2em;" v-if="$store.state.playType !== 'watch'">
        <IngameContentReport v-if="$store.state.playType !== 'watch'"></IngameContentReport>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import reactionListJson from "../data/reactionList.json";
import IngameContentReport from "./IngameContentReport";
import Chart from "chart.js/auto";

export default {
  name: "EndGameResult",
  props: ["content", "isPartyHost", "users", "partyHost"],
  data: function() {
    return {
      slideNo: 0, // 0 - endofgame, 1-3 - reactions, 4 score, >maxslide overview
      maxSlide: 0,
      reactionList: reactionListJson,
      overviewSlideData: {},
      nextSlideInterval: null,
      charts: [],
      colors: [
        "rgba(230,135,0,0.5)",
        "rgba(86,180,233,0.5)",
        "rgba(0,158,115,0.5)",
        "rgba(240,228,66,0.5)",
        "rgba(178,0,102,0.5)",
        "rgba(204,121,167,0.5)",
        "rgba(110,30,195,0.5)",
        "rgba(159,30,30,0.5)",
        "rgba(255,0,0,0.5)",
        "rgba(24,24,24,0.5)",
        "rgba(0,0,255,0.5)",
        "rgba(146,203,172,0.5)",
      ],
      firstSlideLength: 8000,
      slideLength: 10000,
    };
  },
  components: {
    IngameContentReport,
  },
  created() {
    this.transformUsersForEndgame();
  },
  mounted() {
    setTimeout(
      function() {
        this.nextSlide();

        this.nextSlideInterval = setInterval(this.nextSlide, this.slideLength);
      }.bind(this),
      this.firstSlideLength
    );

    this.maxSlide = this.content.length;
  },
  destroyed() {
    clearInterval(this.nextSlideInterval);
  },
  methods: {
    transformUsersForEndgame: function() {
      this.overviewSlideData.scores = [];
      this.overviewSlideData.reactions = [];

      this.content.forEach(
        function(chart) {
          if (chart.name !== "score") {
            chart.title = this.$t("reactionTitles." + this.reactionList.find((x) => x.key === chart.name).title);
            chart.narration = this.reactionList.find((x) => x.key === chart.name).title;
            chart.emoji = this.reactionList.find((x) => x.key === chart.name).emoji;

            var winnerString = "";
            var reactions = [];

            var flatArray = [];
            chart.datasets.forEach((score) => {
              flatArray.push([score.data[score.data.length - 1]]);
            });
            var maxScore = Math.max(...flatArray.map((e) => e.reduce((a, b) => a + b, 0)));

            chart.datasets.forEach((score) => {
              var totalScore = score.data[score.data.length - 1];
              var isWinner = false;
              if (totalScore === maxScore) {
                if (winnerString.length > 0) {
                  winnerString += " & " + score.label;
                } else {
                  winnerString = score.label;
                }
                isWinner = true;
              }

              reactions.push({
                username: score.label,
                count: totalScore,
                isWinner: isWinner,
              });
            });

            this.overviewSlideData.reactions.push({
              key: chart.name,
              emoji: chart.emoji,
              data: reactions,
            });

            chart.winner = winnerString;
          } else {
            chart.datasets.forEach((score) => {
              this.overviewSlideData.scores.push({
                username: score.label,
                score: score.data[score.data.length - 1],
              });
            });

            chart.narration = "most-points";
          }

          chart.datasets.forEach((score, i) => {
            score.fill = false;
            score.stepped = true;
            score.borderColor = this.colors[i];
            score.pointRadius = 0;
            score.pointRadius = 0;
            score.borderWidth = 10;
            score.borderCapStyle = "round";
          });
        }.bind(this)
      );
    },

    nextSlide: function() {
      if (this.slideNo === this.maxSlide) {
        this.slideNo++;
        return;
      }

      if (this.slideNo <= this.maxSlide) {
        this.SoundManager.stopNarration();
        this.SoundManager.playNarration(this.content[this.slideNo].narration);

        if (this.charts[this.slideNo - 1]) {
          this.charts[this.slideNo - 1].destroy();
        }
        this.buildChart(this.slideNo);
        this.slideNo++;
      }
    },

    buildChart(slideNo) {
      var key = this.content[slideNo].name;
      if (this.content[slideNo].datasets.length === 0) {
        return;
      }

      const repeats = 100;
      const delayBetweenPoints = (this.slideLength * 0.6) / (this.content[slideNo].datasets[0].data.length * repeats);
      const previousY = (ctx) =>
        ctx.index === 0
          ? ctx.chart.scales.y.getPixelForValue(100)
          : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(["y"], true).y;
      const animation = {
        x: {
          type: "number",
          easing: "linear",
          duration: delayBetweenPoints,
          from: NaN, // the point is initially skipped
          delay(ctx) {
            if (ctx.type !== "data" || ctx.xStarted) {
              return 0;
            }
            ctx.xStarted = true;
            return ctx.index * delayBetweenPoints;
          },
        },
        y: {
          type: "number",
          easing: "linear",
          duration: delayBetweenPoints,
          from: previousY,
          delay(ctx) {
            if (ctx.type !== "data" || ctx.yStarted) {
              return 0;
            }
            ctx.yStarted = true;
            return ctx.index * delayBetweenPoints;
          },
        },
      };

      var expandedDataset = this.expandDatasets(
        this.content.find((x) => x.name === key).datasets,
        this.content.find((x) => x.name === key).labels,
        repeats
      );

      var maxValue = Math.ceil(
        Math.max(
          ...expandedDataset.datasets.map((dataset) => {
            return Math.max(...dataset.data);
          })
        ) * 1.1
      );

      if (this.$refs.ResultCanvas) {
        this.charts[slideNo] = new Chart(this.$refs.ResultCanvas.getContext("2d"), {
          type: "line",
          data: {
            datasets: expandedDataset.datasets,
            labels: expandedDataset.labels,
          },
          options: {
            scales: {
              y: {
                min: 0,
                max: maxValue,
                beginAtZero: true,
                ticks: {
                  precision: 0,
                  beginAtZero: true,
                },
              },
              x: {
                ticks: {
                  callback: function(val, index) {
                    return index % repeats === 0 || index === expandedDataset.labels.length - 1 ? this.getLabelForValue(val) : null;
                  },
                },
                grid: {
                  drawTicks: false,
                  // display: false,
                },
              },
            },
            animation: animation,
            responsive: true,
            maintainAspectRatio: false,
          },
        });
      }
    },

    expandDatasets(datasets, labels, repeats) {
      var expandedDataset = {};
      expandedDataset.datasets = [];
      expandedDataset.labels = [];

      for (var k = 0; k < datasets.length; k++) {
        expandedDataset.datasets[k] = {};
        expandedDataset.datasets[k].data = [];
        expandedDataset.datasets[k].borderColor = datasets[k].borderColor;
        expandedDataset.datasets[k].fill = datasets[k].fill;
        expandedDataset.datasets[k].label = datasets[k].label;
        expandedDataset.datasets[k].stepped = datasets[k].stepped;
        expandedDataset.datasets[k].pointRadius = datasets[k].pointRadius;
        expandedDataset.datasets[k].borderWidth = datasets[k].borderWidth;
        expandedDataset.datasets[k].borderCapStyle = datasets[k].borderCapStyle;

        for (var i = 0; i < datasets[k].data.length * repeats; i++) {
          var j = Math.floor(i / repeats);
          expandedDataset.datasets[k].data.push(datasets[k].data[j]);
          if (k === 0) {
            expandedDataset.labels.push(labels[j]);
          }
        }
      }

      expandedDataset.labels[expandedDataset.labels.length - 1] = "Ende";

      return expandedDataset;
    },

    onBack: function() {
      this.$router.push({
        name: "Hello",
      });
    },

    onNewRound: function() {
      axios
        .put("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/backtolobby", null, {
          headers: {
            "x-supersketchysecret": this.$store.state.superSketchySecret,
          },
        })
        .catch((err) => {
          if (err.response) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: err.response.data.message,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.endgameContainer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  color: #263238;
}

.theend {
  font-size: 5rem;
  margin-bottom: 0em;
}

.dot-bricks {
  position: relative;
  top: 8px;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #263238;
  color: #263238;
  box-shadow: 9991px -16px 0 0 #263238, 9991px 0 0 0 #263238, 10007px 0 0 0 #263238;
  animation: dotBricks 2s infinite ease;
}

@keyframes dotBricks {
  0% {
    box-shadow: 9991px -16px 0 0 #263238, 9991px 0 0 0 #263238, 10007px 0 0 0 #263238;
  }
  8.333% {
    box-shadow: 10007px -16px 0 0 #263238, 9991px 0 0 0 #263238, 10007px 0 0 0 #263238;
  }
  16.667% {
    box-shadow: 10007px -16px 0 0 #263238, 9991px -16px 0 0 #263238, 10007px 0 0 0 #263238;
  }
  25% {
    box-shadow: 10007px -16px 0 0 #263238, 9991px -16px 0 0 #263238, 9991px 0 0 0 #263238;
  }
  33.333% {
    box-shadow: 10007px 0 0 0 #263238, 9991px -16px 0 0 #263238, 9991px 0 0 0 #263238;
  }
  41.667% {
    box-shadow: 10007px 0 0 0 #263238, 10007px -16px 0 0 #263238, 9991px 0 0 0 #263238;
  }
  50% {
    box-shadow: 10007px 0 0 0 #263238, 10007px -16px 0 0 #263238, 9991px -16px 0 0 #263238;
  }
  58.333% {
    box-shadow: 9991px 0 0 0 #263238, 10007px -16px 0 0 #263238, 9991px -16px 0 0 #263238;
  }
  66.666% {
    box-shadow: 9991px 0 0 0 #263238, 10007px 0 0 0 #263238, 9991px -16px 0 0 #263238;
  }
  75% {
    box-shadow: 9991px 0 0 0 #263238, 10007px 0 0 0 #263238, 10007px -16px 0 0 #263238;
  }
  83.333% {
    box-shadow: 9991px -16px 0 0 #263238, 10007px 0 0 0 #263238, 10007px -16px 0 0 #263238;
  }
  91.667% {
    box-shadow: 9991px -16px 0 0 #263238, 9991px 0 0 0 #263238, 10007px -16px 0 0 #263238;
  }
  100% {
    box-shadow: 9991px -16px 0 0 #263238, 9991px 0 0 0 #263238, 10007px 0 0 0 #263238;
  }
}

.snippet {
  padding: 1rem 5%;
  margin: 1.5rem 0;
  border-radius: 0.25rem;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
  transform: scale(1.75);
}
</style>
