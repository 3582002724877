<template>
  <div class="menuPadding ink shape04">
    <h3>Main Game</h3>
    <p>Game, Code & Design by Elusive Drafts</p>
    <h3>External Graphics</h3>
    <p>Mann Vektor erstellt von pch.vector - de.freepik.com</p>
    <p>Geschäft Vektor erstellt von pikisuperstar - de.freepik.com</p>
    <p>Hintergrund Vektor erstellt von rawpixel.com - de.freepik.com</p>
    <h3>External Music & Sounds</h3>
    <p>
      Payne, Christine. "MuseNet." OpenAI, 25 Apr. 2019, openai.com/blog/musenet
    </p>
    <p>https://freesound.org/people/michaelkoehler/sounds/207191/</p>
    <p>https://freesound.org/people/damonmensch/sounds/121511/</p>
    <p>https://freesound.org/people/musicvision31/sounds/431779/</p>
    <p>https://freesound.org/people/n_audioman/sounds/321967/</p>
    <p>https://freesound.org/people/JimAward/sounds/56521/</p>
    <p>https://freesound.org/people/V4cuum/sounds/348643/</p>
    <p>https://freesound.org/people/JungoXL/sounds/528652/</p>
    <p>https://freesound.org/people/Christyboy100/sounds/495699/</p>
    <p>https://freesound.org/people/Kastenfrosch/sounds/521964/</p>
    <p>https://freesound.org/people/CanimalsOzSeries/sounds/570298/</p>
    <p>https://freesound.org/people/reasanka/sounds/433567/</p>
    <p>https://freesound.org/people/dream4dreamtheater/sounds/161356/</p>
    <p>https://freesound.org/people/miksmusic/sounds/497705/</p>
    <p>https://freesound.org/people/qubodup/sounds/60013/</p>
    <p>https://freesound.org/people/Breviceps/sounds/447910/</p>
    <p>https://freesound.org/people/AlienXXX/sounds/81957/</p>
    <p>https://freesound.org/people/InspectorJ/sounds/411923/</p>
    <p>https://freesound.org/people/THE_bizniss/sounds/39548/</p>
    <p>Glass Sounds by Anton (https://freesound.org/people/Anton/)</p>
    <p>Numero Uno from Noun Project</p>
    <p>Astatine Lab from Noun Project</p>
    <p>Izwar Muis from Noun Project</p>
    <p>Marek Polakovic, SK from Noun Project</p>
    <p>Marcel Boer from Noun Project</p>
    <br />
    <button class="pure-button" @click="$router.back()">
      {{ $t("hello.back") }}
    </button>
  </div>
</template>
<script>
export default {
  props: [],
  name: "Credits",
  components: {},
  data: function() {
    return {};
  },
};
</script>

<style scoped></style>
