import Vue from "vue";
import Vuex from "vuex";
import i18n from "../i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    superSketchyOwnerSecret: "",
    superSketchySecret: "",
    websocketClientId: "",
    playType: "watch",
    showPartyCode: true,
    partyCode: "",
    round: 0,
    partySettings: {
      numberQuestionRedraws: 2,
      numberRounds: 3,
      langu: i18n.locale.substring(0, 2),
      maxPlayers: 12,
      public: false,
      description: "",
    },
    numberQuestionRedraws: -1,
    avatarBlob: null,
    performanceData: null,
    playersoundRef: "",
    playersoundBlob: null,
    selectedSound: "",
    username: "",
    handcards: [],
  },
  mutations: {
    OwnerSecret(state, payload) {
      if (typeof payload !== "undefined") {
        state.superSketchyOwnerSecret = payload.value;
        payload.vue.SettingsManager.saveCookie("superSketchyOwnerSecret", payload.value, 1, payload.vue.$isTauri);
      }
    },
    Secret(state, payload) {
      if (typeof payload.value !== "undefined") {
        state.superSketchySecret = payload.value;
        payload.vue.SettingsManager.saveCookie("supersketchysecret-" + payload.name, payload.value, 1, payload.vue.$isTauri);
      }
    },
    WebsocketClientId(state, payload) {
      if (typeof payload !== "undefined") {
        state.websocketClientId = payload;
      }
    },
    PlayType(state, payload) {
      if (typeof payload !== "undefined") {
        state.playType = payload;
      }
    },
    clearOwnerSecret(state, payload) {
      payload.vue.SettingsManager.removeCookie("superSketchyOwnerSecret", payload.vue.$isTauri);
      state.superSketchyOwnerSecret = "";
    },
    PartySetting(state, payload) {
      if (typeof payload.value !== "undefined") {
        if (
          payload.setting === "maxPlayers" ||
          payload.setting === "numberQuestionRedraws" ||
          payload.setting === "numberRounds" ||
          payload.setting === "timeToDrawPicture" ||
          payload.setting === "timeToGuess" ||
          payload.setting === "timeToPick"
        ) {
          payload.value = Number(payload.value);
        }

        state.partySettings[payload.setting] = payload.value;
        payload.vue.SettingsManager.saveCookie("partySettings", JSON.stringify(state.partySettings), 1, payload.vue.$isTauri);
      }
    },
    PartySettings(state, payload) {
      if (typeof payload !== "undefined") {
        state.partySettings = payload;
      }
    },
    PartyCode(state, payload) {
      if (typeof payload.show !== "undefined") {
        state.showPartyCode = payload.show;
      }

      if (typeof payload.code !== "undefined") {
        state.partyCode = payload.code;
      }
    },
    AvatarBlob(state, payload) {
      if (typeof payload !== "undefined") {
        state.avatarBlob = payload;
      }
    },
    PerformanceData(state, payload) {
      if (typeof payload !== "undefined") {
        state.performanceData = payload;
      }
    },
    PlayersoundRef(state, payload) {
      if (typeof payload !== "undefined") {
        state.playersoundRef = payload;
      }
    },
    PlayersoundBlob(state, payload) {
      if (typeof payload !== "undefined") {
        state.playersoundBlob = payload;
      }
    },
    SelectedSound(state, payload) {
      //filename or blob
      if (typeof payload !== "undefined") {
        state.selectedSound = payload;
      }
    },
    NumberQuestionRedraws(state, payload) {
      if (typeof payload !== "undefined") {
        state.numberQuestionRedraws = payload.value;
      }
    },
    Username(state, payload) {
      if (typeof payload !== "undefined") {
        state.username = payload;
      }
    },
    Handcards(state, payload) {
      if (typeof payload !== "undefined") {
        state.handcards = payload;
      }
    },
    Round(state, payload) {
      if (typeof payload !== "undefined") {
        state.round = Number(payload.value);
      }
    },
  },
  actions: {},
});
