<template>
  <div class="pie degree" ref="pieTimer" v-if="bShown" :style="sPieTimerStyle">
    <span class="block"></span>
    <span v-if="iTimer > 0 || sText" id="WatchTimerTime">{{ this.sText != null ? this.sText : this.iTimer }}</span>
    <span v-if="iTimer === 0"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin:auto;display:block;"
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        v-if="sText === null"
      >
        <circle cx="50" cy="50" fill="none" stroke="#4e6773" stroke-width="30" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle></svg
    ></span>
  </div>
</template>
<script>
export default {
  props: [],
  components: {},
  data: function() {
    return {
      iTimer: 0,
      sText: null,
      iInitialTimer: 0,
      oIntervalRef: null,
      bShown: false,
      sPieTimerStyle: "",
    };
  },
  mounted() {
    this.$root.$on(
      "WatchTimerShow",
      function(initialTimer) {
        if (initialTimer === "?") {
          this.sText = initialTimer;
        } else {
          this.iTimer = initialTimer;
          this.iInitialTimer = initialTimer;
          this.sText = null;
        }

        if (this.oIntervalRef) {
          this.clear();
        }

        this.bShown = true;

        this.sPieTimerStyle =
          "background-image: linear-gradient(180deg, transparent 50%, #4e6773 50%),linear-gradient(0deg, white 50%, transparent 50%)";
        this.oIntervalRef = setInterval(
          function() {
            if (this.iTimer > 0) {
              this.iTimer--;
            }
            this.update();

            if (this.iTimer == 0) this.clear();
          }.bind(this),
          1000
        );
      }.bind(this)
    );

    this.$root.$on(
      "WatchTimerHide",
      function() {
        this.bShown = false;
        this.iTimer = this.iInitialTimer = 0;
        this.clear();
      }.bind(this)
    );
  },
  beforeDestroy() {
    this.clear();
  },
  methods: {
    update: function() {
      var deg;

      if (this.iTimer < this.iInitialTimer / 2) {
        deg = 90 + (360 * this.iTimer) / this.iInitialTimer;
        this.sPieTimerStyle =
          "background-image: linear-gradient(" + deg + "deg, transparent 50%, white 50%),linear-gradient(90deg, white 50%, transparent 50%)";
      } else {
        deg = -90 + (360 * this.iTimer) / this.iInitialTimer;
        this.sPieTimerStyle =
          "background-image: linear-gradient(" + deg + "deg, transparent 50%, #4e6773 50%),linear-gradient(90deg, white 50%, transparent 50%)";
      }

      if ((this.iTimer < this.iInitialTimer / 3 && this.$refs.pieTimer?.style.animation == "") || this.iTimer == 0) {
        if (this.$refs.pieTimer?.style?.animation) {
          this.$refs.pieTimer.style.animation = "pulse 1s infinite";
        }
      }
    },
    clear: function() {
      clearInterval(this.oIntervalRef);
      this.oIntervalRef = null;
    },
  },
};
</script>
