var ControllerManager = {};

ControllerManager.install = function(Vue) {
  Vue.prototype.$isGamepadsActive = false;
  Vue.prototype.$connectedGamepads = 0;
  Vue.prototype.$activeGamepadindex = 0;
  Vue.prototype.$Dimensions = [];
  Vue.prototype.$Controls = [];
  Vue.prototype.$selectedIndex = 0;
  Vue.prototype.$ActionCooldown = 50;
  Vue.prototype.$lastActionCooldown = 50;
  Vue.prototype.$triggerStick = 0.9;
  Vue.prototype.$Controlleri18n = [];

  window.addEventListener("gamepadconnected", function(e) {
    Vue.notify({
      group: "notifications",
      type: "success",
      position: "bottom center",
      title: Vue.prototype.$Controlleri18n.gamepadConnected,
      text: e.gamepad.id,
    });

    Vue.prototype.$activateGamepad(e.gamepad.index);
  });

  window.addEventListener("gamepaddisconnected", function(e) {
    Vue.notify({
      group: "notifications",
      type: "success",
      position: "bottom center",
      title: Vue.prototype.$Controlleri18n.gamepadDisconnected,
      text: e.gamepad.id,
    });

    Vue.prototype.$deactivateGamepad();
  });

  window.addEventListener("mousemove", function() {
    if (Vue.prototype.$isGamepadsActive) {
      Vue.notify({
        group: "notifications",
        type: "success",
        position: "bottom center",
        title: Vue.prototype.$Controlleri18n.gamepadControlDeactivated,
      });

      Vue.prototype.$deactivateGamepad();
    }
  });

  Vue.prototype.$setControlleri18n = function(i18n) {
    Vue.prototype.$Controlleri18n = i18n;
  };

  Vue.prototype.$setControllerViewConfig = function(oConfig) {
    Vue.prototype.$Dimensions = oConfig.dimensions;
    Vue.prototype.$Controls = oConfig.controls;
    Vue.prototype.$selectedIndex = 0;

    if (Vue.prototype.$isGamepadsActive) {
      Vue.prototype.$focusControl(Vue.prototype.$selectedIndex);
    }
  };

  Vue.prototype.$focusControl = function(sControl) {
    Vue.prototype.$lastActionCooldown = 0;
    Vue.prototype.$Controls[sControl].focus();
  };

  Vue.prototype.$applyControl = function(sControl, iDelta) {
    if (Boolean(Vue.prototype.$Controls[sControl]?.attributes["gamepadclickable"]?.value) === true && Vue.prototype.$Controls[sControl]?.value) {
      Vue.prototype.$Controls[sControl].value = Number(Vue.prototype.$Controls[sControl].value) + Number(iDelta);
    }
    Vue.prototype.$lastActionCooldown = 0;
  };

  Vue.prototype.$clickControl = function(sControl) {
    if (Boolean(Vue.prototype.$Controls[sControl]?.attributes["gamepadclickable"]?.value) === true) {
      Vue.prototype.$Controls[sControl].click();
    } else {
      Vue.prototype.$Controls[sControl].classList.add("shakeControl");
      setTimeout(function() {
        Vue.prototype.$Controls[sControl].classList.remove("shakeControl");
      }, 2000);
    }
    Vue.prototype.$lastActionCooldown = 0;
  };

  Vue.prototype.$activateGamepad = function(iIndex) {
    Vue.prototype.$activeGamepadindex = iIndex;
    Vue.prototype.$connectedGamepads++;
    Vue.prototype.$isGamepadsActive = true;

    window.requestAnimationFrame(Vue.prototype.$controllerUpdate);

    if (Vue.prototype.$Controls.length > 0) {
      Vue.prototype.$selectedIndex = 0;
      Vue.prototype.$focusControl(Vue.prototype.$selectedIndex);
    }
  };

  Vue.prototype.$deactivateGamepad = function() {
    Vue.prototype.$connectedGamepads--;
    if (Vue.prototype.$connectedGamepads === 0) {
      Vue.prototype.$isGamepadsActive = false;
      document.activeElement.blur();
    }
  };

  Vue.prototype.$controllerUpdate = function() {
    Vue.prototype.$lastActionCooldown =
      Vue.prototype.$lastActionCooldown < Vue.prototype.$ActionCooldown ? Vue.prototype.$lastActionCooldown + 1 : Vue.prototype.$ActionCooldown;

    if (Vue.prototype.$isGamepadsActive) {
      //Down
      if (
        (navigator.getGamepads()[Vue.prototype.$activeGamepadindex]?.buttons[13].pressed ||
          navigator.getGamepads()[Vue.prototype.$activeGamepadindex].axes[1] >= Vue.prototype.$triggerStick) &&
        Vue.prototype.$lastActionCooldown === Vue.prototype.$ActionCooldown
      ) {
        Vue.prototype.$selectedIndex =
          Vue.prototype.$selectedIndex < Vue.prototype.$Controls.length - 1 ? Vue.prototype.$selectedIndex + 1 : Vue.prototype.$selectedIndex;
        Vue.prototype.$focusControl(Vue.prototype.$selectedIndex);
      }

      //Up
      if (
        (navigator.getGamepads()[Vue.prototype.$activeGamepadindex]?.buttons[12].pressed ||
          navigator.getGamepads()[Vue.prototype.$activeGamepadindex].axes[1] <= -Vue.prototype.$triggerStick) &&
        Vue.prototype.$lastActionCooldown === Vue.prototype.$ActionCooldown
      ) {
        Vue.prototype.$selectedIndex = Vue.prototype.$selectedIndex > 0 ? Vue.prototype.$selectedIndex - 1 : Vue.prototype.$selectedIndex;
        Vue.prototype.$focusControl(Vue.prototype.$selectedIndex);
      }

      //Right 15
      if (
        (navigator.getGamepads()[Vue.prototype.$activeGamepadindex]?.buttons[15].pressed ||
          navigator.getGamepads()[Vue.prototype.$activeGamepadindex].axes[0] >= Vue.prototype.$triggerStick) &&
        Vue.prototype.$lastActionCooldown === Vue.prototype.$ActionCooldown
      ) {
        Vue.prototype.$applyControl(Vue.prototype.$selectedIndex, 5);
      }
      //Left 14
      if (
        (navigator.getGamepads()[Vue.prototype.$activeGamepadindex]?.buttons[14].pressed ||
          navigator.getGamepads()[Vue.prototype.$activeGamepadindex].axes[0] <= -Vue.prototype.$triggerStick) &&
        Vue.prototype.$lastActionCooldown === Vue.prototype.$ActionCooldown
      ) {
        Vue.prototype.$applyControl(Vue.prototype.$selectedIndex, -5);
      }

      //Action
      if (
        navigator.getGamepads()[Vue.prototype.$activeGamepadindex]?.buttons[0].pressed &&
        Vue.prototype.$lastActionCooldown === Vue.prototype.$ActionCooldown
      ) {
        Vue.prototype.$clickControl(Vue.prototype.$selectedIndex);
      }
    }

    //Activate via button
    if (!Vue.prototype.$isGamepadsActive) {
      var iIndex = Array.from(navigator.getGamepads()).findIndex((gamepad) => gamepad && gamepad.buttons[0].pressed);
      if (iIndex > -1) {
        Vue.prototype.$activateGamepad(iIndex);
      }
    }

    window.requestAnimationFrame(Vue.prototype.$controllerUpdate);
  };
};

export default ControllerManager;
