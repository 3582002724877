<template>
  <div>
    <button
      v-if="state === 'closed'"
      class="pure-button"
      @click="
        state = 'open';
        $calcZoom();
      "
    >
      {{ $t("submission.report") }}
    </button>
    <div v-if="state === 'open'" class="ink shape03">
      <h2 class="centerContent">{{ $t("submission.text") }}</h2>
      <div class="contentClipper">
        <table>
          <thead>
            <th id="phrase"></th>
            <th id="btnOffensive"></th>
            <th id="btnWrong"></th>
          </thead>
          <tbody>
            <tr v-for="phrase in phrases" :key="phrase.crossReferenceGuid">
              <td>
                <p class="phrase">{{ phrase.text }}</p>
              </td>
              <td>
                <button class="pure-button" @click="onReport(phrase, 'o', $event)">
                  {{ $t("submission.offensive") }}
                </button>
              </td>
              <td>
                <button class="pure-button" @click="onReport(phrase, 'w', $event)">
                  {{ $t("submission.wrong") }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: ["users", "partyHost"],
  name: "IngameContentReport",
  components: {},
  data: function() {
    return {
      state: "closed", //close, open
      phrases: [], //text, crossReferenceGuid, langu
    };
  },
  mounted() {
    this.state = "closed";

    this.getAllPhrases();
  },
  methods: {
    onReport: function(phrase, sReason, oEvent) {
      axios
        .post(
          "/api/content/report",
          {
            reason: sReason,
            crossReferenceGuid: phrase.crossReferenceGuid,
            langu: phrase.langu,
          },
          {
            headers: {
              "x-supersketchysecret": this.$store.state.superSketchySecret,
            },
          }
        )
        .then(
          function(resp) {
            if (resp.status == 201) {
              this.$notify({
                group: "notifications",
                type: "success",
                position: "top center",
                text: this.$getMessage("contentvote.success"),
              });
              oEvent.srcElement.disabled = true;
            } else if (resp.status == 208) {
              this.$notify({
                group: "notifications",
                type: "error",
                position: "top center",
                text: this.$getMessage("contentvote.alreadyvoted"),
              });
            }
          }.bind(this)
        )
        .catch((err) => {
          if (err.response) {
            this.$notify({
              group: "notifications",
              type: "error",
              position: "top center",
              title: this.$getMessage("error.genericError"),
              text: err.response.data.message,
            });
          }
        });
    },

    getAllPhrases: function() {
      axios
        .get("/api/party/" + this.$store.state.partyCode.toUpperCase() + "/phrases", {
          headers: {
            "x-supersketchysecret": this.$store.state.superSketchySecret,
          },
        })
        .then(
          function(resp) {
            if (resp.status == 200) {
              this.phrases = resp.data;
            }
          }.bind(this)
        );
    },
  },
};
</script>

<style scoped>
.contentClipper {
  max-height: 16em;
  overflow-y: auto;
}
</style>
